import './supportBottom.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function SupportBottom({ leftCol, rightCol }) {
    return (
        <section className='supportDownloadCenter-section supportBottom w-100 row justify-content-center px-0 bg-white'>
            <div className='col-12 moreInfo mb-10 mb-lg-14'>
                <div className='row'>
                    <div className='col-12 col-lg-6 text-center px-0'>
                        <div className='border-grey8 border-end'>
                            <p className='text-grey8 pt-0 pt-lg-4 mt-4 mb-2 mb-lg-5'>{leftCol.title}</p>
                            <Link
                                to={{
                                    pathname: leftCol.link,
                                    state: {
                                        initialLocation: rightCol.section || '.section2',
                                    },
                                }}
                            >
                                <h6 className='text-grey8 pb-0 pb-lg-4 mb-3'>
                                    <span className='me-2'>{leftCol.text}</span>
                                    <span>
                                        <img
                                            className='icnLink h-100 mx-0'
                                            src='./images/support/icnLink.png'
                                            alt='icnLink'
                                        />
                                    </span>
                                </h6>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 text-center px-0'>
                        <p className='text-grey8 pt-lg-4 mt-3 mt-lg-4 mb-2 mb-lg-5'>{rightCol.title}</p>
                        <Link
                            to={{
                                pathname: rightCol.link,
                                state: {
                                    initialLocation: rightCol.section || '.section2',
                                },
                            }}
                        >
                            <h6 className='text-grey8 pb-4 mb-0 mb-lg-3'>
                                <span className='me-2'>{rightCol.text}</span>
                                <span>
                                    <img
                                        className='icnLink h-100 mx-0'
                                        src='./images/support/icnLink.png'
                                        alt='icnLink'
                                    />
                                </span>
                            </h6>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SupportBottom;
