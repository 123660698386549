import useAxios from '../../../hooks/useAxios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../actManage/actScheduleRegistration/AdvancedSearchResultTag';
export default function SalSalesKitClass() {
    const { operation } = useAxios();

    const [isGetList, setIsGetList] = useState(false);
    const [salesKitClassList, setSalesKitClassList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // Sorting states
    const [sortBy, setSortBy] = useState('statusTime');
    const [orderBy, setOrderBy] = useState('desc');

    const [selectedSalesKitList, setSelectedSalesKitList] = useState([]);

    //#region AdvancedSearch region

    const searchContentList = [
        {
            column: 'name',
            name: '商品名稱',
            type: 'text',
            tableColumn: 'name', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'name',
            selectOptionList: ['==', '!=', 'contains', '!contains'],
        },
        {
            column: 'salesKitClass',
            name: '銷售分類代碼',
            type: 'text',
            tableColumn: 'salesKitClass', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'salesKitClass',
            selectOptionList: ['==', '!='],
        },
        {
            column: 'startDate',
            name: '銷售起始日期',
            type: 'datetime-local',
            tableColumn: 'startDate',
            searchFromDataBase: 'startDate',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'endDate',
            name: '銷售截止日期',
            type: 'datetime-local',
            tableColumn: 'endDate',
            searchFromDataBase: 'endDate',
            selectOptionList: ['>', '<'],
        },
    ];

    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    const filterSalesKitClassList = () => {
        if (searchText) {
            const advancedSearchDataList = [
                {
                    searchLogic: '',
                    SearchFilter: 'name_01',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_02',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_03',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
            ];

            fetchData(advancedSearchDataList, 100000, sortBy, 1);
        }
    };

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'name',
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['==', '!=', 'contains', '!contains'],
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    /**
     * Deletes a filter from the advanced search data list and the display list.
     *
     * @param {number} targetIndex - The index of the filter to delete.
     * @return {undefined} This function does not return a value.
     */

    const deleteFilter = (targetIndex) => {
        const newAdvancedSearchDataList = advancedSearchDataList.filter((_, index) => index !== targetIndex);
        const newDisplayList = displayList.filter((_, index) => index !== targetIndex);
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null);
    };

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = async (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/SalSalesKit/getPingtexPSalesKitList`;
        // console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sort} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        const { success, response, error } = await operation(
            {
                url: `/SalSalesKit/getPingtexPSalesKitList`,
                method: 'POST',
                data: reqBody,
            },
            'getPingtexPSalesKitList'
        );
        if (success) {
            console.log(response.data);

            setSalesKitClassList(response.data.data);
            setIsGetList(true);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <section id='section-main'>
            <div id='container-salSalesKitClass'>
                <h5 className='fw-bold mb-4'>商品圖片倉庫-商品列表</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        {/* <select
                            className="form-control me-2"
                            style={{ width: '160px' }}
                            name="searchColumn"
                            id="searchColumn"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            required
                        >
                            <option value="all">全部</option>
                            <option value="fullname" id="fullnameSearch">
                                显示名称
                            </option>
                            <option value="userID" id="userIDSearch">
                                用户名(ID)
                            </option>
                            <option value="phone1" id="phone1Search">
                                手机号码
                            </option>
                            <option value="email1" id="email1Search">
                                邮箱
                            </option>
                        </select> 
                    */}
                        <div className='searchWrapper bg-white  rounded-4'>
                            <div className='searchBox row flex-wrap'>
                                <div className='col-12 mb-2'>
                                    <button
                                        className='searchBtn col-2 btn btn-size-s btn-color-main'
                                        onClick={() => setAdvancedSearchModalOpen(true)}
                                    >
                                        搜尋
                                    </button>
                                </div>
                                <AdvancedSearchResultTag
                                    advancedSearchDataList={advancedSearchDataList}
                                    deleteAllFilters={deleteAllFilters}
                                    deleteFilter={deleteFilter}
                                    ns='lgsPurchaseOrder'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>商品列表</h6>{' '}
                        {/* <button
                            className="btn btn-primary text-white ms-auto me-2"
                            onClick={
                                handleInsertSalesKit
                            }
                        >
                            <svg
                                id="icn_Create"
                                className="me-2 mb-1"
                                viewBox="0 0 12 12"
                                width="12"
                                height="12"
                                fill="#fff"
                            >
                                <defs> </defs>
                                <path
                                    className="cls-1"
                                    id="_Color"
                                    d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
                                    data-name=" ↳Color"
                                ></path>
                            </svg>
                            <span>上架商品</span>
                        </button> */}
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey7 border-0'>
                                            #<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            簡中名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            英文名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售商品分類<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售起始日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售截止日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            註記<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetList &&
                                        (salesKitClassList.length !== 0 ? (
                                            salesKitClassList.map((skClass, idx) => {
                                                // console.log(skClass);
                                                return (
                                                    <tr
                                                        id={skClass?.salesKitGuid}
                                                        key={skClass?.salesKitGuid}
                                                        onClick={() => navigate(`${skClass?.salesKitGuid}`)}
                                                    >
                                                        <td>{idx + 1}</td>
                                                        <td>{skClass?.name01}</td>
                                                        <td>{skClass?.name02}</td>
                                                        <td>{skClass?.name03}</td>
                                                        <td>{skClass?.salesKitClassName}</td>
                                                        <td>
                                                            {skClass.startDate
                                                                ? dayjs(skClass.startDate).format('YYYY-MM-DD HH:mm:ss')
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {skClass.endDate
                                                                ? dayjs(skClass.endDate).format('YYYY-MM-DD HH:mm:ss')
                                                                : ''}
                                                        </td>
                                                        <td>{skClass?.note}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='10'>無商品記錄</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>

                            {/* modal */}
                            <AdvancedSearchModal
                                isOpen={advancedSearchModalOpen}
                                handleClose={() => setAdvancedSearchModalOpen(false)}
                                advancedSearchDataTemp={advancedSearchDataTemp}
                                setAdvancedSearchDataList={setAdvancedSearchDataList}
                                displayList={[]}
                                searchContentList={searchContentList}
                                fetchData={fetchData}
                                ns='ActScheduleRegistration'
                                restSearchData={[]}
                            />
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
