import './productStat.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

/// 规格参数
/// Author : YL
function ProductStat() {
    const { t } = useTranslation('productStat'); // i18n

    return (
        <div className='container-fluid p-0'>
            <article id='container-productStat' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <section className='productStat-section section0 position-fixed w-100 row justify-content-center bg-white'>
                    <div className='col-12 section0Wrapper p-0 text-center'>
                        <div className='h-100 d-flex justify-content-between align-items-center px-4'>
                            <h6 className='text-grey8 mb-0'>{t('synerter_pingpong_robot')}</h6>
                            <Dropdown>
                                <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
                                    {t('specifications')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link className='text-grey8' to='/productcenter/productfeature'>
                                            {t('feature_highlights')}
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link className='text-primary' to='/productcenter/productstat'>
                                            {t('specifications')}
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link className='text-grey8' to='/app/download'>
                                            {t('synerter_app')}
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </section>
                <div className='wrapper'>
                    <section className='productStat-section section1 justify-content-center align-content-center text-center'>
                        <div className='top'></div>
                        <div className='row middle'>
                            <div className='left col-3 text-left'>{t('TS_series')}</div>
                            <div className='col productImageCard'>
                                <div id='product1_img' className=''></div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section2 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('product_model')}</div>
                        </div>
                        <div className='right'>
                            <p>{t('TS_series')}</p>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section3 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('product_specification')}</div>
                        </div>
                        <div className='right'>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('length')}: </p>
                                </div>
                                <div className='col'>
                                    <p>302 {t('millimeter')}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('width')}: </p>
                                </div>
                                <div className='col'>
                                    <p>302 {t('millimeter')}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('height')}: </p>
                                </div>
                                <div className='col'>
                                    <p>560 {t('millimeter')}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>{t('weight')}:</div>
                                <div className='col'>
                                    <p>5.5 {t('kilogram')}</p>
                                    <p>{t('product_specification_remark')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section4 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('ball_features')}</div>
                        </div>
                        <div className='right'>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('ball_speed')}:</p>
                                </div>
                                <div className='col'>
                                    <p>
                                        {t('ball_speed_content', {
                                            min: '2',
                                            max: '16',
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('ball_frequency')}:</p>
                                </div>
                                <div className='col'>
                                    <p>
                                        {t('ball_frequency_content', {
                                            rangeMin: 10,
                                            rangeMax: 120,
                                            max: 0.5,
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('spin_angle')}:</p>
                                </div>
                                <div className='col'>
                                    <p>
                                        {t('spin_angle_content', {
                                            maxSpinDegree: 360,
                                            numberOfPositions: 16,
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>{t('ball_angle_vertical')}:</div>
                                <div className='col'>
                                    <p>
                                        {t('ball_angle_vertical_content', {
                                            maxVerticalAngle: 80,
                                            maxVerticalAngleUpward: 40,
                                            maxVerticalAngleDownward: 40,
                                        })}
                                    </p>
                                    <p>
                                        {t('ball_angle_sub_content', {
                                            unitDegree: 0.5,
                                            totalNumberOfGears: 160,
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>{t('ball_angle_horizontal')}:</div>
                                <div className='col'>
                                    <p>
                                        {t('ball_angle_Horizontal_content', {
                                            maxHorizontalAngle: 60,
                                            maxHorizontalAngleUpward: 30,
                                            maxHorizontalAngleDownward: 30,
                                        })}
                                    </p>
                                    <p>
                                        {t('ball_angle_sub_content', {
                                            unitDegree: 1,
                                            totalNumberOfGears: 120,
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section5 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('continuous_mode')}</div>
                        </div>
                        <div className='right'>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('bluetooth')}：</p>
                                </div>
                                <div className='col'>
                                    <p>{t('bluetooth_content')}</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>Wi-Fi：</p>
                                </div>
                                <div className='col'>
                                    <p>IEEE 802.11ax/g/n 2.4Ghz</p>
                                    <p>*{t('continuous_mode_remark')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section6 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('voice_prompt')}</div>
                        </div>
                        <div className='right'>
                            <p>{t('voice_prompt_available')}</p>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section7 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('spin_indicator_light')}</div>
                        </div>
                        <div className='right'>
                            <p>{t('spin_indicator_light_adjustable')}</p>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section8 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('battery_and_power')}</div>
                        </div>
                        <div className='right'>
                            <p>{t('battery_and_power_content')}</p>
                        </div>
                    </section>
                </div>

                <div className='wrapper border-top'>
                    <section className='productStat-section section9 section-info d-flex align-items-start justify-content-center'>
                        <div className='left'>
                            <div className='textZone'>{t('package_contents')}</div>
                        </div>
                        <div className='right'>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('package_size')}：</p>
                                </div>
                                <div className='col'>
                                    <p>70 x 60 x 43 {t('centimeter')}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>
                                    <p>{t('package_weight')}：</p>
                                </div>
                                <div className='col'>
                                    <p>9.8 {t('kilogram')}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='right-1 col'>{t('package_contents')}：</div>
                                <div className='col'>
                                    <p>{t('ping_pong_robot')} x 1</p>
                                    <p>{t('power_adapter')} x 1</p>
                                    <p>{t('tripod')} x 1</p>
                                    <p>{t('tray')} x 1</p>
                                    <p>{t('ball_collector')} x 2</p>
                                    <p>{t('net_aluminum_pipes')} x 2</p>
                                    <p>{t('net')} x 1</p>
                                    <p>{t('ball_collector_clamps')} x 2</p>
                                    <p>{t('net_clamps')} x 2</p>
                                    <p>{t('quick_installation_manual')} x 1</p>
                                    <p>*{t('package_contents_remark')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper'>
                    <section className='productStat-section section10 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div className='smallWrapper'>
                            <div className='col-12 desc px-3'>
                                <ul className='text-grey6 ps-0 mb-4 mb-lg-5'>
                                    <li>{t('product_disclaimer_1')}</li>
                                    <li>{t('product_disclaimer_2')}</li>
                                    <li>{t('product_disclaimer_3')}</li>
                                    <li>{t('product_disclaimer_4')}</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default ProductStat;
