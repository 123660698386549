// import axios from 'axios';
import useAxios from '../../../hooks/useAxios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../actManage/actScheduleRegistration/AdvancedSearchResultTag';

/**
 * `SalSalesKit` 是一個 React 元件，負責顯示和管理銷售套件的清單。
 * 它包含基本和進階搜索功能、數據排序以及項目的選擇等功能。
 *
 * 主要特點：
 * - 顯示一個銷售套件的清單表格，包括名稱、銷售套件類別、起始和截止日期等詳細信息。
 * - 支援基本搜索，可按名稱、銷售套件類別、銷售套件代碼、起始日期和截止日期進行篩選。
 * - 提供進階搜索功能，使用者可以在模態窗口中設置多個搜索條件。
 * - 可以根據選定的準則（例如狀態時間）對銷售套件進行排序。
 * - 允許選擇單個銷售套件進行進一步操作。
 *
 * 使用的組件和庫：
 * - 使用 Axios 進行向後端 API 發送異步 HTTP 請求。
 * - 使用 AdvancedSearchResultTag 組件為每個應用的進階搜索篩選渲染標籤。
 * - 使用 AdvancedSearchModal 組件實現可自定義搜索條件的模態窗口。
 *
 * 狀態和 Hooks：
 * - 使用 `useState` 和 `useEffect` 鉤子管理各種狀態，用於處理搜索、排序和選擇。
 *
 * 外部依賴：
 * - 需要 Axios 庫進行 API 請求。
 * - 使用 Day.js 库格式化日期值。
 *
 * 用法：
 * - 若要使用此組件，應將其導入並在父組件或路由中呈現。
 * - 该组件从后端 API 获取数据，然后呈现具有銷售套件詳細信息的動態表格。
 * - 使用者可以通過搜索、排序和選擇來與表格交互，以滿足其需求。
 *
 * 注意：此評論提供了一個高層次的概述。有關更詳細的說明，請參閱代碼中的內聯評論。
 */
/**
 * `SalSalesKit` is a React component responsible for displaying and managing a list of sales kits.
 * It includes features such as basic and advanced search functionalities, data sorting, and selection of items.
 *
 * Key Features:
 * - Displays a table of sales kits with various details such as name, sales kit class, start and end dates, etc.
 * - Supports basic search with options to filter by name, sales kit class, sales kit code, start date, and end date.
 * - Provides advanced search functionality with a modal allowing users to set multiple search criteria.
 * - Allows sorting of the sales kits based on selected criteria such as status time.
 * - Enables selection of individual sales kits for further actions.
 *
 * Components and Libraries Used:
 * - Axios: Used for making asynchronous HTTP requests to the backend API.
 * - AdvancedSearchResultTag: Renders tags for each applied advanced search filter.
 * - AdvancedSearchModal: Modal component for advanced search with customizable search criteria.
 *
 * State and Hooks:
 * - Manages various states using the `useState` and `useEffect` hooks for handling search, sorting, and selection.
 *
 * External Dependencies:
 * - Requires Axios library for API calls.
 * - Utilizes Day.js library for formatting date values.
 *
 * Usage:
 * - To use this component, it should be imported and rendered within a parent component or route.
 * - The component fetches data from the backend API and renders a dynamic table with sales kit details.
 * - Users can interact with the table by searching, sorting, and selecting items based on their requirements.
 *
 * Note: This comment serves as a high-level overview. For more detailed explanations, refer to the inline comments within the code.
 */
export default function SalSalesKit() {
    const { operation } = useAxios();
    const [isGetList, setIsGetList] = useState(false);
    const [salesKitClassList, setSalesKitClassList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // Sorting states
    const [sortBy, setSortBy] = useState('statusTime');
    const [orderBy, setOrderBy] = useState('desc');

    const [selectedSalesKitList, setSelectedSalesKitList] = useState([]);

    //#region AdvancedSearch region

    const searchContentList = [
        // 'name',
        // 'phone',
        // 'time_Registry',
        // 'queueNo',
        // 'time_Playing',
        // 'score',
        // 'time_Checkin',
        // 'Status',
        {
            column: 'name',
            name: '商品名稱',
            type: 'text',
            tableColumn: 'name', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'name',
            selectOptionList: ['==', '!=', 'contains', '!contains'],
        },
        {
            column: 'salesKitClass',
            name: '銷售分類代碼',
            type: 'text',
            tableColumn: 'salesKitClass', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'salesKitClass',
            selectOptionList: ['==', '!='],
        },
        {
            column: 'startDate',
            name: '銷售起始日期',
            type: 'datetime-local',
            tableColumn: 'startDate',
            searchFromDataBase: 'startDate',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'endDate',
            name: '銷售截止日期',
            type: 'datetime-local',
            tableColumn: 'endDate',
            searchFromDataBase: 'endDate',
            selectOptionList: ['>', '<'],
        },
    ];

    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    //過濾搜尋
    const resetFilterSalesKitClassList = () => {
        setSearchText('');
        fetchData();
    };

    const filterSalesKitClassList = () => {
        if (searchText) {
            const advancedSearchDataList = [
                {
                    searchLogic: '',
                    SearchFilter: 'name_01',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_02',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_03',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
            ];

            fetchData(advancedSearchDataList, 100000, sortBy, 1);
        }
    };

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'name',
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['==', '!=', 'contains', '!contains'],
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    /**
     * Deletes a filter from the advanced search data list and the display list.
     *
     * @param {number} targetIndex - The index of the filter to delete.
     * @return {undefined} This function does not return a value.
     */

    const deleteFilter = (targetIndex) => {
        const newAdvancedSearchDataList = advancedSearchDataList.filter((_, index) => index !== targetIndex);
        const newDisplayList = displayList.filter((_, index) => index !== targetIndex);
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null);
    };

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = async (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/SalSalesKit/getPingtexPSalesKitList`;
        // console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sort} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        const { success, response, error } = await operation(
            {
                url: '/SalSalesKit/getPingtexPSalesKitList',
                method: 'POST',
                data: reqBody,
            },
            'getPingtexPSalesKitList'
        );
        if (success) {
            console.log(response);

            setSalesKitClassList(response.data.data);
            setIsGetList(true);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelect = (e) => {
        const id = e.currentTarget.id;

        // 檢查是否已經存在
        if (!selectedSalesKitList.includes(id)) {
            // 如果不存在，則添加
            setSelectedSalesKitList((prev) => [...prev, id]);
        } else {
            // 如果已經存在，則可以選擇移除或執行其他操作
            // 這裡是一個示例，你可以根據需求進行調整
            setSelectedSalesKitList((prev) => prev.filter((item) => item !== id));
        }
    };

    const handleSelectAll = () => {
        if (selectedSalesKitList.length === salesKitClassList.length) {
            setSelectedSalesKitList([]);
        } else {
            setSelectedSalesKitList(salesKitClassList.map((item) => item.salesKitGuid));
        }
    };

    return (
        <section id='section-main'>
            <div id='container-salSalesKitClass'>
                <h5 className='fw-bold mb-4'>商品列表</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        {/* <select
                            className="form-control me-2"
                            style={{ width: '160px' }}
                            name="searchColumn"
                            id="searchColumn"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            required
                        >
                            <option value="all">全部</option>
                            <option value="fullname" id="fullnameSearch">
                                显示名称
                            </option>
                            <option value="userID" id="userIDSearch">
                                用户名(ID)
                            </option>
                            <option value="phone1" id="phone1Search">
                                手机号码
                            </option>
                            <option value="email1" id="email1Search">
                                邮箱
                            </option>
                        </select> 
                    */}
                        <div className='searchWrapper bg-white  rounded-4'>
                            <div className='searchBox row flex-wrap'>
                                <div className='col-12 mb-2'>
                                    <button
                                        className='searchBtn col-2 btn btn-size-s btn-color-main'
                                        onClick={() => setAdvancedSearchModalOpen(true)}
                                    >
                                        搜尋
                                    </button>
                                </div>
                                <AdvancedSearchResultTag
                                    advancedSearchDataList={advancedSearchDataList}
                                    deleteAllFilters={deleteAllFilters}
                                    deleteFilter={deleteFilter}
                                    ns='lgsPurchaseOrder'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>商品列表</h6>{' '}
                        {/* <button
                            className="btn btn-primary text-white ms-auto me-2"
                            onClick={
                                handleInsertSalesKit
                            }
                        >
                            <svg
                                id="icn_Create"
                                className="me-2 mb-1"
                                viewBox="0 0 12 12"
                                width="12"
                                height="12"
                                fill="#fff"
                            >
                                <defs> </defs>
                                <path
                                    className="cls-1"
                                    id="_Color"
                                    d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
                                    data-name=" ↳Color"
                                ></path>
                            </svg>
                            <span>上架商品</span>
                        </button> */}
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey7 border-0'>
                                            #<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            簡中名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            英文名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售商品分類<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售起始日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            銷售截止日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            註記<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetList &&
                                        (salesKitClassList.length !== 0 ? (
                                            salesKitClassList.map((salesKit, idx) => {
                                                // console.log(salesKit);
                                                return (
                                                    <tr
                                                        id={salesKit?.salesKitGuid}
                                                        key={salesKit?.salesKitGuid}
                                                        onClick={() =>
                                                            navigate(`${salesKit?.salesKitGuid}`, {
                                                                state: {
                                                                    salesKit: salesKit,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <td>{idx + 1}</td>
                                                        <td>{salesKit?.name01}</td>
                                                        <td>{salesKit?.name02}</td>
                                                        <td>{salesKit?.name03}</td>
                                                        <td>{salesKit?.salesKitClassName}</td>
                                                        <td>
                                                            {salesKit.startDate
                                                                ? dayjs(salesKit.startDate).format(
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  )
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {salesKit.endDate
                                                                ? dayjs(salesKit.endDate).format('YYYY-MM-DD HH:mm:ss')
                                                                : ''}
                                                        </td>
                                                        <td>{salesKit?.note}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='10'>無商品記錄</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>

                            {/* modal */}
                            <AdvancedSearchModal
                                isOpen={advancedSearchModalOpen}
                                handleClose={() => setAdvancedSearchModalOpen(false)}
                                advancedSearchDataTemp={advancedSearchDataTemp}
                                setAdvancedSearchDataList={setAdvancedSearchDataList}
                                displayList={[]}
                                searchContentList={searchContentList}
                                fetchData={fetchData}
                                ns='ActScheduleRegistration'
                                restSearchData={[]}
                            />
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
