import './productDetail.scss';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_Add } from '../../../assets/images/icn_AddAppendix.svg';
import { ReactComponent as Icn_Minus } from '../../../assets/images/icn_Minus.svg';
import { ReactComponent as Icn_chevronLeft } from '../../../assets/images/icn_chevron-left.svg';
import { ReactComponent as Icn_chevronRight } from '../../../assets/images/icn_chevron-right.svg';
import Swal from 'sweetalert2';
import { CartDialog } from '../../../components/cartDialog/CartDialog';

function ProductDetail() {
    const { t } = useTranslation('productDetail'); // i18n
    const { operation } = useAxios();

    const [position, setPosition] = useState(0); // 移動縮略圖的位置
    const [product, setProduct] = useState({});
    const [activeImg, setActiveImg] = useState('');
    const [quantity, setQuantity] = useState(1); // 畫面上的產品數量
    const [addCartQuantity, setAddCartQuantity] = useState(1); // 當次加入購物車的產品數量
    const [cartPdTypeQuantity, setCartPdTypeQuantity] = useState(); // 購物車中的產品類型數量
    const [showFeature, setShowFeature] = useState('desc');
    const [showCartInfo, setShowCartInfo] = useState(false);

    const params = useParams();
    const navigator = useNavigate();

    // 判斷裝置類型
    function detectDeviceType() {
        const width = window.innerWidth;

        if (width <= 576) {
            // 寬度小於等於768px，視為手機板
            return 'mb';
        } else if (width <= 992) {
            // 寬度小於等於1024px，視為平板
            return 'pad';
        } else {
            // 寬度大於1024px，視為電腦版
            return 'pc';
        }
    }

    const deviceType = detectDeviceType();

    // 縮圖可移動次數
    const [moveTimes, setMoveTimes] = useState(0);

    const moveThumbnail = (direction) => {
        // 縮略圖的寬度為mb:58px, pad:72px, pc:117px

        if (direction === 'left' && position !== 0) {
            setPosition((prevPosition) => prevPosition + (deviceType === 'mb' ? 58 : deviceType === 'pad' ? 72 : 117));
            setMoveTimes((prev) => prev + 1);
        } else if (direction === 'right') {
            // 移動次數
            if (moveTimes != 0) {
                setPosition(
                    (prevPosition) => prevPosition - (deviceType === 'mb' ? 58 : deviceType === 'pad' ? 72 : 117)
                );
                setMoveTimes((prev) => prev - 1);
            }
        }

        // console.log(moveTimes);
    };

    const handleAddCart = async () => {
        // 1.存入cart
        const { success, response, error } = await operation(
            {
                url: '/ShoppingCart/insertCart',
                method: 'POST',
                data: {
                    salesKitList: [
                        {
                            action: 'INSERT',
                            salesKitGuid: product.salesKitGuid,
                            quantity: quantity,
                            promoteGrossAmount: product.promoteGrossAmount,
                        },
                    ],
                },
            },
            'insertCart'
        );
        if (success) {
            console.log(response.data);
            const getCartList = async () => {
                const { success, response, error } = await operation(
                    {
                        url: '/ShoppingCart/getCartList',
                    },
                    'getCartList'
                );
                if (success) {
                    console.log(response.data.result);

                    setAddCartQuantity(quantity); // 本次加入購物車的產品數量
                    setCartPdTypeQuantity(response.data.result.length); // cart產品類型數量
                    setShowCartInfo(true); // 顯示彈出視窗
                } else if (error) {
                    if (error.response.status === 401) {
                        Swal.fire({
                            title: t('please_login_first'),
                            icon: 'warning',
                            allowOutsideClick: true,
                            confirmButtonText: t('redirect_to_login_page'),
                            confirmButtonColor: 'var(--primary)',
                            cancelButtonText: t('cancel'),
                            cancelButtonColor: 'var(--secondary)',
                            showCancelButton: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigator('/login', {
                                    state: { from: window.location.pathname },
                                });
                            }
                        });
                    }
                }
            };
            getCartList();
        } else if (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    title: t('please_login_first'),
                    icon: 'warning',
                    allowOutsideClick: true,
                    confirmButtonText: t('redirect_to_login_page'),
                    confirmButtonColor: 'var(--primary)',
                    cancelButtonText: t('cancel'),
                    cancelButtonColor: 'var(--secondary)',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigator('/login', {
                            state: { from: window.location.pathname },
                        });
                    }
                });
            }
        }

        setTimeout(() => {
            setShowCartInfo(false);
        }, 2000);
    };

    // API
    const getPdDetail = async () => {
        const { success, response, error } = await operation(
            {
                url: `/salSalesShop/getSalesKit/${params.productGuid}`,
                method: 'POST',
            },
            'getPdDetail'
        );

        if (success) {
            console.log(response);
            const productData = response.data.result;
            setProduct(productData);

            // 確保 salesKitAppendixList 存在且不為空
            if (productData.salesKitAppendixList && productData.salesKitAppendixList.length > 0) {
                console.log(productData.salesKitAppendixList[0].appendixURL);
                setActiveImg(productData.salesKitAppendixList[0].appendixURL); // 設定第一張縮略圖
                setMoveTimes(`${productData.salesKitAppendixList.length - (deviceType === 'mb' ? 5 : 4)}`); // 判斷最多可以移動幾次
            } else {
                console.log('No appendix available');
                setActiveImg('../../../images/no_image_200_no_squares.png');
                setMoveTimes('0');
            }
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPdDetail();
    }, []);

    // console.log(product);
    // console.log(moveTimes);
    // console.log(addCartQuantity);

    return (
        <div className='container-fluid position-relative p-0 text-center bg' id='container-productDetail'>
            <div className='topTagArea'></div>

            <div className='w-100 d-flex flex-column align-items-center'>
                {/* 主要區塊 */}
                <div className='mainArea wrapper position-relative d-flex flex-column align-items-center px-0 px-sm-5'>
                    {/* 購物車資訊 */}
                    <CartDialog
                        name={product.name_01}
                        addCartQuantity={addCartQuantity}
                        showCartInfo={showCartInfo}
                        setShowCartInfo={setShowCartInfo}
                        cartPdTypeQuantity={cartPdTypeQuantity}
                        coverURL={product.coverURL}
                    />

                    {/* 麵包屑 */}
                    <div className='breadCrumb w-100 text-start px-4 px-sm-0 pt-3 pt-sm-4 pt-md-6 pb-3 pb-sm-4 pb-md-4'>
                        <div>
                            <Link to='/'>{t('home')}</Link>
                            <span className='mx-2'>
                                <Icn_chevronRight />
                            </span>
                            <Link to='/onlineStore'>{t('online_store')}</Link>
                            <span className='mx-2'>
                                <Icn_chevronRight />
                            </span>
                            <Link to=''>{product.name_01}</Link>
                        </div>
                    </div>

                    {/* 購買區塊 */}
                    <section className='purchaseArea w-100 row flex-column flex-sm-row'>
                        {/* 圖片區塊 */}
                        <div className='col-6 imageArea ps-0 pe-0 pe-sm-4 mb-4 mb-sm-0'>
                            <div className='activeImg d-flex justify-content-center align-items-center mb-2'>
                                <img src={activeImg} />
                            </div>
                            <div className='thumbnailArea d-flex position-relative'>
                                <div
                                    className='leftBtn d-flex justify-content-center align-items-center ms-3 ms-sm-0'
                                    onClick={() => moveThumbnail('left')}
                                >
                                    <Icn_chevronLeft />
                                </div>
                                <div className='thumbnailImgList d-flex mx-3 mx-sm-0'>
                                    {product.salesKitAppendixList?.map((item) => (
                                        <img
                                            style={{
                                                transform: `translateX(${position}px)`,
                                            }}
                                            key={item.appendixGuid}
                                            className='thumbnailImg me-2'
                                            src={item.appendixURL}
                                            alt={item.appendixName}
                                            onClick={() => setActiveImg(item.appendixURL)}
                                        />
                                    ))}
                                </div>
                                <div
                                    className='rightBtn d-flex justify-content-center align-items-center me-3 me-sm-0'
                                    onClick={() => moveThumbnail('right')}
                                >
                                    <Icn_chevronRight />
                                </div>
                            </div>
                        </div>

                        {/* 售價區塊 */}
                        <div className='priceArea col-6 px-4'>
                            <div className='d-flex flex-column'>
                                <h1 className='productName fw-bold text-start mb-3 mb-sm-4'>{product.name_01}</h1>
                                <p className='desc text-start mb-5 mb-sm-4'>{product.note}</p>
                                <div className='text-start mb-4 mb-sm-6'>
                                    <span className='promotePrice fw-bold me-3'>
                                        {product.promoteGrossAmount >= 0
                                            ? `NT$${product?.promoteGrossAmount?.toLocaleString()}`
                                            : `NT$${product?.grossAmount?.toLocaleString()}`}
                                    </span>
                                    {product.promoteGrossAmount >= 0 ? (
                                        product.promoteGrossAmount === product.grossAmount ? null : (
                                            <span className='price'>
                                                NT$
                                                {product.grossAmount?.toLocaleString()}
                                            </span>
                                        )
                                    ) : null}
                                </div>
                                <p className='text-start mb-2'>{t('quantity')}</p>

                                {/* 數量按鈕 */}
                                <div className='quantityBtnArea w-100 d-flex align-items-center mb-4'>
                                    <button
                                        className='quantityBtn'
                                        onClick={() => {
                                            if (quantity > 1) {
                                                setQuantity(quantity - 1);
                                            }
                                        }}
                                    >
                                        <Icn_Minus />
                                    </button>
                                    <input
                                        type='number'
                                        className='w-100'
                                        value={quantity}
                                        onChange={(e) => {
                                            setQuantity(e.target.value);
                                        }}
                                        readOnly
                                    />
                                    <button
                                        className='quantityBtn'
                                        onClick={() => {
                                            setQuantity(quantity + 1);
                                        }}
                                    >
                                        <Icn_Add />
                                    </button>
                                </div>

                                {/* 購買按鈕區塊 */}
                                <button
                                    className='addCartBtn btn btn-primary w-100 d-flex align-items-center justify-content-center text-white text-center border-0'
                                    onClick={() => handleAddCart()}
                                >
                                    {t('add_to_cart')}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>

                {/* 產品介紹區塊 */}
                <section className='featureSection wrapper w-100 d-flex flex-column align-items-center'>
                    {/* 分頁tab */}
                    <div className='tabBtnArea w-100 d-flex mb-6 mb-sm-9'>
                        <button
                            className={`tabBtn ${showFeature === 'desc' && 'active'}`}
                            onClick={() => {
                                setShowFeature('desc');
                            }}
                        >
                            {t('product_details')}
                        </button>
                        {/* <button
							className={`tabBtn ${
								showFeature === 'spec' && 'active'
							}`}
							onClick={() => {
								setShowFeature('spec');
							}}
						>
							商品規格
						</button> */}
                    </div>

                    {/* 產品描述 */}
                    <div className='descArea d-flex flex-column align-items-center px-0 px-sm-10'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: product.content,
                            }}
                        />
                    </div>
                </section>
            </div>

            {/* <div class="w-100">
				<picture>
					<source
						className="w-100"
						media="(min-width: 680px)"
						srcSet="storage/salesKitAppendixStore/1/ba3c9c66-e9eb-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
					<source
						className="w-100"
						media="(min-width: 360px)"
						srcSet="storage/salesKitAppendixStore/1/bb3c9c66-e9eb-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
					<img
						className="w-100"
						src="storage/salesKitAppendixStore/1/bdeddd78-0af0-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
				</picture>
			</div>
			<div class="w-100">
				<picture>
					<source
						className="w-100"
						media="(min-width: 680px)"
						srcSet="storage/salesKitAppendixStore/1/ba3c9c66-e9eb-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
					<source
						className="w-100"
						media="(min-width: 360px)"
						srcSet="storage/salesKitAppendixStore/1/4791524a-e9eb-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
					<img
						className="w-100"
						src="storage/salesKitAppendixStore/1/4e664077-09f0-ee11-a190-00155d070505.jpg"
						alt="pd details 1"
					/>
				</picture>
			</div> */}
        </div>
    );
}

export default ProductDetail;
