import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';

// #region styled-components
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
`;

const ModalDialog = styled.div`
    max-width: 600px !important;
    min-height: calc(100% - 3.5rem);
`;

const TextareaElement = styled.textarea`
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    min-height: 88px;
    resize: none;

    &:disabled {
        background-color: white;
    }
`;

// #endregion

export const DialogCol = ({ isOpen, setIsOpen, modalData }) => {
    console.log(modalData);

    //#region Modal儲存
    function modalSave() {
        handleClose();
    }
    //#endregion

    // Modal取消(關閉)
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <ModalWrapper onClick={handleClose}>
                    <ModalDialog
                        className='modal-dialog d-flex align-items-center'
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            className='modal-content border-0'
                            style={{
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                borderRadius: '12px',
                            }}
                        >
                            <div className='modal-header px-4 pt-4 pb-3'>
                                <h5 className='text-primary fw-bold fontSize125Rem mb-0'>异动记录</h5>
                                <button
                                    style={{ top: '12px', right: '12px' }}
                                    type='button'
                                    className='close position-absolute btn'
                                    onClick={handleClose}
                                >
                                    <Icn_Cancel />
                                </button>
                            </div>

                            <div className='modal-body text-center px-7 py-0'>
                                <div className='form-group pe-0 py-4 mb-0'>
                                    <label htmlFor='transactionTime' className='fontSize75Rem mb-2 w-100 text-start'>
                                        时间
                                    </label>
                                    <input
                                        type='datetime-local'
                                        id='transactionTime'
                                        name='transactionTime'
                                        className='form-control mb-3 bg-white'
                                        value={modalData.transactionTime.split('+')[0]}
                                        style={{ height: '32px' }}
                                        disabled
                                    />

                                    <label htmlFor='transactionTime' className='fontSize75Rem mb-2 w-100 text-start'>
                                        异动內容
                                    </label>
                                    <TextareaElement
                                        type='datetime-local'
                                        id={'updateContent'}
                                        rows={'5'}
                                        cols={'30'}
                                        defaultValue={modalData.updateContent.replace(/\。 /g, '\n')}
                                        onChange={() => {}}
                                        disabled
                                    />

                                    <label htmlFor='qcStaffName' className='fontSize75Rem mb-2 w-100 text-start'>
                                        人员
                                    </label>
                                    <input
                                        type='text'
                                        id='qcStaffID'
                                        name='qcStaffID'
                                        className='form-control mb-0 bg-white'
                                        style={{ height: '32px' }}
                                        value={modalData.userName}
                                        onChange={(e) => {}}
                                        disabled
                                    />
                                </div>
                            </div>

                            <hr
                                className='mx-4 mt-3 mb-0'
                                style={{
                                    border: `1px solid var(--grey4)`,
                                }}
                            />

                            {/* 確定btn */}
                            <div className='modal-footer justify-content-center border-0 pt-4 pb-5'>
                                <button
                                    type='button'
                                    className='btn btn-primary fontSize875Rem text-white d-flex align-items-center justify-content-center mx-1'
                                    style={{
                                        width: '120px',
                                        height: '32px',
                                    }}
                                    onClick={modalSave}
                                >
                                    確定
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-outline-primary fontSize875Rem d-flex align-items-center justify-content-center mx-1'
                                    style={{
                                        width: '120px',
                                        height: '32px',
                                    }}
                                    onClick={handleClose}
                                >
                                    取消
                                </button>
                            </div>
                        </div>
                    </ModalDialog>
                </ModalWrapper>
            )}
        </>
    );
};
