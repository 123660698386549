import './supportDownloadCenter.scss';
import React, { useEffect, useState } from 'react';
import SupportTop from '../SupportTop';
import { Link } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import SupportBottom from '../SupportBottom';
import { useTranslation } from 'react-i18next';

function SupportDownloadCenter() {
    const { t } = useTranslation(['supportDownloadCenter']); // i18n
    const { operation } = useAxios();

    const [manual, setManual] = useState([]);

    //獲取產品說明書
    const getManual = async () => {
        const { success, response, error } = await operation(
            {
                url: '/service/manual',
                method: 'GET',
            },
            'getManual'
        );

        if (success) {
            console.log(response.data.result);
            setManual(response.data.result);
        }
        if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getManual();
    }, []);

    //獲取最新版apk下載連結
    const getNewestAndroid = async () => {
        window.open('https://play.google.com/store/apps/details?id=com.synerter.SynerterSports&pli=1', '_blank');
    };

    return (
        <div className='container-fluid p-0 bg-white'>
            <article
                id='container-supportDownloadCenter'
                className='d-flex flex-column align-items-center px-0 mx-auto'
            >
                <SupportTop />

                <section className='supportDownloadCenter-section section2 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-3 text-grey8'>{t('download_center')}</h2>
                        <h6 className='px-6 mx-auto mb-0 text-grey8'>{t('product_information')}</h6>
                    </div>
                </section>

                <section className='supportDownloadCenter-section section3 w-100 row bg-white'>
                    {manual.map((item) => {
                        return (
                            <div
                                key={item.manualPath}
                                className='manualWrapper col-12 col-lg-4 px-3 py-3 mx-auto mx-lg-auto'
                            >
                                <div
                                    className='manualItem p-4 bg-background
					'
                                >
                                    <h2 className='mb-3 text-grey8'>{item.title}</h2>
                                    <h6 className='mb-3 text-grey6'>{item.title}.pdf</h6>
                                    <h6 className='mb-4 text-grey6'>
                                        {item.createTime.split('T')[0]} | {item.fileSize}M
                                    </h6>
                                    <Link to={'/' + item.manualPath} target='_blank'>
                                        <div className='btn btn-dark rounded-pill text-white'>{t('download')}</div>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </section>

                <section className='supportDownloadCenter-section section4 w-100 row bg-white pb-9 pb-lg-7'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-3 text-grey8'>{t('app_download')}</h2>
                        <h6 className='px-6 mx-auto text-grey8'>{t('welcome')}</h6>
                    </div>
                </section>

                <section className='supportDownloadCenter-section section5 w-100 row py-7 px-lg-13 py-lg-7 mb-lg-5 bg-background'>
                    <div
                        className='col-12 col-lg-6 mb-5 mb-lg-0
					'
                    >
                        <div className='d-flex flex-column flex-lg-row align-items-center'>
                            <div className='leftCol mb-3 mb-lg-0 me-lg-4'>
                                <img
                                    className='section5_1_2x h-100 mx-0'
                                    src='./images/support/supportDownloadCenter/section5_1_2x.png'
                                    alt='section5_1_2x'
                                    style={{ width: '97px' }}
                                />
                            </div>
                            <div className='rightCol d-flex flex-column justify-content-center'>
                                <p className='mb-2'>{t('dedicated_smart_app')}</p>
                                <h5 className='text-grey6 text-center text-lg-start mb-0'>{t('ready_to_use')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='h-100 d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                            <div
                                className='mb-2 mb-lg-0'
                                // to="/support/downloadcenter"\
                                onClick={() =>
                                    window.open(
                                        'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                        '_blank'
                                    )
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    className='section5_2_2x me-lg-4'
                                    src='./images/support/supportDownloadCenter/section5_2_2x.png'
                                    alt='section5_2_2x'
                                />
                            </div>
                            <div
                                // to={{
                                //     pathname: '/support/downloadcenter',
                                //     state: {
                                //         initialLocation: '.section4',
                                //     },
                                // }}
                                onClick={getNewestAndroid}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    className='section5_3_2x'
                                    src='./images/support/supportDownloadCenter/section5_3_2x.png'
                                    alt='section5_3_2x'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='supportDownloadCenter-section section6 w-100 bg-white text-center pt-5 pt-lg-0 pb-10 pb-lg-0'>
                    <div
                    // to={{
                    //     pathname: '/support/downloadcenter',
                    //     state: {
                    //         initialLocation: '.section4',
                    //     },
                    // }}
                    >
                        <div
                            className='btn btn-primary rounded-pill text-white'
                            onClick={getNewestAndroid}
                            style={{ cursor: 'pointer' }}
                        >
                            {t('download_android')}
                        </div>
                    </div>
                </section>

                <SupportBottom
                    leftCol={{
                        title: t('frequently_asked_questions'),
                        text: t('see_more'),
                        link: `/support/question`,
                    }}
                    rightCol={{
                        title: t('contact_us'),
                        text: t('start_consultation'),
                        link: `/support/contactus`,
                    }}
                />
            </article>
        </div>
    );
}

export default SupportDownloadCenter;
