import './cart.scss';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_Tag } from '../../../assets/images/icn_Tag.svg';
import { ReactComponent as Icn_Trash } from '../../../assets/images/icn_Trash.svg';
import { AccountContext } from '../../../App';
import QuantityBody from './components/quantityBody/QuantityBody';
import Swal from 'sweetalert2';
import useDebounce from '../../../hooks/useDebounce';

function Cart() {
    const { t } = useTranslation('cart'); // i18n
    const { operation } = useAxios();

    const accountContext = useContext(AccountContext);
    const { lang } = accountContext;
    const navigator = useNavigate();

    const [product, setProduct] = useState([]);
    const [price, setPrice] = useState({
        netPrice: 0,
        discount: 0,
        deliveryFee: 0,
        totalPrice: 0,
    });
    const [isNeedToPayDeliveryFee, setIsNeedToPayDeliveryFee] = useState(false); // 透過使用者註冊地址判斷是否需要付運費
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [isPromoCodeInputLocked, setIsPromoCodeInputLocked] = useState(false);

    const debouncedFunction = useCallback(
        useDebounce(async (newProduct) => {
            const { success, response, error } = await operation(
                {
                    url: '/ShoppingCart/insertCart',
                    method: 'POST',
                    data: {
                        salesKitList: newProduct,
                    },
                },
                'debouncedFunction'
            );
            if (success) {
                console.log(response.data);
            } else if (error) {
                console.log(error);
            }
        }, 500),
        []
    );

    // 更新購物車商品數量
    const handleQuantity = (el, type) => {
        const newProduct = product.map((item) => {
            if (item.salesKitGuid === el.salesKitGuid) {
                return {
                    ...item,
                    action: 'UPDATE',
                    quantity: type === 'plus' ? item.quantity + 1 : item.quantity - 1,
                };
            }
            return item;
        });
        setProduct(newProduct);

        handlePrice(newProduct);

        // 3.更新後端購物車資料庫
        debouncedFunction(newProduct);
    };

    // 依照勾選的商品更新價格
    const handlePrice = (updatedProduct) => {
        let newNetPrice = updatedProduct.reduce((acc, el) => {
            if (el.checked) {
                // return acc + (el.promoteGrossAmount >= 0 ? el.grossAmount * el.quantity : el.grossAmount * el.quantity);
                return acc + el.grossAmount * el.quantity;
            }
            return acc;
        }, 0);

        let newDiscount = updatedProduct.reduce((acc, el) => {
            if (el.checked) {
                return acc + (el.promoteGrossAmount < 0 ? 0 : el.grossAmount - el.promoteGrossAmount) * el.quantity;
            }
            return acc;
        }, 0);

        let newDeliveryFee = updatedProduct.reduce((acc, el) => {
            if (el.checked) {
                return acc + el.deliveryFee * el.quantity;
            }
            return acc;
        }, 0);

        setPrice({
            netPrice: newNetPrice,
            discount: newDiscount,
            deliveryFee: newDeliveryFee,
        });
    };

    // 刪除購物車商品
    const handleDelete = async (id) => {
        const deletedProduct = product.find((item) => item.salesKitGuid === id);

        if (deletedProduct) {
            const newProduct = product.filter((item) => item.salesKitGuid !== id);
            const { success, response, error } = await operation(
                {
                    url: '/ShoppingCart/deleteCart',
                    method: 'POST',
                    data: {
                        salesKitList: [deletedProduct],
                    },
                },
                'handleDeleteCart'
            );
            if (success) {
                setProduct(newProduct);
                handlePrice(newProduct);
            } else if (error) {
                console.log(error);
            }
        }
    };

    // 實際要結帳的資料
    const handleCheckOrder = (el, isChecked, isAllBtn) => {
        const newProduct = product.map((item) => {
            if (isAllBtn || item.salesKitGuid === el.salesKitGuid) {
                return { ...item, checked: isChecked };
            }
            return item;
        });
        setProduct(newProduct);
        handlePrice(newProduct);
    };

    // 前往結帳
    const handleCheckout = async () => {
        const checkOrderData = product
            .filter((item) => item.checked)
            .map((el) => {
                if (el.promoteGrossAmount < 0) {
                    el.promoteGrossAmount = el.grossAmount;
                }

                return el;
            });

        if (checkOrderData.length === 0) {
            Swal.fire({
                icon: 'info',
                title: t('please_select_a_product_first'),
                confirmButtonText: t('confirm'),
                confirmButtonColor: 'var(--primary)',
            });
            return;
        }
        const { success, response, error } = await operation(
            {
                url: '/SalSalesOrder/checkCart',
                method: 'POST',
                data: {
                    couponCode: isPromoCodeInputLocked && promoCode.length ? promoCode : null,
                    salesKitList: checkOrderData,
                },
            },
            'handleCheckout'
        );
        if (success) {
            if (response.data.status === '0') {
                navigator('/pdOrder', {
                    state:
                        isPromoCodeInputLocked && promoCode.length
                            ? {
                                  price,
                                  checkOrderData,
                                  promoCode,
                              }
                            : { price, checkOrderData },
                });
            } else if (response.data.status === '700') {
                Swal.fire({
                    icon: 'info',
                    title: t('some_items_price_changed_or_out_of_stock'),
                    text: t('confirm_then_refresh_cart'),
                    confirmButtonText: t('confirm'),
                    confirmButtonColor: 'var(--primary)',
                });
            }
        } else if (error) {
            console.log(error);
        }
    };

    // API
    const getCartList = async () => {
        const { success, response, error } = await operation(
            {
                url: '/ShoppingCart/getCartList',
            },
            'getCartList'
        );
        if (success) {
            console.log(response);
            const result = response.data.result.map((item) => ({
                ...item,
                checked: true,
            }));
            setProduct(result);
            setIsNeedToPayDeliveryFee(response.data.deliveryFee); // boolean

            handlePrice(result); // 更新價格
        } else if (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    title: t('please_login_first'),
                    icon: 'warning',
                    allowOutsideClick: false,
                    confirmButtonText: t('redirect_to_login_page'),
                    confirmButtonColor: 'var(--primary)',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigator('/login', {
                            state: { from: window.location.pathname },
                        });
                    }
                });
            }
        }
    };

    const handleResetPromoCode = () => {
        setPromoCode('');
        setIsPromoCodeInputLocked(false);

        getCartList();
    };

    const handleApplyPromoCode = async () => {
        if (promoCode.length < 5) {
            return setPromoCodeError(t('promotion_code_requirement'));
        }
        const { success, response, error } = await operation(
            {
                url: `/SalSalesDealerCoupon/coupon/${promoCode}`,
            },
            'handleApplyPromoCode'
        );
        if (success) {
            const newPriceList = response.data.data;
            // console.log(newPriceList);
            if (!newPriceList) {
                setPromoCodeError(t('promotion_code_not_exist'));
                return setPromoCode('');
            }

            let hasMatchingProduct = false;
            const matchingProductList = product.map((productItem) => {
                const newPriceItem = newPriceList.find((item) => item.salesKitGuid === productItem.salesKitGuid);

                if (newPriceItem) {
                    hasMatchingProduct = true;
                    return {
                        ...productItem,
                        action: 'UPDATE',
                        promoteGrossAmount: newPriceItem.promoteGrossAmount,
                        discount: productItem.grossAmount - newPriceItem.promoteGrossAmount,
                    };
                }
                return productItem;
            });

            if (!hasMatchingProduct) {
                setPromoCodeError(t('promotion_code_invalid'));
                return setPromoCode('');
            } else {
                setIsPromoCodeInputLocked(true);
            }

            setProduct(matchingProductList);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getCartList();
    }, []);

    useEffect(() => {
        handlePrice(product);
        return () => {};
    }, [product]);

    useEffect(() => {
        promoCode && promoCodeError && setPromoCodeError('');
    }, [promoCode]);

    return (
        <div className='container-fluid position-relative p-0 text-center bg-background' id='container-cart'>
            <div className='topTagArea'></div>

            <div className='mainArea wrapper mx-auto d-flex flex-column flex-lg-row px-3 px-sm-5 px-lg-0'>
                {/* 產品區塊 */}
                <section className='productSection d-flex flex-column w-100 bg-white mb-4 mb-lg-0'>
                    <div className='header d-flex p-3'>
                        <div className='headerItem checkHeader'>
                            <input
                                type='checkbox'
                                name='allPd'
                                id='allPd'
                                className='form-check-input'
                                checked={product.length > 0 && product.every((item) => item.checked)}
                                onChange={(e) => {
                                    handleCheckOrder(null, e.target.checked, true);
                                }}
                            />
                        </div>
                        <div className='headerItem contentHeader text-start'>
                            <span className=''>
                                {t('shopping_cart_items')}
                                <span>
                                    ({product.length ?? 0}
                                    {t('pieces')})
                                </span>
                            </span>
                        </div>
                        <div className='headerItem quantityHeader d-none d-lg-block'>
                            <span className=''>{t('quantity')}</span>
                        </div>
                        <div className='headerItem priceHeader d-none d-sm-block'>
                            <span className=''>{t('unit_price')}</span>
                        </div>
                        <div className='headerItem deleteHeader d-none d-sm-block'>
                            <span className=''>{t('remove')}</span>
                        </div>
                    </div>

                    {product.map((el) => {
                        return (
                            <div
                                className='body d-flex justify-content-center align-items-center'
                                key={el.salesKitGuid}
                            >
                                <div className='bodyItem checkBody pe-3 mb-6 mb-sm-0'>
                                    <input
                                        type='checkbox'
                                        name='pd1'
                                        id='pd1'
                                        className='form-check-input'
                                        checked={el.checked}
                                        onChange={(e) => handleCheckOrder(el, e.target.checked, false)}
                                    />
                                </div>
                                <div className='bodyItem contentBody d-flex flex-column  flex-lg-row align-items-start align-items-lg-center'>
                                    <div className='contentBody d-flex align-items-center mb-3 mb-lg-0'>
                                        <img className='pdImg me-2 me-sm-3' src={el.coverURL} alt='cart' />
                                        <div className='pdTitle text-start'>{el.name_01}</div>
                                        {/* sm以下才顯示 */}
                                        <div
                                            className='ms-3 d-block d-sm-none'
                                            onClick={() => handleDelete(el.salesKitGuid)}
                                        >
                                            <Icn_Trash />
                                        </div>
                                    </div>

                                    <div className='w-100 d-flex justify-content-between align-items-cente d-block d-lg-none'>
                                        {/* lg以下才顯示 */}
                                        <QuantityBody el={el} handleQuantity={handleQuantity} className={'sm'} />
                                        {/* sm以下才顯示 */}
                                        <div className='price text-primary d-block d-sm-none'>
                                            {`NT$${el.promoteGrossAmount.toLocaleString()}`}
                                        </div>
                                    </div>
                                </div>
                                {/* lg以上才顯示 */}
                                <QuantityBody el={el} handleQuantity={handleQuantity} className={'lg'} />
                                <div className='bodyItem priceBody d-none d-sm-block'>
                                    <div className='price text-primary'>
                                        {el.promoteGrossAmount >= 0
                                            ? `NT$${el.promoteGrossAmount.toLocaleString()}`
                                            : `NT$${el.grossAmount.toLocaleString()}`}
                                    </div>
                                    {el.promoteGrossAmount >= 0 ? (
                                        el.promoteGrossAmount === el.grossAmount ? null : (
                                            <div className='unitPrice text-grey6'>{`NT$${el.grossAmount.toLocaleString()}`}</div>
                                        )
                                    ) : null}
                                </div>
                                <div
                                    className='bodyItem deleteBody d-none d-sm-block'
                                    onClick={() => handleDelete(el.salesKitGuid)}
                                >
                                    <Icn_Trash />
                                </div>
                            </div>
                        );
                    })}
                </section>

                {/* 訂單區塊 */}
                <section className='orderSection d-flex flex-column align-items-center bg-white'>
                    <p className='title w-100 text-start px-4 py-3 mb-0'>{t('order_information')}</p>
                    <div className='content w-100 px-4 pt-4 pb-3 py-lg-3'>
                        <div className='priceWrap d-flex flex-column align-items-baseline pb-4 pb-lg-3'>
                            <div className='w-100 d-flex justify-content-between mb-1'>
                                <span>{t('subtotal')}：</span>
                                <span>
                                    NT$
                                    {price.netPrice.toLocaleString()}
                                </span>
                            </div>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>{t('discount')}：</span>
                                <span>
                                    NT$
                                    {price.discount.toLocaleString()}
                                </span>
                            </div>
                        </div>

                        <div className='promoteCodeWrap mt-4 mb-5 my-lg-4'>
                            <p className='promoteTitle text-start mb-2'>
                                <Icn_Tag className='me-1' />
                                {t('promo_code')}：
                            </p>

                            <div
                                className={`coupon_input_wrapper inputWrapper d-flex position-relative ${
                                    isPromoCodeInputLocked ? 'input_locked' : ''
                                }`}
                            >
                                <input
                                    type='text'
                                    className='w-100 form-control'
                                    placeholder={t('enter_promo_code')}
                                    value={promoCode}
                                    onChange={(e) =>
                                        e.target.value.length <= 5 && setPromoCode(e.target.value.toUpperCase())
                                    }
                                    readOnly={isPromoCodeInputLocked}
                                />
                                <button
                                    className='position-absolute d-flex align-items-center justify-content-center px-3'
                                    onClick={() => !isPromoCodeInputLocked && handleApplyPromoCode()}
                                >
                                    {t('apply')}
                                </button>
                            </div>
                            {isPromoCodeInputLocked ? (
                                <div className='cancel_promo' onClick={handleResetPromoCode}>
                                    Cancel
                                </div>
                            ) : null}
                            <div className='d-flex justify-content-start'>
                                <span style={{ color: 'red', fontSize: '14px', textAlign: 'start' }}>
                                    {promoCodeError}
                                </span>
                            </div>
                        </div>

                        <div className='shipAndTotalPriceWrap d-flex flex-column align-items-baseline pb-3'>
                            <div className='w-100 d-flex justify-content-between align-items-center mb-1'>
                                <span>{t('shipping_total')}</span>
                                <span>
                                    {/* 使用者後臺地址需要付運費才顯示 */}
                                    NT$
                                    {isNeedToPayDeliveryFee ? price.deliveryFee.toLocaleString() : 0}
                                </span>
                            </div>
                            <div className='w-100 d-flex justify-content-between align-items-center'>
                                <span>{t('order_total')}</span>
                                <span className='orderTotalPrice'>
                                    NT$
                                    {isNeedToPayDeliveryFee
                                        ? (price.netPrice - price.discount + price.deliveryFee).toLocaleString()
                                        : (price.netPrice - price.discount).toLocaleString()}
                                </span>
                            </div>
                        </div>

                        <div className='btnWrap d-flex flex-column w-100'>
                            <button
                                className='btn w-100 d-flex align-items-center justify-content-center mb-3 mb-sm-2'
                                onClick={() => navigator('/onlineStore')}
                            >
                                {t('continue_shopping')}
                            </button>
                            <button
                                className='btn btn-primary w-100 d-flex align-items-center justify-content-center text-white text-center border-0 mb-4 mb-sm-2'
                                onClick={() => handleCheckout()}
                            >
                                {t('go_to_checkout')}
                            </button>
                            {/* 確認服務條款 */}
                            <p className='text-start text-grey6 mb-0'>
                                {t('click_to_checkout_agree_to_terms')}
                                <Link className='text-primary mx-1' to={`/agreement/${lang}`} target='_blank'>
                                    {t('terms_of_service')}
                                </Link>
                                {t('and')}
                                <Link className='text-primary mx-1' to={`/privacy/${lang}`} target='_blank'>
                                    {t('privacy_policy')}
                                </Link>
                                。
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Cart;
