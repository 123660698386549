import './about.scss';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

/// 关于宾乐达
/// Author : YL
function About() {
    const { t } = useTranslation('about'); // i18n
    //點擊公司簡介跳轉至正確位置
    const companyIntroductionRef = useRef(null);

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            // console.log(ref.current);
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className='container-fluid p-0'>
            <article id='container-about' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <section className='about-section section1 row justify-content-center align-items-center position-relative'>
                    <div id='section1-1_img' className='text-center'>
                        <h1 className='banner_title'>{t('about_synerter')}</h1>
                        <section className='switchingPostionBar bg-white row align-items-center justify-content-center'>
                            <div className='col wrapper'>
                                <button
                                    className='active me-lg-7 bg-white border-0'
                                    onClick={() => scrollToSection(companyIntroductionRef)}
                                    href='#company_introduction'
                                >
                                    {t('company_introduction')}
                                </button>
                            </div>
                        </section>
                    </div>
                </section>

                <div className='smallWrapper'>
                    <section className='about-section section2 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div className='text-left'>{t('about_synerter_content')}</div>
                    </section>
                </div>

                <div className='wrapper fullWrapperRWD' ref={companyIntroductionRef}>
                    <section className='about-section section3 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div id='section3-1_img' className='text-left position-relative'>
                            <div className='banner_title'>
                                <h1>{t('company_introduction')}</h1>
                                <p>{t('company_introduction_content')}</p>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='smallWrapper'>
                    <section className='about-section section4 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div className='text-left '>{t('brand_info')}</div>
                        <div className='text-left mt-3 mt-lg-5 mb-5 mb-lg-0'>{t('company_mission')}</div>
                    </section>
                </div>

                {/* <div className="wrapper">
                    <section className="about-section section5 w-100 row align-items-center justify-content-center px-0 bg-white">
                        <button
                            type="button"
                            className="btn btn-size-customised btn-color-main rounded-pill text-white"
                        >
                            了解翔睿德集团
                        </button>
                    </section>
                </div> */}

                <div className='wrapper'>
                    <section className='about-section section6 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative'>
                        <div className='left'>
                            <div className='backWord'>TECHNOLOGY</div>
                            <div className='frontWord'>{t('high_tech')}</div>
                        </div>
                        <div className='right'>
                            <div className='wordArea'>
                                <div className='word'>{t('synerter_features')}</div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper'>
                    <section className='about-section section7 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative'>
                        <div className='left'>
                            <div className='backWord'>INNOVATION</div>
                            <div className='frontWord'>{t('innovation')}</div>
                        </div>
                        <div className='right'>
                            <div className='wordArea'>
                                <div className='word'>{t('innovation_content')}</div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper'>
                    <section className='about-section section8 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative'>
                        <div className='left'>
                            <div className='backWord'>PING-PONG</div>
                            <div className='frontWord'>{t('happy_sports')}</div>
                        </div>
                        <div className='right'>
                            <div className='wordArea'>
                                <div className='word'>{t('happy_sports_content')}</div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper'>
                    <section className='about-section section9 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative'>
                        <div className='left'>
                            <div className='backWord'>SERVICE</div>
                            <div className='frontWord'>{t('serve_the_public')}</div>
                        </div>
                        <div className='right'>
                            <div className='wordArea'>
                                <div className='word'>{t('serve_the_public_content')}</div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='wrapper fullWrapperRWD'>
                    <section className='about-section section10 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div id='section3-1_img' className='text-left position-relative'>
                            <div className='banner_title'>
                                <h1>{t('intellectual_right')}</h1>
                                <p>{t('intellectual_right_content')}</p>
                            </div>
                        </div>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default About;
