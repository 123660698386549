import { Link, useParams } from 'react-router-dom';
import './news.scss';
import Carousel from 'react-bootstrap/Carousel';
import { ReactComponent as Icn_calendar } from '../../../assets/images/icn_calendar.svg';
import { ReactComponent as Icn_appendix } from '../../../assets/images/icn_appendix.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from '../../../hooks/useAxios';

export default function News() {
    const { t } = useTranslation(['news']); // i18n

    const { bulletinGuid } = useParams();
    const { operation } = useAxios();
    const [news, setNews] = useState({});

    useEffect(() => {
        const getNews = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/service/bulletin/${bulletinGuid}`,
                    method: 'GET',
                },
                'getNews'
            );

            if (success) {
                console.log(response.data);
                setNews(response.data);
            }
            if (error) {
                console.log(error);
            }
        };
        getNews();
    }, []);

    return (
        <div className='container-fluid p-0 bg-white'>
            <article id='container-news' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <section className='news-section sectionTitle w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h1 className='px-0 mb-4 text-grey8 text-start'>{news.bulletin?.title}</h1>
                        <hr />
                        <h6 className='px-0 mb-0 text-grey8 text-start'>
                            <Icn_calendar className='me-2' style={{ marginBottom: '3px' }} />
                            {t('release_time')}：{news.bulletin?.releaseTime.split('T')[0]}
                        </h6>
                    </div>
                </section>
                <section className='news-section sectionContent w-100 row mb-10 mb-lg-14'>
                    <div
                        className='newsWrapper d-flex flex-column justify-content-between align-items-center px-3 px-lg-0 mb-7 mb-lg-7'
                        to='./1'
                    >
                        {/* <iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/cybaFQBB39s"
							frameborder="0"
							allowfullscreen
						></iframe> */}

                        {/* {news.pictureList?.map((el) => {
							return <img src={el.appendixURL} alt={el.appendixName} />;
						})} */}
                        {news.pictureList?.length === 0 ? (
                            <></>
                        ) : news.pictureList?.length === 1 ? (
                            <div className='imgWrapper w-100 mb-lg-4 bg-transparent'>
                                {news.pictureList.map((el) => {
                                    return <img key={el.appendixGuid} src={el.appendixURL} alt={el.appendixName} />;
                                })}
                            </div>
                        ) : (
                            <div className='imgWrapper w-100 mb-lg-4'>
                                <Carousel>
                                    {news.pictureList?.map((el) => {
                                        return (
                                            <Carousel.Item key={el.appendixGuid}>
                                                <img src={el.appendixURL} alt={el.appendixName} />
                                                <Carousel.Caption>
                                                    {/* <h3>First slide label</h3>
										<p>
											Nulla vitae elit libero, a pharetra
											augue mollis interdum.
										</p> */}
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        )}

                        <div className='textWrapper w-100 mb-4 mb-lg-6' style={{ whiteSpace: 'pre-line' }}>
                            {/* 將內容字符串顯示在元素內，並且將換行符號 \n 轉換為換行 */}
                            {news.bulletin?.content}
                        </div>

                        {news.appendixList?.length > 0 && (
                            <div className='appendixWrapper w-100 d-flex flex-column align-items-start'>
                                <p className='title mb-2'>
                                    <Icn_appendix className='me-2' style={{ marginBottom: '3px' }} />
                                    {t('attachments')}：
                                </p>
                                {news.appendixList.map((el) => {
                                    return (
                                        <Link
                                            key={el.appendixGuid}
                                            className='mb-2'
                                            to={`/${el.appendixURL}`}
                                            target='_blank' // 在新視窗中打開
                                            rel='noopener noreferrer' // 推薦添加的安全性屬性
                                        >
                                            {el.appendixName}
                                        </Link>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </section>
            </article>
        </div>
    );
}
