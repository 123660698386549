/* eslint-disable jsx-a11y/iframe-has-title */
import './homepage_new.scss';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { AccountContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Tick } from './Tick.svg';
import { ReactComponent as Cross } from './Cross.svg';
import { ReactComponent as Right } from './Right.svg';
import { ReactComponent as Left } from './Left.svg';
import { ReactComponent as BigTick } from './BigTick.svg';
import { ReactComponent as AddressIcon } from './AddressIcon.svg';
import { ReactComponent as EmailIcon } from './EmailIcon.svg';
import { ReactComponent as CallUsIcon } from './CallUsIcon.svg';

import useAxios from '../../../hooks/useAxios';

// import AddressIcon from './addressIcon/AddressIcon';
// import EmailIcon from './emailIcon/EmailIcon';
// import CallUsIcon from './callUsIcon/CallUsIcon';

function Homepage() {
    const { t } = useTranslation('homepage'); // i18n

    const { lang } = useContext(AccountContext);
    const { operation } = useAxios();
    const [newsList, setNewsList] = useState([]);

    // 多國語言
    let languageCode = 'ZF';

    if (lang === 'zh-CN') {
        languageCode = 'ZH';
    } else if (lang === 'en') {
        languageCode = 'EN';
    } else {
        languageCode = 'ZF';
    }

    useEffect(() => {
        const getNewsList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/service/getBulletinList?languageCode=${languageCode}`,
                    method: 'GET',
                },
                'getNewsList'
            );

            if (success) {
                console.log(response.data.result);
                setNewsList(response.data.result);
            }
            if (error) {
                console.log(error);
            }
        };
        getNewsList();
    }, [lang]);

    const navigate = useNavigate();

    const accountContext = useContext(AccountContext);
    const homePageIndex = accountContext.homePageIndex;
    const setHomePageIndex = accountContext.setHomePageIndex;
    const handleSelect = (selectedIndex, e) => {
        setHomePageIndex(selectedIndex);
        //// Navbar text color end
    };

    //carousel輪播控制
    const isLoaded = document.querySelectorAll('.visually-hidden')[1];
    useEffect(() => {
        document.querySelectorAll('.visually-hidden')[0].textContent = '';
        document.querySelectorAll('.visually-hidden')[1].textContent = '';
        document.querySelectorAll('.head-menu-item1').forEach((el) => {
            el.classList.add('homepageNavColor');
        });

        return () => {
            document.querySelectorAll('.head-menu-item1').forEach((el) => {
                el.classList.remove('homepageNavColor');
            });
        };
    }, [isLoaded]);

    const useResponsiveItemsPerPage = () => {
        const [itemsPerPage, setItemsPerPage] = useState(3);

        useEffect(() => {
            const handleResize = () => {
                if (window.matchMedia('(min-width: 769px)').matches) {
                    setItemsPerPage(3); // 768px 以上
                } else if (window.matchMedia('(min-width: 576px)').matches) {
                    setItemsPerPage(2); // 576px 到 767px
                } else {
                    setItemsPerPage(1); // 576px 以下
                }
            };

            handleResize(); // Initial check
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return itemsPerPage;
    };

    const itemsPerPage = useResponsiveItemsPerPage();
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(newsList.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = newsList.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(1); // Wrap around to the first page
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setCurrentPage(totalPages); // Wrap around to the last page
        }
    };

    return (
        <article id='homepage__container'>
            {/* carousel section */}
            <section className='carousel-section'>
                <Carousel activeIndex={homePageIndex} onSelect={handleSelect} variant={'dark'} interval={5000}>
                    <Carousel.Item>
                        <picture>
                            <source
                                className='d-block w-100'
                                media='(min-width: 992px)'
                                srcSet='./images/home/homepage_slide_1_2x.png'
                                alt='First slide'
                            />
                            <img
                                className='d-block w-100'
                                src='./images/home/homepage_slide_1_1x.png'
                                alt='First slide'
                            />
                        </picture>

                        <Carousel.Caption>
                            <h1 className='text-white mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                            <h2 className='text-white mb-3 mb-lg-4'>{t('ai_algorithm_imported_processor')}</h2>
                            <p className='text-white mb-3 mb-lg-6'>
                                {t('flagship_performance')}
                                <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                <br className=' d-lg-none' />
                                {t('high_precision_brushless_dc_motor')}
                            </p>
                            <div
                                className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                style={{ gap: '16px' }}
                            >
                                <Link className=' btn-size-m' to='/productcenter/productfeature'>
                                    <button type='button' className='btn btn-size-m btn-hover-white  rounded-pill '>
                                        {t('learn_more')}
                                    </button>
                                </Link>
                                {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                <Link className=' btn-size-m' to='/onlineStore'>
                                    <button
                                        type='button'
                                        className='btn btn-size-m btn-color-main rounded-pill text-white'
                                    >
                                        {t('buy_now')}
                                    </button>
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <picture>
                            <source
                                className='d-block w-100'
                                media='(min-width: 992px)'
                                srcSet='./images/home/homepage_slide_2_2x.png'
                                alt='Second slide'
                            />
                            <img
                                className='d-block w-100'
                                src='./images/home/homepage_slide_2_1x.png'
                                alt='Second slide'
                            />
                        </picture>

                        <Carousel.Caption>
                            <h1 className='text-white mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                            <h2 className='text-white mb-3 mb-lg-4'>{t('flexible_variation_start_training')}</h2>
                            <p className='text-white mb-3 mb-lg-6'>
                                {t('head_and_stand_description')}
                                <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                <br className=' d-lg-none' />
                                {t('automatic_circulation_no_ball_jamming')}
                            </p>
                            <div
                                className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                style={{ gap: '16px' }}
                            >
                                <Link className=' btn-size-m' to='/explore'>
                                    <button type='button' className='btn btn-size-m btn-hover-white  rounded-pill '>
                                        {t('learn_more')}
                                    </button>
                                </Link>
                                {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                <Link className=' btn-size-m' to='/onlineStore'>
                                    <button
                                        type='button'
                                        className='btn btn-size-m btn-color-main rounded-pill text-white'
                                    >
                                        {t('buy_now')}
                                    </button>
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <picture>
                            <source
                                className='d-block w-100'
                                media='(min-width: 992px)'
                                srcSet='./images/home/homepage_slide_3_2x.png'
                                alt='Third slide'
                            />
                            <img
                                className='d-block w-100'
                                src='./images/home/homepage_slide_3_1x.png'
                                alt='Third slide'
                            />
                        </picture>

                        <Carousel.Caption>
                            <h1 className='mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                            <h2 className='mb-3 mb-lg-4'>{t('app_control_efficient_extraordinary')}</h2>
                            <p className='mb-3 mb-lg-6'>
                                {t('customizable_ball_trajectory_parameters')}
                                <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                <br className=' d-lg-none' />
                                {t('cloud_storage_on-demand_access')}
                            </p>
                            <div
                                className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                style={{ gap: '16px' }}
                            >
                                <Link className=' btn-size-m' to='/app/download'>
                                    <button
                                        type='button'
                                        className='btn btn-size-m btn-color-outline-transparent-auxiliary  rounded-pill'
                                    >
                                        {t('learn_more')}
                                    </button>
                                </Link>
                                {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                <Link className=' btn-size-m' to='/onlineStore'>
                                    <button
                                        type='button'
                                        className='btn btn-size-m btn-color-main rounded-pill text-white'
                                    >
                                        {t('buy_now')}
                                    </button>
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>
            {/* About us section */}
            <div className='container text-center about-content' id='AboutUs'>
                <h1>{t('about_us')}</h1>
                <div className='d-md-flex flex-row-reverse '>
                    <div className='mt-5 mt-md-0 align-items-stretch'>
                        <img
                            src='./images/home/賓樂達乒乓球機器人 - 一起來場全新的桌球體驗吧 ! 2.jpg'
                            alt='ping__pong__picture'
                            style={{
                                objectFit: 'cover',
                                height: '100%', // 確保圖片高度隨左邊內容高度變化
                            }}
                            className='rounded-4 ping__pong__picture'
                        />
                    </div>
                    <div className='d-flex align-items-start justify-content-between flex-column mt-sm-0 mt-5 pe-0 pe-md-5'>
                        <h3>{t('synerter')}</h3>
                        <h5 className='text-start'>{t('company_introduction')}</h5>
                        <button
                            className='btn btn-outline-primary d-flex justify-content-center align-items-center MoreBtn rounded-5 MoreBtn_sm'
                            onClick={() => navigate('/about')}
                        >
                            <div className='text-nowrap'>Read More</div>
                            <svg
                                width='34'
                                height='34'
                                viewBox='0 0 74 74'
                                fill='#1278E8'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z'
                                    fill='#1278e8'
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* SYNERTER section */}
            <section className='about-content-gray' id='Synerter'>
                <div className='container text-center about-content'>
                    <h1>{t('synerter_ping_pong_robots')}</h1>
                    <div className='row'>
                        <div className='col-md col-12 d-flex align-items-stretch'>
                            <div className='video-container w-100'>
                                <iframe
                                    className='rounded-4 w-100 h-100'
                                    src='https://www.youtube.com/embed/zLBfzXNqSXE'
                                ></iframe>
                            </div>
                        </div>
                        <div className='col-md col-12 text-start bg-white rounded-4 d-flex align-items-center'>
                            <div className='mx-4 my-3'>
                                <h3 className='mb-2'>{t('top_brand_for_ping_pong_robots')}</h3>
                                <h5 className='text-start'>{t('top_brand_for_ping_pong_robots_details')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Advantages section */}
            <section className='container text-center about-content' id='Advantages'>
                <h1>{t('the_advantages_of_synerter')}</h1>
                <h5 className='text-start mb-md-7 mb-4'>{t('the_advantages_of_synerter_details')}</h5>
                <div className='row'>
                    <div className='col-md col-12 text-start d-flex align-items-start flex-column justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <BigTick />
                            <h3>{t('the_advantages_of_synerter_first')}</h3>
                        </div>
                        <h5 className='mt-2'>{t('the_advantages_of_synerter_first_details')}</h5>
                        <div className='d-flex align-items-center mt-2'>
                            <BigTick />
                            <h3>{t('the_advantages_of_synerter_second')}</h3>
                        </div>
                        <h5 className='mt-2'>{t('the_advantages_of_synerter_second_details')}</h5>
                        <div className='d-flex align-items-center mt-2'>
                            <BigTick />
                            <h3>{t('the_advantages_of_synerter_third')}</h3>
                        </div>
                        <h5 className='mt-2'>{t('the_advantages_of_synerter_third_details')}</h5>
                    </div>
                    <div className='col-md col-12 mt-4'>
                        <img
                            src='./images/home/賓樂達乒乓球機器人 - 一起來場全新的桌球體驗吧 ! 2 (1).jpg'
                            alt='sample'
                            style={{
                                border: '0',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                </div>
            </section>
            {/* Cart section */}
            <section className='about-content-gray' id='for_beginner_and_professional'>
                <div className='container text-center about-container about-content'>
                    <h1>{t('suitable_for_beginners_and_professional_players')}</h1>
                    <div className='h1__underline' />
                    <div className='row'>
                        <div className='col-12 col-sm-6'>
                            <div className='product__card--body'>
                                <div className='product__card--series mb-2'>{t('uno_series')}</div>
                                <div className='product__card--series--underline' />
                                <div className=''>
                                    <h4 className=''>{t('uno_suitable')}</h4>
                                    <h5 className=''>
                                        {t('the_best_training_partner_for_beginners_and_professionals')}
                                    </h5>
                                    <h5 className=''>
                                        {t('the_best_training_partner_for_beginners_and_professionals_2')}
                                    </h5>
                                </div>
                                <div className='text-start mt-6'>
                                    <h2 className='text-primary mb-2'>$33500-</h2>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('suitable_for_standard_or_non_standard_tables')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('coverage_of_the_entire_table')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('app_controlled')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('serve_angle_adjustable')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('spin_directions')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-end'>
                                            <Tick className='me-2' />
                                            {t('max_ball_speed')}
                                            <h4 className='text-primary'> 75 </h4>
                                            {t('km_h')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-end'>
                                            <Tick className='me-2' />
                                            {t('max_frequency')}
                                            <h4 className='text-primary'> 90 </h4>
                                            {t('balls_minute')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('adjustable_serve_time_gap')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='text-decoration-line-through text-secondary d-flex align-items-center'>
                                            <Cross className='me-2' />
                                            {t('multiple_people_training_simultaneously')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='text-decoration-line-through text-secondary d-flex align-items-center'>
                                            <Cross className='me-2' />
                                            {t('multiple_machines_can_serve_simultaneously')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='text-decoration-line-through text-secondary d-flex align-items-center'>
                                            <Cross className='me-2' />
                                            {t('control_serving_on_other_table_tennis_tables')}
                                        </h5>
                                    </div>
                                </div>
                                <button
                                    className='btn btn-outline-primary d-flex justify-content-center align-items-center MoreBtn rounded-5 w-100 mt-4 mt-md-9'
                                    onClick={() => navigate('/products/c9b389e4-d5eb-ee11-a190-00155d070505')}
                                >
                                    <p>Read More</p>
                                    <svg
                                        width='34'
                                        height='34'
                                        viewBox='0 0 74 74'
                                        fill='#1278E8'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z'
                                            fill='#1278e8'
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6'>
                            <div className='product__card--body'>
                                <div className='product__card--series mb-2'>{t('max_series')}</div>
                                <div className='product__card--series--underline' />
                                <div className=''>
                                    <h4 className=''>{t('max_suitable')}</h4>
                                    <h5 className=''>{t('simulates_playing_against_a_professional_player')}</h5>
                                    <h5 className=''>{t('ball_behavior_closely_mimics_real_gameplay')}</h5>
                                </div>
                                <div className='text-start mt-6'>
                                    <h2 className='text-primary mb-2'>$43500-</h2>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('suitable_for_standard_or_non_standard_tables')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('coverage_of_the_entire_table')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('app_controlled')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('serve_angle_adjustable')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('spin_directions')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-end'>
                                            <Tick className='me-2' />
                                            {t('max_ball_speed')}
                                            <h4 className='text-danger'> 90 </h4>
                                            {t('km_h')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-end'>
                                            <Tick className='me-2' />
                                            {t('max_frequency')}
                                            <h4 className='text-danger'> 120 </h4>
                                            {t('balls_minute')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('adjustable_serve_time_gap')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('multiple_people_training_simultaneously')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('multiple_machines_can_serve_simultaneously')}
                                        </h5>
                                    </div>
                                    <div>
                                        <h5 className='d-flex align-items-center'>
                                            <Tick className='me-2' />
                                            {t('control_serving_on_other_table_tennis_tables')}
                                        </h5>
                                    </div>
                                </div>
                                <button
                                    className='btn btn-primary d-flex justify-content-center align-items-center MoreBtn rounded-5 w-100 mt-4 mt-md-9'
                                    onClick={() => navigate('/products/c4d3f6ba-55eb-ee11-a190-00155d070505')}
                                >
                                    <p>Read More</p>
                                    <svg
                                        width='34'
                                        height='34'
                                        viewBox='0 0 74 74'
                                        fill='#1278E8'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z'
                                            fill='#ffffff'
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* videos section */}
            <section className='container text-center about-content' id='videos'>
                <div className='video__card row'>
                    <div className='col-12 col-md-6 text-start d-flex flex-column justify-content-between'>
                        <h3 className='text-primary'>林昀儒、陳思羽備戰奧運靠它！揭密乒乓球機器人</h3>
                        <div>
                            <h5 className='mt-2'>57東森財經新聞</h5>
                            <h5 className='mt-2'>
                                巴黎奧運即將登場，其中中華隊的桌球混雙項目，將由小林同學林昀儒和陳思羽參賽，
                                備戰期間除了選手對打練習，我們也獨家開箱國家訓練中心的秘密武器，這台MIT的AI
                                桌球機器人，發出來的球堪比真人，甚至還能高度還原對手的招式。
                            </h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <iframe
                            src='https://www.youtube.com/embed/0-MYpUP1g60?si=Hlj5IDK9eVtUWoeJ'
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            className='w-100 videosize rounded-4 mt-2'
                        ></iframe>
                    </div>
                </div>
                <div className='video__card row'>
                    <div className='col-12 col-md-6 order-md-last text-start d-flex flex-column justify-content-between'>
                        <h3 className='text-primary'>
                            【教練來說🎯】開箱賓樂達機器人!輕鬆組裝樂趣無窮。Feat.呂鴻森．范瑋庭
                        </h3>
                        <h5 className='mt-2'>
                            購買賓樂達乒乓球機器人後，擔心發生不會組裝的困境嗎?
                            呂鴻森教練與范瑋庭教練，來為大家分享乒乓球機器人的組裝過程~😍
                            男生女生都能快速上手的組裝流程，輕鬆不費力，馬上就能體驗桌球新感覺✨
                        </h5>
                    </div>
                    <div className='col-12 col-md-6 order-md-first'>
                        <iframe
                            src='https://www.youtube.com/embed/KKktbGhxjL0?si=DsPrjQQV6G7F6_v1'
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            // referrerpolicy='strict-origin-when-cross-origin'
                            // allowfullscreen
                            className='w-100 videosize rounded-4 mt-2'
                        ></iframe>
                    </div>
                </div>
                <div className='video__card row'>
                    <div className='col-12 col-md-6 text-start d-flex flex-column justify-content-between'>
                        <h3 className='text-primary'>【嘉婷教室】從零開始練桌球｜1-10正手切球</h3>
                        <div>
                            <h5 className='video__content d-flex align-items-center'>
                                <Tick className='me-2' />
                                對桌球有興趣的初學者
                            </h5>
                            <h5 className='video__content d-flex align-items-center'>
                                <Tick className='me-2' />
                                對桌球已有認識，想增進技巧的業餘玩家
                            </h5>
                            <h5 className='video__content d-flex align-items-center'>
                                <Tick className='me-2' />
                                對桌球非常熟悉，想要穩定訓練的桌球高手，你都能在這裡找到適合的訓練方法！
                            </h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <iframe
                            src='https://www.youtube.com/embed/j69Exe-v8DU?si=WvfOoS6SBPdjQIQj'
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            // referrerpolicy='strict-origin-when-cross-origin'
                            // allowfullscreen
                            className='w-100 videosize rounded-4 mt-2'
                        ></iframe>
                    </div>
                </div>
            </section>
            <div className='container text-center about-content'>
                {/* News section */}
                <section id='news'>
                    <h1 className='text-primary'>{t('news')}</h1>
                    {newsList && newsList.length > 0 ? (
                        <div className='d-flex justify-content-center'>
                            <div className='row justify-content-center'>
                                {currentItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className='col-lg-4 col-md-6 col-12 mb-4 news__card rounded-2'
                                        style={{ cursor: 'pointer' }} // Add this line
                                        onClick={() => navigate(`/news/${item.bulletinGuid}`)}
                                    >
                                        <div className='bg-grey1 mt-3'>
                                            <img
                                                // src='./images/home/賓樂達乒乓球機器人 - 一起來場全新的桌球體驗吧 ! 2.jpg'
                                                src={item.coverPath}
                                                alt='sample'
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                className='rounded-4'
                                            />
                                        </div>
                                        <div className='p-3 text-center'>
                                            <h5 className='text-center'>{item.title}</h5>
                                            <div className='news__underline mt-1' />
                                            <h6 className='py-3 text-break'>{item.summary}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p>{t('no_content_available_at_the_moment')}</p>
                    )}
                    <div className='d-flex justify-content-between'>
                        <button className='btn p-0' onClick={handlePrevPage}>
                            <Left className='text-primary' />
                        </button>
                        <button className='btn p-0' onClick={handleNextPage}>
                            <Right className='text-primary' />
                        </button>
                    </div>
                </section>
            </div>

            {/* Contact Us section */}
            <section className='about-content-gray' id='contact_us'>
                <div className='container text-center about-content'>
                    <h1>{t('contact_us')}</h1>
                    <div className='row gy-4'>
                        <div className='col-md-6'>
                            <img
                                className='d-block rounded-4 object-fit-cover w-100 h-100'
                                src='./images/home/map.png'
                                alt='Company Map'
                            />
                        </div>
                        <div className='col-md-6 d-flex flex-column justify-content-between'>
                            <div className='text-primary text-start mb-4'>
                                <h3>{t('synerter_co_ltd')}</h3>
                                <h3>{t('experience_center')}</h3>
                                <h5>---{t('Call_to_schedule_your_experience')}---</h5>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='pb-4'>
                                        <div className='bg-grey1 px-3 py-2 px-lg-7 py-lg-4 rounded-4 text-center'>
                                            <a
                                                href='https://maps.app.goo.gl/XwyJCP91oRqWDnGx9'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className='d-inline-flex align-items-center justify-content-center border border-2 rounded-circle border-primary p-lg-3 p-2 mb-2 md-md-3 icon__container'
                                            >
                                                <AddressIcon className='responsive_svg' />
                                            </a>
                                            <h5 className='text-primary md-1'>Our Address</h5>
                                            <h6>{t('address')}</h6>
                                            <h6>（{t('address_details')}）</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 '>
                                    <div className='px-3 py-2 px-lg-7 py-lg-4  bg-grey1 rounded-4 w-100 h-100'>
                                        <div className='text-center'>
                                            <a
                                                href='mailto:contact@synerter.com'
                                                className='d-inline-flex align-items-center justify-content-center border border-2 rounded-circle border-primary p-lg-3 p-2 mb-2 md-md-3 icon__container'
                                            >
                                                <EmailIcon className='responsive_svg' />
                                            </a>
                                            <h5 className='text-primary mb-1'>Email Us</h5>
                                            <h6 className='text-wrap text-break'>contact@synerter.com</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='px-3 py-2 px-lg-7 py-lg-4 bg-grey1 rounded-4 w-100 h-100'>
                                        <div className='text-center'>
                                            <a
                                                href='tel:+886288666008'
                                                className='d-inline-flex align-items-center justify-content-center border border-2 rounded-circle border-primary p-lg-3 p-2 mb-2 md-md-3 icon__container'
                                            >
                                                <CallUsIcon className='responsive_svg' />
                                            </a>
                                            <h5 className='text-primary mb-1'>Call Us</h5>
                                            <h6>(02) 8866-6008</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    );
}

export default Homepage;
