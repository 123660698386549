import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from './AdvancedSearchResultTag';
import UpdateActScheduleRegistrationModal from './UpdateActScheduleRegistrationModal';
import './actScheduleRegistration.scss';
import useAxios from '../../../hooks/useAxios';

// 總結：
// 這是一個 React 函數組件，管理與使用者註冊數據、排序和進階搜尋相關的狀態。
// 主要特點包括：
// 1. 狀態管理：
//    - 使用 useState 鉤子定義多個狀態變數，例如 isGetActUserList、registrationList、windowWidth、
//      updateActRegistrationModalOpen、registrationData、sortBy、orderBy 等。
// 2. 進階搜尋：
//    - 實現了一個帶有模態對話框（advancedSearchModalOpen）和相關狀態變數（advancedSearchDataList、
//      displayList）的進階搜尋功能。
//    - searchContentList 數組定義了不同的搜索標準，包括類型、列名和搜索選項。
//    - deleteAllFilters 和 deleteFilter 函數允許用戶管理和刪除進階搜索篩選器。
// 3. 排序：
//    - 使用 sortBy 和 orderBy 狀態變數實現排序功能。
//    - handleSortChange 函數處理排序偏好更改。
// 4. 事件監聽器和效應：
//    - handleResize 函數在窗口調整大小事件時更新 windowWidth 狀態。
//    - useEffect 鉤子用於添加和刪除窗口大小更改的事件監聽器。
// 5. API 交互：
//    - fetchData 函數負責從 API 端點（web/ActRegistration/getRegistrationList）獲取註冊數據。
//    - 使用 Axios 發送帶有排序和搜索參數的 POST 請求。
// 6. 初始化：
//    - useEffect 鉤子使用空依賴數組確保在組件掛載時以及 sortBy 或 orderBy 更改時調用 fetchData。

const ActScheduleRegistration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { operation } = useAxios();

    const { scheduleGuid } = useParams('scheduleGuid');

    const [isGetActUserList, setIsGetActUserList] = useState(false);
    const [registrationList, setRegistrationList] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // 編輯使用者 dialog
    const [updateActRegistrationModalOpen, setUpdateActRegistrationModalOpen] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);
    const [activityData, setActivityData] = useState(null); // 活動資訊
    const [scheduleData, setScheduleData] = useState(null); // 活動資訊

    // Sorting states
    const [sortBy, setSortBy] = useState('time_Registry');
    const [orderBy, setOrderBy] = useState('desc');

    const statusList = {
        0: '"0"：未報到',
        1: '"1"：已報到',
        2: '鎖定（停用）',
    };

    //#region AdvancedSearch region
    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    const searchContentList = [
        // 'name',
        // 'phone',
        // 'time_Registry',
        // 'queueNo',
        // 'time_Playing',
        // 'score',
        // 'time_Checkin',
        // 'Status',
        {
            column: 'name',
            name: '姓名',
            type: 'text',
            tableColumn: 'name', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'name',
            selectOptionList: ['==', '!=', 'contains', '!contains'],
        },
        {
            column: 'phone',
            name: '電話號碼',
            type: 'text',
            tableColumn: 'phone', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'phone',
            selectOptionList: ['==', '!='],
        },
        {
            column: 'time_Registry',
            name: '報名時間',
            type: 'time',
            tableColumn: 'time_Registry',
            searchFromDataBase: 'time_Registry',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'time_Checkin',
            name: '報到時間',
            type: 'time',
            tableColumn: 'time_Checkin',
            searchFromDataBase: 'time_Checkin',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'time_Playing',
            name: '上場時間',
            type: 'time',
            tableColumn: 'time_Playing',
            searchFromDataBase: 'time_Playing',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'queueNo',
            name: '報名號次',
            tableColumn: 'queueNo',
            searchFromDataBase: 'queueNo',
            type: 'number',
            selectOptionList: ['==', '!=', '>', '<'],
        },
        {
            column: 'score',
            name: '得分',
            tableColumn: 'score',
            searchFromDataBase: 'score',
            type: 'number',
            selectOptionList: ['==', '!=', '>', '<'],
        },
        {
            column: 'rank',
            name: '排名',
            tableColumn: 'rank',
            searchFromDataBase: 'rank',
            type: 'number',
            selectOptionList: ['==', '!=', '>', '<'],
        },
    ];

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'name',
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['==', '!=', 'contains', '!contains'],
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    /**
     * Deletes a filter from the advanced search data list and the display list.
     *
     * @param {number} targetIndex - The index of the filter to delete.
     * @return {undefined} This function does not return a value.
     */

    const deleteFilter = (targetIndex) => {
        const newAdvancedSearchDataList = advancedSearchDataList.filter((_, index) => index !== targetIndex);
        const newDisplayList = displayList.filter((_, index) => index !== targetIndex);
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null);
    };
    //#endregion

    // 使用 useState 來儲存視窗寬度的狀態

    // 處理視窗大小改變時的函數
    const handleResize = () => {
        // 當視窗大小變化時更新視窗寬度的狀態
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // 添加視窗大小改變事件監聽器
        window.addEventListener('resize', handleResize);

        // 在組件卸載時清除監聽器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 空陣列表示僅在組件掛載和卸載時運行一次

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = async (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `/ActRegistration/getRegistrationList?guid=${scheduleGuid}`;
        // console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sortBy} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        const { success, response, error } = await operation(
            {
                url,
                method: 'POST',
                data: reqBody,
            },
            'fetchData'
        );
        if (success) {
            setRegistrationList(response.data.data);
            setActivityData(response.data.activity);
            setScheduleData(response.data.schedule);
            setIsGetActUserList(true);

            function isArrayFn(obj) {
                if (typeof Array.isArray === 'function') {
                    return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
                } else {
                    // 否則就使用 toString 方法
                    return Object.prototype.toString.call(obj) === '[object Array]';
                }
            }

            /// 這和 api 回傳的 result 無關
            // 如果有 advancedSearchDataList, 且是 array
            // console.log('original', advancedSearchDataList);
            if (isArrayFn(advancedSearchDataList)) {
                // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
                // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
                advancedSearchDataList.map((el) => {
                    searchContentList.map((item) => {
                        // console.log(item);
                        if (el.searchFilter === item.tableColumn) {
                            el.searchFilter = item.searchFromDataBase;
                        }
                    });
                });

                // console.log('after edit', advancedSearchDataList);

                setDisplayList(advancedSearchDataList);
                setAdvancedSearchDataList(advancedSearchDataList);
            }

            setAdvancedSearchModalOpen(false);
        } else if (error) {
            console.log(error);
        }
    };

    const handleSortChange = (newTarget) => {
        let newSortBy;
        let newOrderBy;

        const oldTarget = sortBy.split(' ')[0];

        if (oldTarget === newTarget) {
            newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
        } else {
            newOrderBy = 'desc';
        }

        newSortBy = `${newTarget}`;

        setSortBy(newSortBy);
        setOrderBy(newOrderBy);
    };

    useEffect(() => {
        // list data
        fetchData();
    }, [sortBy, orderBy]);

    return (
        <section id='section-main'>
            <div id='container-actRegistration'>
                <div className='articleBoxWithSideBox px-xl-5 px-lg-5 px-md-0 px-sm-0'>
                    <h2 className='pageTitle pageTitle_main'>
                        {activityData ? `${activityData?.code} ${activityData?.name}` : ''}
                    </h2>
                    <h2 className='pageTitle pageTitle_time'>
                        {activityData ? dayjs(scheduleData?.startDate).format('YYYY/MM/DD') : ''}
                    </h2>
                    <h2 className='pageTitle pageTitle_subtitle'>
                        {activityData ? `${activityData?.boothNumber} ${activityData?.boothName}` : ''}
                    </h2>

                    <h2 className='pageTitle pageTitle_subtitle'>使用者查詢</h2>
                    <div className='searchWrapper bg-white p-4 rounded-4'>
                        <div className='searchBox row flex-wrap'>
                            <button
                                className='searchBtn col-2 btn btn-size-s btn-color-main'
                                onClick={() => setAdvancedSearchModalOpen(true)}
                            >
                                搜尋
                            </button>
                            <AdvancedSearchResultTag
                                advancedSearchDataList={advancedSearchDataList}
                                deleteAllFilters={deleteAllFilters}
                                deleteFilter={deleteFilter}
                                ns='lgsPurchaseOrder'
                            />
                        </div>
                    </div>
                    <div className='searchTableWrapper bg-white p-2 rounded-4'>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='firmwareListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            <th width='48' scope='col' className='text-grey7 border-0'>
                                                #<span></span>
                                            </th>
                                            <th
                                                width='240'
                                                scope='col'
                                                className='text-grey7 border-0'
                                                onClick={() => handleSortChange('name')}
                                            >
                                                使用者名稱(暱稱)
                                                {sortBy === 'name' &&
                                                    (orderBy === 'desc' ? (
                                                        <span className='ml-1'>▼</span>
                                                    ) : (
                                                        <span className='ml-1'>▲</span>
                                                    ))}
                                            </th>
                                            <th
                                                width='160'
                                                scope='col'
                                                className='text-grey7 border-0'
                                                onClick={() => handleSortChange('phone')}
                                            >
                                                手機號碼
                                                {sortBy === 'phone' &&
                                                    (orderBy === 'desc' ? (
                                                        <span className='ml-1'>▼</span>
                                                    ) : (
                                                        <span className='ml-1'>▲</span>
                                                    ))}
                                            </th>
                                            {windowWidth > 992 && (
                                                <>
                                                    <th
                                                        width='160'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('time_Registry')}
                                                    >
                                                        報名時間
                                                        {sortBy === 'time_Registry' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='120'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('queueNo')}
                                                    >
                                                        報名序號
                                                        {sortBy === 'queueNo' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='160'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('time_Checkin')}
                                                    >
                                                        報到時間
                                                        {sortBy === 'time_Checkin' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='160'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('time_PLaying')}
                                                    >
                                                        遊戲時間
                                                        {sortBy === 'time_PLaying' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='84'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('score')}
                                                    >
                                                        分數
                                                        {sortBy === 'score' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='84'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('rank')}
                                                    >
                                                        排名
                                                        {sortBy === 'rank' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='160'
                                                        scope='col'
                                                        className='text-grey7 border-0'
                                                        onClick={() => handleSortChange('status')}
                                                    >
                                                        狀態
                                                        {sortBy === 'status' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                </>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {isGetActUserList &&
                                            (registrationList ? (
                                                registrationList?.map((list, index) => {
                                                    return (
                                                        <tr
                                                            id={list.registrationGuid}
                                                            key={list.registrationGuid}
                                                            onClick={() => {
                                                                setUpdateActRegistrationModalOpen(true);
                                                                setRegistrationData({
                                                                    ...registrationData,
                                                                    registrationGuid: list.registrationGuid,
                                                                });
                                                            }}
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>{list.name}</td>
                                                            <td>{list.phone}</td>
                                                            {windowWidth > 992 && (
                                                                <>
                                                                    <td>
                                                                        {list.time_Registry
                                                                            ? dayjs(list.time_Registry).format(
                                                                                  'HH:mm:ss'
                                                                              )
                                                                            : ''}
                                                                    </td>
                                                                    <td>{list.queueNo}</td>
                                                                    <td>
                                                                        {list.time_Checkin
                                                                            ? dayjs(list.time_Checkin).format(
                                                                                  'HH:mm:ss'
                                                                              )
                                                                            : ''}
                                                                    </td>
                                                                    <td>
                                                                        {list.time_Playing
                                                                            ? dayjs(list.time_Playing).format(
                                                                                  'HH:mm:ss'
                                                                              )
                                                                            : ''}
                                                                    </td>
                                                                    <td>{list.score}</td>
                                                                    <td>{list.rank}</td>
                                                                    <td width={160}>{statusList[list.status]}</td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='7'>無版本紀錄</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/*讀取畫面*/}
                                <div
                                    className={`text-center loadingBox ${isGetActUserList && 'd-none'}`}
                                    id='loadingBox'
                                >
                                    <h6 className='sr-only'>請查詢</h6>
                                </div>

                                {registrationData && (
                                    <UpdateActScheduleRegistrationModal
                                        updateActRegistrationModalOpen={updateActRegistrationModalOpen}
                                        setUpdateActRegistrationModalOpen={setUpdateActRegistrationModalOpen}
                                        registrationData={registrationData}
                                        setRegistrationData={setRegistrationData}
                                    />
                                )}

                                {/* modal */}
                                <AdvancedSearchModal
                                    isOpen={advancedSearchModalOpen}
                                    handleClose={() => setAdvancedSearchModalOpen(false)}
                                    advancedSearchDataTemp={advancedSearchDataTemp}
                                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                                    displayList={[advancedSearchDataTemp]}
                                    searchContentList={searchContentList}
                                    fetchData={fetchData}
                                    ns='ActScheduleRegistration'
                                    restSearchData={[]}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ActScheduleRegistration;
