import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Wrapper = styled.div`
    font-size: 14px;
`;

const Title = styled.div`
    font-size: 16px;
    color: #989898;
`;

export default function OrderPaymentDetails({ order, t }) {
    const navigate = useNavigate();
    const { operation } = useAxios();

    const {
        orderGuid,
        paymentMethod,
        paymentStatus,
        grossAmount,
        orderDate,
        carrierId,
        invoiceStatus,
        invoiceFormat,
        npoban,
        taxID,
        companyName,
    } = order;
    // console.log(order);

    const paymentDueDate = dayjs(orderDate).add(48, 'h').format('YYYY-MM-DD HH:mm:ss');
    const [invoice, setInvoice] = useState(null);
    const invoiceDate = dayjs(invoice?.invoiceTime).add(48, 'h').format('YYYY-MM-DD HH:mm:ss');

    //getInvoiceList
    const getInvoiceList = async () => {
        const { success, response, error } = await operation(
            {
                url: `/InvInvoice/getInvoice`,
                method: 'POST',
                data: { orderGuid: orderGuid },
            },
            'getInvoiceList'
        );
        if (success) {
            console.log(response.data);
            setInvoice(response.data.invoice);
        } else if (error) {
            console.log(error);
        }
    };

    const paymentStatusList = {
        0: t('not_paid'),
        1: t('complete_payment'),
        2: t('partial_payment'),
    };

    const paymentMethodList = {
        0: t('credit_card_payment'),
        1: t('cash_payment'),
        2: t('remittance'),
    };

    const invoiceType = {
        0: t('electronic_invoice'), //電子發票
        1: t('corporate_invoice'), //公司戶
        2: t('donation_invoice'), //捐贈
        3: t('mobile_barcode_carrier'), //載具
    };

    const invoiceStatusList = {
        0: t('not_yet_issued'),
        1: t('issued'),
    };
    useEffect(() => {
        getInvoiceList();
    }, []);
    return (
        <Wrapper>
            <div className='mt-2'>
                <section className='border-sm-0 border'>
                    <div className='p-4'>
                        <Title>{t('payment_information')}</Title>
                        <div className='row'>
                            <div className='col-12 col-md-6 mt-4'>
                                <div>{`${t('payment_method')} : ${paymentMethodList[paymentMethod]}`}</div>
                                {paymentMethod === '2' && paymentStatus === '0' && (
                                    <>
                                        <div>{`${t('bank_code')} : 006`}</div>
                                        <div>{`${t('receiver_account')} : 5089-898-100889`}</div>
                                        <div>{`${t('payment_deadline')} : ${t('before_payment_due_date', {
                                            dueDate: paymentDueDate,
                                        })}`}</div>
                                    </>
                                )}
                                <div>{`${t('payment_amount')} : NT$${grossAmount}`}</div>
                                <div>{`${t('payment_status')} : ${paymentStatusList[paymentStatus]}`}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    );
}
