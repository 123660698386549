import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import useAxios from '../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

const ActScheduleRegistration = () => {
    const { auth } = useAuth();

    console.log(auth);

    const { operation } = useAxios();
    const { t } = useTranslation('pdCheckoutInfo'); // i18n
    const navigate = useNavigate();
    const { orderGuid } = useParams('orderGuid');
    const [isGettingData, setIsGettingData] = useState(true);
    const [order, setOrder] = useState(null);

    //   const [password, setPassword] = useState("");
    //   const [isCorrectPassword, setIsCorrectPassword] = useState(true);
    //   const [isValid, setIsValid] = useState(false);

    //   const statusList = {
    //     0: '"0"：未報到',
    //     1: '"1"：已報到',
    //     2: "鎖定（停用）",
    //   };

    //   const realPassword = "55866301";
    //   const key = "02-88666008";

    //#region AdvancedSearch region
    //   const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    //   const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    //   const [displayList, setDisplayList] = useState([]);

    //   const searchContentList = [
    //     {
    //       column: "fullname",
    //       name: "姓名",
    //       type: "text",
    //       tableColumn: "fullname", // call fetchData 時 db 會去找的 column name
    //       searchFromDataBase: "fullname",
    //       selectOptionList: ["contains", "!contains"],
    //     },
    //     {
    //       column: "phone",
    //       name: "電話號碼",
    //       type: "number",
    //       tableColumn: "phone", // call fetchData 時 db 會去找的 column name
    //       searchFromDataBase: "phone",
    //       selectOptionList: ["contains", "!contains"],
    //     },
    //   ];

    //   const paymentMethodList = {
    //     0: "信用卡付款",
    //     1: "付現金",
    //   };

    //   const advancedSearchDataTemp = {
    //     searchLogic: "AND",
    //     column: "name",
    //     searchFilter: "fullname",
    //     searchOperator: "==",
    //     searchValue: "",
    //     searchContent: "",
    //     inputWarningContent: "",
    //     selectOptionList: ["contains", "!contains"],
    //   };

    //   const deleteAllFilters = () => {
    //     setDisplayList([]);
    //     setAdvancedSearchDataList([]);
    //     fetchData();
    //   };

    //   const deleteFilter = (targetIndex) => {
    //     const newAdvancedSearchDataList = advancedSearchDataList.filter(
    //       (_, index) => index !== targetIndex
    //     );
    //     const newDisplayList = displayList.filter(
    //       (_, index) => index !== targetIndex
    //     );
    //     setDisplayList(newDisplayList);
    //     setAdvancedSearchDataList(newAdvancedSearchDataList);
    //     fetchData(
    //       newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
    //     );
    //   };
    //#endregion

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The el of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */

    const getList = useCallback(() => {
        const gettingList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/salSalesOrder/getSalesOrder/${orderGuid}`,
                },
                'PdCheckout'
            );
            if (success) {
                console.log(response.data.result);
                //
                setOrder(response.data.result);
                setIsGettingData(false);

                // if (res.data.status === "1") {
                // } else if (res.data.status === "2") {
                // } else if (res.data.status === "3") {
                // } else if (res.data.status === "0") {
                // }
            } else if (error) {
                console.log(error);
            }
        };

        gettingList();
    }, []);

    useEffect(() => {
        getList();
        // sortTable(5)
    }, [getList]);

    return (
        <section id='section-main'>
            <div id='container-pdCheckout' className='d-flex flex-column justify-content-center align-items-center'>
                <div className='articleBoxWithSideBox px-0'>
                    <h2 className='pageTitle pageTitle_main'>{t('order_completed_thank_you_for_your_purchase')}</h2>

                    <div className='searchTableWrapper bg-white p-2 rounded-4'>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='firmwareListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='titleHead'>
                                            <th className='px-0'>{t('order_details')}</th>
                                        </tr>

                                        <tr className='bg-grey '>
                                            <th className='text-start px-3 py-1'>{t('product_information')}</th>
                                            <th className='px-3 py-1'>{t('subtotal')}</th>
                                        </tr>
                                    </thead>

                                    <tbody className='border-top-0'>
                                        {order?.salesOrderItemList.map((el) => {
                                            return (
                                                <tr key={el?.itemGuid}>
                                                    <th className='px-3'>{`${el?.quantitySales}X ${el?.name_01}`}</th>
                                                    <th className='px-3'>{`NT$${el?.grossAmount}`}</th>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <th className='px-3'>{t('shipping_fee')}</th>
                                            <th className='px-3'>{`NT$${order?.deliveryFee}`}</th>
                                        </tr>
                                        <tr>
                                            <th className='text-rainbow-animation-removed fw-bold text-bold px-3'>
                                                {t('actual_payment_amount')}
                                            </th>

                                            <th className='text-rainbow-animation-removed fw-bold text-bold px-3'>
                                                {`NT$${order?.grossAmount}`}
                                            </th>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan='2' className='px-0 text-grey6 fontSize75Rem'>
                                                {t('dear_user_hello')}
                                            </td>
                                        </tr>
                                        <tr className='w-100'>
                                            <td colSpan='2' className='w-100 text-center'>
                                                <button
                                                    type='button'
                                                    className='btn btn-size-l btn-color-main rounded-pill text-white'
                                                    onClick={() => navigate('/onlineStore')}
                                                >
                                                    {t('return_to_store_homepage')}
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ActScheduleRegistration;
