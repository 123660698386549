import './newsUpdateChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import { ReactComponent as Icn_Cancel } from '../../../assets/images/icn_Cancel.svg';

const NewsUpdateChange = () => {
    const { operation } = useAxios();

    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState({
        bulletin: {
            // 預設值
            languageCode: 'ZF', // 繁體中文
            status: 1,
        },
        bulletinAppendixList: [],
    });
    const [progress, setProgress] = useState(0);

    const paramsGuid = useParams('bulletinGuid');
    const navigate = useNavigate();

    //#endregion

    //#region [function]

    // 前端給予新GUID
    function generateGuid() {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    const handleCoverImage = (e) => {
        // console.log('e.target.files[0]', e.target.files);
        const filesArray = Array.from(e.target.files);

        setData({
            ...data,
            bulletin: {
                ...data.bulletin,
                coverFile: e.target.files[0],
                coverFileName: e.target.files[0].name,
            },
        });
    };

    const handleSetAppendix = (e, category) => {
        // console.log('e.target.files[0]', e.target.files);
        const filesArray = Array.from(e.target.files);
        const newAppendixList = filesArray.map((file) => ({
            appendixGuid: generateGuid(),
            action: 'insert',
            displayOrder: data.bulletinAppendixList.length + 1,
            appendixName: file.name,
            appendixFile: file,
            category, // 圖片為0
        }));

        setData({
            ...data,
            bulletinAppendixList: [...data.bulletinAppendixList, ...newAppendixList],
        });
    };

    // 刪除appendix
    const handleDeleteAppendix = (appendixGuid) => {
        const newAppendixList = [...data.bulletinAppendixList];

        newAppendixList.forEach((el, index) => {
            if (el.appendixGuid === appendixGuid) {
                console.log('el', el);

                // 先判斷狀態
                if (el.action === 'origin') {
                    el.action = 'delete';
                } else {
                    newAppendixList.splice(index, 1);
                }
            }
        });
        setData({ ...data, bulletinAppendixList: newAppendixList });
    };

    const handleDeleteItem = async (e) => {
        e.preventDefault();
        const { success, response, error } = await operation(
            {
                url: `/service/Bulletin/${data.bulletin.bulletinGuid}`,
                method: 'DELETE',
            },
            'handleDeleteItem'
        );
        if (success) {
            // console.log(response.data);
            navigate('/newsupdate');
        } else if (error) {
            console.log(error);
        }
    };

    // 追蹤進度
    const handleUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
            const percent = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(Math.round(percent));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        const { bulletin, bulletinAppendixList } = data;

        Object.keys(bulletin).forEach((key) => {
            formData.append(`Bulletin.${key}`, bulletin[key]);
        });

        bulletinAppendixList.map((el, idx) => {
            Object.keys(el).map((key) => {
                formData.append(`BulletinAppendixList[${idx}].${key}`, el[key]);
            });
        });

        if (Object.keys(paramsGuid).length) {
            //edit
            setIsLoading(true);
            const { success, response, error } = await operation(
                {
                    url: '/service/bulletin',
                    method: 'PUT',
                    data: formData,
                    onUploadProgress: (progressEvent) => handleUploadProgress(progressEvent), // 追踪进度
                },
                'handleEdit'
            );
            if (success) {
                // console.log(response.data);
                setIsLoading(false);
                navigate('/newsupdate');
            } else if (error) {
                setIsLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: '網路不穩，儲存失敗',
                    text: '請再次儲存。',
                });
                console.log(error);
            }
        } else {
            //create
            setIsLoading(true);
            const { success, response, error } = await operation(
                {
                    url: '/service/bulletin',
                    method: 'POST',
                    data: formData,
                    onUploadProgress: (progressEvent) => handleUploadProgress(progressEvent), // 追蹤進度
                },
                'handleCraete'
            );
            if (success) {
                // console.log(response.data);
                setIsLoading(false);
                navigate('/newsupdate');
            } else if (error) {
                setIsLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: '網路不穩，儲存失敗',
                    text: '請再次儲存。',
                });
                console.log(error);
            }
        }
    };

    //#endregion

    //#region [useEffect]

    // getDetails
    useEffect(() => {
        const fetchOnlineVideoDetails = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/service/bulletin/${paramsGuid.bulletinGuid}`,
                },
                'fetchOnlineVideoDetails'
            );
            if (success) {
                // console.log(response.data);

                const { bulletin, appendixList, pictureList } = response.data;
                const {
                    bulletinGuid,
                    category,
                    summary,
                    content,
                    displayOrder,
                    endTime,
                    languageCode,
                    releaseTime,
                    rowStamp,
                    status,
                    title,
                } = bulletin;

                const newAppendixList = appendixList.map((el) => ({
                    ...el,
                    action: 'origin',
                }));

                const newPictureList = pictureList.map((el) => ({
                    ...el,
                    action: 'origin',
                }));
                setData({
                    bulletin: {
                        ...bulletin,
                        bulletinGuid,
                        category,
                        summary,
                        content,
                        displayOrder,
                        endTime: endTime.toLocaleString().split(':')[0] + ':' + endTime.toLocaleString().split(':')[1],
                        languageCode,
                        releaseTime:
                            releaseTime.toLocaleString().split(':')[0] +
                            ':' +
                            releaseTime.toLocaleString().split(':')[1],
                        rowStamp,
                        status,
                        title,
                    },
                    bulletinAppendixList: [...newAppendixList, ...newPictureList],
                });

                const data = response.data;

                data.bulletin.releaseTime =
                    data.bulletin.releaseTime.toLocaleString().split(':')[0] +
                    ':' +
                    data.bulletin.releaseTime.toLocaleString().split(':')[1];

                data.bulletin.endTime =
                    data.bulletin.endTime.toLocaleString().split(':')[0] +
                    ':' +
                    data.bulletin.endTime.toLocaleString().split(':')[1];
            } else if (error) {
                console.log(error);
            }
        };
        if (Object.keys(paramsGuid).length) {
            fetchOnlineVideoDetails();
        }
    }, [paramsGuid]);

    // console.log('data', data);

    //#endregion

    return (
        <>
            <LoadingAnimate isLoading={isLoading} progress={progress} />

            <section id='section-main'>
                <form id='container-newsUpdateChange' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>新聞動態</h5>
                        <button
                            className='btnDelete btn btn-outline-danger px-4 ms-auto me-2'
                            onClick={(e) => {
                                handleDeleteItem(e);
                            }}
                            type='button'
                        >
                            刪除
                        </button>
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/newsupdate');
                            }}
                            type='button'
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4' type='submit'>
                            儲存
                        </button>
                    </div>
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='row'>
                            <div className='col-6'>
                                <h6 className='fontSize75Rem mb-2'>標題</h6>
                                <input
                                    id='title'
                                    name='title'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.bulletin?.title ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                title: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor='summary' className='fontSize75Rem mb-2'>
                                    摘要
                                </label>
                                <textarea
                                    id='summary'
                                    name='summary'
                                    className='form-control mb-3'
                                    rows='2'
                                    value={data.bulletin?.summary ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                summary: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor='content' className='fontSize75Rem mb-2'>
                                    內容
                                </label>
                                <textarea
                                    id='content'
                                    name='content'
                                    className='form-control mb-3'
                                    rows='20'
                                    value={data.bulletin?.content ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                content: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-6'>
                                <h6 className='fontSize75Rem mb-2'>摘要圖片</h6>
                                <div className='fileBox d-flex align-items-center mb-2 w-100'>
                                    <label
                                        htmlFor='coverImages'
                                        style={{
                                            minWidth: '94px',
                                            height: '32px',
                                        }}
                                    >
                                        <div className='btn btn-primary text-white px-2 py-1 fontSize875Rem'>
                                            <svg
                                                id='icn_Create'
                                                className='me-2 mb-1'
                                                viewBox='0 0 12 12'
                                                width='12'
                                                height='12'
                                                fill='#fff'
                                            >
                                                <defs> </defs>
                                                <path
                                                    className='cls-1'
                                                    id='_Color'
                                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                    data-name=' ↳Color'
                                                ></path>
                                            </svg>
                                            <span>上傳摘要圖片(1張)</span>
                                        </div>
                                        <input
                                            type='file'
                                            className='form-control d-none'
                                            id='coverImages'
                                            name='coverImages'
                                            onChange={
                                                (e) =>
                                                    checkAndSetFile(e.target, ['.jpg', '.png'], false) &&
                                                    handleCoverImage(e) //
                                            }
                                        />
                                    </label>
                                    {/* <span className="guideline ms-2 mb-0">
										建議採用 16:9 的長寬比且大小在 5 MB
										以內的圖片，並以 jpg,png
										等圖片格式上傳
									</span> */}
                                </div>
                            </div>
                        </div>
                        {data.bulletin.coverPath || data.bulletin.coverFile ? (
                            <div className='row mb-3'>
                                <div className='col-4'>
                                    <div className='imageSection'>
                                        <div className='imageCard position-relative d-flex justify-content-center align-items-center rounded-3'>
                                            <img
                                                src={
                                                    data.bulletin.coverFile
                                                        ? URL.createObjectURL(data.bulletin.coverFile)
                                                        : data.bulletin.coverPath
                                                }
                                                alt='image'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className='row'>
                            <div className='col-6'>
                                <h6 className='fontSize75Rem mb-2'>內文圖片</h6>
                                <div className='fileBox d-flex align-items-center mb-2 w-100'>
                                    <label
                                        htmlFor='images'
                                        style={{
                                            minWidth: '94px',
                                            height: '32px',
                                        }}
                                    >
                                        <div className='btn btn-primary text-white px-2 py-1 fontSize875Rem'>
                                            <svg
                                                id='icn_Create'
                                                className='me-2 mb-1'
                                                viewBox='0 0 12 12'
                                                width='12'
                                                height='12'
                                                fill='#fff'
                                            >
                                                <defs> </defs>
                                                <path
                                                    className='cls-1'
                                                    id='_Color'
                                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                    data-name=' ↳Color'
                                                ></path>
                                            </svg>
                                            <span>上傳圖片</span>
                                        </div>
                                        <input
                                            type='file'
                                            className='form-control d-none'
                                            id='images'
                                            name='images'
                                            multiple
                                            onChange={
                                                (e) =>
                                                    checkAndSetFile(e.target, ['.jpg', '.png'], false) &&
                                                    handleSetAppendix(e, '0') // 圖片category '0'
                                            }
                                        />
                                    </label>
                                    {/* <span className="guideline ms-2 mb-0">
										建議採用 16:9 的長寬比且大小在 5 MB
										以內的圖片，並以 jpg,png
										等圖片格式上傳
									</span> */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {data.bulletinAppendixList
                                .filter((el) => el.category === '0' && el.action !== 'delete') //只顯示附件
                                ?.map((item) => (
                                    <div key={item.appendixGuid} className='col-4'>
                                        <div className='imageSection'>
                                            <div className='imageCard position-relative d-flex justify-content-center align-items-center rounded-3'>
                                                <img
                                                    src={item.appendixURL || URL.createObjectURL(item.appendixFile)}
                                                    alt='image'
                                                />

                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: '8px',
                                                        right: '8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleDeleteAppendix(item.appendixGuid)}
                                                >
                                                    <svg
                                                        id='Group_6556'
                                                        dataname='Group 6556'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                                        width='16'
                                                        height='16'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <defs>
                                                            <clipPath id='clip-path-times1811DDDDDDD'>
                                                                <rect
                                                                    id='Rectangle_3429'
                                                                    dataname='Rectangle 3429'
                                                                    width='16'
                                                                    height='16'
                                                                    fill='#ea4132'
                                                                ></rect>
                                                            </clipPath>
                                                        </defs>
                                                        <g
                                                            id='Group_6556-2'
                                                            dataname='Group 6556'
                                                            clipPath='url(#clip-pathtimes1811DDDDDDD)'
                                                        >
                                                            <path
                                                                id='Path_1484'
                                                                dataname='Path 1484'
                                                                d='M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226'
                                                                transform='translate(1.215 1.216)'
                                                                fill='#fff'
                                                            ></path>
                                                            <path
                                                                id='Path_1485'
                                                                dataname='Path 1485'
                                                                d='M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z'
                                                                transform='translate(0.001 0.001)'
                                                                fill='#ea4132'
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className='bg-grey3 px-2 py-2 w-100 rounded-3 mt-2 mb-3'>
                                                <p className='fontSize75Rem text-center mb-0'>
                                                    {`${item.appendixName || '[檔案名稱]'}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        <h6 className='fontSize75Rem mb-2'>附件</h6>
                        <label htmlFor='appendix' className='mb-3'>
                            <div
                                className={`btn btn-primary text-white px-2 py-1 fontSize875Rem`}
                                style={{
                                    width: '94px',
                                    cursor: 'pointer',
                                }}
                            >
                                <svg
                                    id='icn_Create'
                                    className='me-2 mb-1'
                                    viewBox='0 0 12 12'
                                    width='12'
                                    height='12'
                                    fill='#fff'
                                >
                                    <defs> </defs>
                                    <path
                                        className='cls-1'
                                        id='_Color'
                                        d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                        data-name=' ↳Color'
                                    ></path>
                                </svg>
                                <span>上傳檔案</span>
                            </div>
                            <input
                                type='file'
                                className='form-control d-none'
                                id='appendix'
                                name='appendix'
                                multiple
                                onChange={(e) => {
                                    checkAndSetFile(
                                        e.target,
                                        ['.png', '.jpg', '.pdf', '.docx', '.xlsx', '.pptx'],
                                        false
                                    ) && handleSetAppendix(e, '1'); // 檔案 category '1'
                                }}
                            />
                        </label>

                        {data.bulletinAppendixList
                            .filter((el) => el.category === '1' && el.action !== 'delete') //只顯示附件
                            ?.map((item, index) => (
                                <div key={'appendixName' + index} className='d-flex align-items-center mb-3'>
                                    <label className='me-2' htmlFor='appendixName'>
                                        {index + 1}.
                                    </label>
                                    <button
                                        className='btn btn-warning me-2'
                                        onClick={(e) => {
                                            e.preventDefault();

                                            item.appendixURL
                                                ? window.open('/' + item.appendixURL) // 已上傳的檔案
                                                : window.open(URL.createObjectURL(item.appendixFile)); // 新上傳的檔案
                                        }}
                                    >
                                        檢視
                                    </button>
                                    <input
                                        type='text'
                                        id={'appendixName' + index}
                                        name='appendixName'
                                        className='form-control w-50 me-1'
                                        required=''
                                        value={item.appendixName}
                                        onChange={(e) => {
                                            const newList = [...data.bulletinAppendixList];

                                            newList.forEach((el) => {
                                                if (el.appendixGuid === item.appendixGuid) {
                                                    el.appendixName = e.target.value;

                                                    if (el.action === 'insert') {
                                                        el.action = 'insert';
                                                    } else {
                                                        el.action = 'update';
                                                    }
                                                }
                                            });

                                            setData({
                                                ...data,
                                                bulletinAppendixList: newList,
                                            });
                                        }}
                                    />

                                    {/* deleteBtn */}
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            height: '32px',
                                            width: '32px',
                                            textAlign: 'center',
                                        }}
                                        onClick={() => handleDeleteAppendix(item.appendixGuid)}
                                    >
                                        <Icn_Cancel
                                            style={{
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        {/* <div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">檔案資訊</h6>
						</div> */}
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='category' className='fontSize75Rem mb-2'>
                                    型態
                                </label>
                                <select
                                    className='form-control mb-3 '
                                    name='category'
                                    id='category'
                                    value={data.bulletin?.category ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                category: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>"0"：最新消息</option>
                                    <option value='1'>"1"：產品發布</option>
                                    <option value='2'>"2"：展會活動</option>
                                </select>
                                <label htmlFor='releaseTime' className='fontSize75Rem mb-2'>
                                    發佈時間
                                </label>
                                <input
                                    type='datetime-local'
                                    id='releaseTime'
                                    name='releaseTime'
                                    className='form-control'
                                    value={data.bulletin?.releaseTime ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                releaseTime: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='languageCode' className='fontSize75Rem mb-2'>
                                    語言代碼
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='languageCode'
                                    id='languageCode'
                                    value={data.bulletin?.languageCode ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                languageCode: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='ZF'>"ZF"：繁體中文</option>
                                    <option value='ZH'>"ZH"：簡體中文</option>
                                    <option value='EN'>"EN"：英文</option>
                                </select>
                                <label htmlFor='endTime' className='fontSize75Rem mb-2'>
                                    結束時間
                                </label>
                                <input
                                    type='datetime-local'
                                    id='endTime'
                                    name='endTime'
                                    className='form-control'
                                    value={data.bulletin?.endTime ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                endTime: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='displayOrder' className='fontSize75Rem mb-2'>
                                    顯示順序
                                </label>
                                <input
                                    type='number'
                                    id='displayOrder'
                                    name='displayOrder'
                                    max={10000}
                                    className='form-control mb-3'
                                    value={data.bulletin?.displayOrder ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                displayOrder: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                />
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='status'
                                    id='status'
                                    value={data.bulletin?.status ?? ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bulletin: {
                                                ...data.bulletin,
                                                status: e.target.value,
                                            },
                                        })
                                    }
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>0：停用</option>
                                    <option value='1'>1：啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default NewsUpdateChange;
