import './privacy.scss';
import React from 'react';

/// 隱私政策
/// Author : YL
function Privacy() {
    return (
        <div className='container-fluid p-0'>
            <article id='container-privacy' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <div className='outerWrapper'>
                    <div className='wrapper'>
                        <div className='col-12 desc px-3'>
                            <div className='text-grey8 ps-0 mb-4 mb-lg-5 row justify-content-center'>
                                <main className=''>
                                    <h1>賓樂達運動隱私政策</h1>
                                    <section className='section0'>
                                        <div className='subSection subSession1'>
                                            <div className='wordWrap'>
                                                <p>
                                                    翔睿德股份有限公司（簡稱“翔睿德”或“我們”，地址：“台北市士林區中山北路六段七號三樓”）非常重視用戶您的隱私，在此特別提醒您（簡稱“用戶”或“您”）認真閱讀並充分理解本隱私權政策（簡稱“本政策”）。
                                                </p>
                                                <p>
                                                    本政策解釋翔睿德如何收集、使用和披露關於您或與您相關聯的資訊（簡稱“個人資訊”）。本政策適用於我們的網站和服務（簡稱“我們的服務”或“本服務”），包括但不限於www.synerter.com
                                                    和 www.synerter.com.tw
                                                    （簡稱“我們的網站”或“本網站”）、您使用的賓樂達智慧設備（簡稱“本設備”）、及為本設備提供支援的線上應用程式（簡稱“應用程式”）等各項服務內容。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section1'>
                                        <h2>1. 我們收集哪些資訊：</h2>
                                        <div className='subSection subSection0'>
                                            <p>
                                                為了向您提供我們的服務，我們需要您提供使用該服務所必需的資訊。我們只會收集為實現具體、特定、明確及合法的目的所必需的資訊，並且確保不會對這些資訊進行與上述目的不相符的進一步處理。您有權自行選擇是否提供我們請求的資訊，但多數情況下，如果您拒絕，我們可能無法向您提供相應的服務，也無法回應您遇到的問題。
                                            </p>
                                            <p>根據您選擇的服務，我們可能收集以下資訊中的一種或多種：</p>
                                        </div>

                                        <div className='subSection subSection1'>
                                            <p className='subtitle'>
                                                1.1
                                                您主動提供給我們的個人資訊。我們會根據您選擇的服務，收集您在使用相應服務時需要提供的個人資訊。例如：
                                            </p>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.1.1
                                                    若您使用雲同步服務，您可能會上傳雲同步的相關資料；若您創建帳號，您可能會提供您的生日、性別、帳號安全設置等相關資訊；
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.1.2
                                                    若您參與市場促銷活動，您可能會向我們提供您的暱稱、電子郵寄地址、照片、視頻或其他所需的資訊；
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.1.3
                                                    若您參與我們的活動或獲獎，您可能會向我們提供您的姓名、手機號碼和地址。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection2'>
                                            <p className='subtitle'>1.2 我們在您使用服務過程中收集的資訊。例如：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.2.1
                                                    您在使用本服務時生成的資訊：例如使用者等級、簽到資訊、流覽記錄、收藏、分享、搜索關鍵字、系統語言、國家和地區、網路狀態、應用清單、地區、互聯網協定
                                                    (IP) 位址等。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.2.2
                                                    位置資訊（僅適用於特定服務/功能）：若您使用和位置相關的服務，我們可能採集與您設備的模糊或精確位置相關的各類資訊。例如地區、國家代碼、城市代碼、移動網路代碼、移動國家代碼、社區標識、經緯度資訊、時區設置和語言設置。您可以隨時在手機設置（設置-許可權）中關閉每個應用的位置服務。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.2.3 其他資訊：
                                                    環境特徵值(ECV)，即從使用者帳號、設備標識、連接Wi-Fi時產生的資訊和地理位置資訊。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection3'>
                                            <p className='subtitle'>
                                                1.3
                                                來源於協力廠商的資訊。在一些法律允許的情況下，我們可能從協力廠商處獲得您的個人資訊。例如：
                                            </p>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.3.1
                                                    出於安全和防欺詐的目的，針對特定帳號與服務，在您授權的前提下，通過合法來源核實您的資訊（如電話號碼）。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.3.2
                                                    我們還可能從協力廠商社交網路服務中獲取某些資訊，例如帳戶、暱稱、頭像及郵箱（例如，當您使用社交網路帳戶登錄本服務時）。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.3.3
                                                    他人提供的與您有關的資訊，例如其他使用者在使用電子商場為您購買產品時，向我們提供的您的收貨地址。
                                                </p>
                                            </div>
                                        </div>
                                        <div className='subSection subSection4'>
                                            <p className='subtitle'>
                                                1.4
                                                非個人資訊。我們還可能收集其他無法直接或間接地識別到特定個人、且根據當地適用法律不屬於個人資訊的資訊。這類資訊被稱為非個人資訊。我們可能收集、使用、轉移和披露非個人資訊。例如：
                                            </p>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.4.1
                                                    我們可能包含您使用特定服務時產生的統計類資料（如匿名設備相關資訊、日活事件、頁面訪問事件、頁面訪問時長事件、會話事件）。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>1.4.2 網路監控資料如請求時長、請求與錯誤請求數等。</p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>1.4.3 應用崩潰事件（如應用程式崩潰後自動生成的日誌等）。</p>
                                            </div>
                                        </div>
                                        <p>
                                            收集此類資訊的目的在於改善我們向您提供的服務。我們收集資訊的類別和數量將取決於您如何使用我們的產品或服務。
                                        </p>
                                    </section>

                                    <section className='section2'>
                                        <h2>2. 我們收集的個人資訊將如何被使用。</h2>
                                        <div className='subSection subSection1'>
                                            <p>
                                                我們收集個人資訊的目的在於向您提供產品和/或服務，並且保證我們遵守適用的相關法律、法規及其他規範性檔。這包括：
                                            </p>
                                            <p className='subtitle'>
                                                2.1
                                                提供、處理、維護、改善、開發我們的產品或提供給您的服務，例如交付、啟動、驗證、售後、客戶支援和廣告宣傳。
                                            </p>

                                            <p className='subtitle'>
                                                2.2 用於防丟失和防欺詐等目的的安全保障，協助識別用戶、驗證身份等。
                                            </p>
                                            <p className='subtitle'>
                                                2.3 處理您關於設備與服務的提問或請求，
                                                例如解答客戶詢問、發送系統及應用程式的消息通知。
                                            </p>
                                            <p className='subtitle'>
                                                2.4 進行相關的推廣活動，例如提供推廣與促銷的資料和更新。
                                                除非適用的法律另有規定，如果您不再希望接收某些類別的推廣資訊，您可以通過該資訊中提供的退出方式來拒絕此類服務。
                                            </p>
                                            <p className='subtitle'>
                                                2.5
                                                內部目的，如數據分析、研究和開發與我們產品或服務的使用相關的統計資訊，以更好地改進我們的產品或服務。
                                            </p>
                                            <p className='subtitle'>
                                                2.6 優化設備的性能，例如分析應用程式的記憶體使用情況或應用的CPU利用率。
                                            </p>
                                            <p className='subtitle'>
                                                2.7
                                                其他徵得您同意的目的關於我們如何使用您的資訊（其中可能包含個人資訊），下面提供了更多詳細示例：
                                            </p>
                                            <div className='wordWrap'>
                                                <p>2.7.1 啟動、註冊您購買的產品或服務。</p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.2
                                                    創建及維護您的帳號：您通過網站或移動設備創建使用者帳號時提供的個人資訊，可用於建立您個人帳號和資料頁。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.3 處理您的訂購單：
                                                    與電子商務訂單相關的資訊可用於處理訂購單和相關的售後服務，包括客戶支援和重新發貨。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.4
                                                    收集用戶回饋：您選擇提供的回饋對説明我們改進服務非常重要。為了及時處理您提供的回饋，我們可能會使用您所提供的個人資訊與您聯繫，並保留記錄用於解決問題及改進服務。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.5
                                                    發送通知：我們可能會將您的個人資訊用於發送重要通知，例如有關訂單的通知和我們的條款、條件和政策的變更。由於此類資訊對您和我們之間的溝通至關重要，不建議您拒絕接收此類資訊。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.6
                                                    進行促銷活動：如果您通過我們的社交媒體平臺參與了抽獎、比賽或類似的促銷活動，我們可能會使用您提供的個人資訊以為您發送此類獎勵。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.7.7
                                                    提供個性化使用者服務和內容：為了保護您的隱私，我們會使用一個唯一識別碼而不是您的姓名、電子郵件或其他可以直接識別您的資訊，以為您提供個性化的產品、服務和活動，包括廣告。
                                                </p>
                                            </div>
                                        </div>
                                        <p>
                                            我們可能將這些資訊與其他資訊結合起來（包括跨不同服務或設備如電腦、手機、智慧電視和其他聯網設備中的資訊），用於提供和改進我們的產品、服務、內容和廣告宣傳。
                                        </p>
                                    </section>

                                    <section className='section3'>
                                        <h2>3. 我們如何使用 Cookie 和同類技術</h2>
                                        <div className='subSection subSection1'>
                                            <p>
                                                我們和協力廠商服務提供者及業務合作夥伴使用Cookie、標籤和腳本等技術。這些技術用於分析趨勢、管理網站、追蹤您的網站活動並收集關於整個使用者群體的統計資訊。我們會收到通過以上技術收集的個體或匯總的分析報告。這些技術幫助我們更好地瞭解您的行為，使我們瞭解您流覽我們網站的哪些部分，衡量廣告和網路搜索的效果並加以改善。
                                            </p>
                                            <p className='subtitle'>
                                                3.1
                                                日誌檔：和大部分網站一樣，我們收集特定資訊並保存在日誌檔中。此類資訊可能包括互聯網協定
                                                (IP) 地址、流覽器類型、互聯網服務供應商
                                                (ISP)、引用/退出頁面、作業系統、日期/時間戳記和/或點選流向資料。我們不會把自動收集的日誌資料與我們收集的其他資訊進行關聯。
                                            </p>
                                            <p className='subtitle'>
                                                3.2 本機存放區：我們使用本機存放區物件
                                                (LSO)，例如HTML來存儲內容和偏好，在我們的網站上提供某些功能，或根據您的網頁流覽行為投放廣告的協力廠商也會通過HTML來收集和存儲相關資訊。各種流覽器提供了各自的管理工具來刪除HTML本機存放區物件。
                                            </p>
                                            <p className='subtitle'>
                                                3.3
                                                廣告cookie：我們與協力廠商服務提供者及業務合作夥伴合作，在我們的網站上展示廣告或管理我們在其他網站上的廣告。我們的協力廠商服務提供者和業務合作夥伴可能使用廣告類cookie來收集您的線上活動和個人興趣資訊，用於向您提供與您的用戶畫像和興趣愛好高度相關的廣告。我們會在向您提供廣告服務之前，獲取您明確的事前同意。如果您不希望該資訊被用於向您投放針對您興趣的廣告，您可以通過管理您的Cookie
                                                設置來選擇退出。
                                            </p>
                                            <p className='subtitle'>
                                                3.4
                                                移動分析：在某些移動應用中，我們使用分析類cookie來收集有關訪問者如何使用我們網站的資訊。這類cookie收集的資訊包括您使用該應用程式的頻率、該應用程式內發生的事件、累計使用、性能資料以及應用程式發生崩潰的情況。我們不會將存儲於分析軟體內的資訊與您在移動應用程式中提交的任何個人資訊相關聯。
                                            </p>
                                        </div>
                                    </section>

                                    <section className='section4'>
                                        <h2>4. 我們如何共用、轉讓、公開披露您的個人資訊</h2>
                                        <div className='subSection subSection1'>
                                            <p className='subtitle'>
                                                4.1
                                                共用：我們不會將任何個人資訊出售給協力廠商。我們有時可能會向協力廠商共用您的個人資訊，以便提供或改進我們的產品或服務，包括根據您的要求提供產品或服務。下面提供了有關資料共用的具體資訊。
                                            </p>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.1.1
                                                    您主動選擇的共用：在獲得您的明確同意或主動選擇的情況下，與您指定的協力廠商共用您授權/請求範圍內的資訊。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.1.2
                                                    與我們集團共用資訊：為了順利地從事商業經營，以向您提供產品或服務的全部功能，我們可能不時與其他的關聯公司共用您的個人資訊。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.1.3
                                                    與協力廠商服務提供者與業務合作夥伴共用：為保證向您提供本隱私政策所述的產品和服務，我們可能會在必要時與我們的協力廠商服務提供者和業務合作夥伴共用您的個人資訊。這包括我們的物流供應商、資料中心、資料存儲設施、客戶服務供應商、廣告和行銷服務供應商以及其他業務合作夥伴。這些協力廠商可能代表我們或出於本隱私政策的一項或多專案的處理您的個人資訊。我們保證僅出於正當、合法、必要、特定、明確的目的共用為您提供服務所必要的個人資訊。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.1.4
                                                    其他：根據法律法規規定、法律程式、訴訟和/或公共機構和政府部門依法提出的要求，我們可能有必要披露您的個人資訊。如果就國家安全、執法或具有公眾重要性的其他事宜而言，披露是必須的或適當的，我們也可能會披露關於您的資訊。例如，我們可能與下列人員共用您的個人資訊。
                                                </p>
                                                <p>
                                                    (1) 我們的會計師、律師或類似的顧問，當我們要求他們提供專業建議時。
                                                </p>
                                                <p>
                                                    (2)
                                                    投資者以及其他相關協力廠商，如果發生實際或潛在的出售或其他公司交易，且與我們集團內實體相關時。
                                                </p>
                                                <p>
                                                    (3)
                                                    本隱私政策中詳述的其他協力廠商或以其他方式通知您的協力廠商，如經您授權就特定資訊進行披露。
                                                </p>
                                            </div>
                                        </div>
                                        <div className='subSection subSection2'>
                                            <p className='subtitle'>
                                                4.2 轉讓：除以下情形外，我們不會將您的資訊轉讓給任何主體：
                                            </p>
                                            <p>(1) 獲得您的明確同意。</p>
                                            <p>
                                                (2)
                                                如果我們參與合併、收購或出售其全部或部分資產，可能會影響到您的個人資訊，我們將通過電子郵件和/或在我們網站上或其他適當方式發佈醒目通知，告知對您個人資訊的所有權、使用權方面的任何變化，以及您可能擁有的關於您個人資訊的任何選擇。
                                            </p>
                                            <p>(3) 在本隱私政策中已解釋的或以其他方式通知您的情況下。</p>
                                        </div>
                                        <div className='subSection subSection3'>
                                            <p className='subtitle'>
                                                4.3 公開披露：在以下情況下，我們可能會公開披露您的個人資訊：
                                            </p>
                                            <p>
                                                (1)
                                                當我們需要公佈促銷、比賽或抽獎活動的獲勝者時，我們只會公佈有限的資訊。
                                            </p>
                                            <p>
                                                (2)
                                                當我們已獲得您的明確同意，或您通過我們的服務如社交媒體頁面或公共論壇披露了資訊。
                                            </p>
                                            <p>
                                                (3)
                                                基於法律或合理依據的公開披露：包括法律法規規範、法律程式、訴訟或應政府主管部門要求。
                                            </p>
                                        </div>
                                    </section>

                                    <section className='section5'>
                                        <h2>5. 我們如何保存和保護您的個人資訊</h2>
                                        <div className='subSection subSection1'>
                                            <p className='subtitle'>5.1 我們的安全措施</p>
                                            <p>
                                                我們致力於保護您的個人資訊的安全。為了防止未經授權的訪問、披露或其他類似風險，我們落實了所有法律規定的物理、電子和管理措施流程，以保障我們從您的移動設備和我們網站上收集的資訊的安全。我們將確保依據適用的法律保護您的個人資訊。
                                            </p>
                                            <p className='subtitle'>5.2 您能做什麼？</p>
                                            <p>
                                                您可以通過不向任何人（除非此人經您正式授權）披露您的登錄密碼或帳號資訊，您可以為我們設置唯一的密碼，以防止其他網站密碼洩露危害您在我們的帳號安全。無論何時，請不要向任何人（包括自稱是我們客服的人士）透露您收到的驗證碼。無論您以我們帳號用戶的身份登錄我們網站，尤其是在他人的電腦或公共互聯網終端上登錄時，您都應當在會話結束後登出帳號。
                                            </p>
                                            <p>
                                                我們不對因您未能保持個人資訊的私密性而導致協力廠商訪問您的個人資訊進而造成的安全疏漏承擔責任。儘管有上述規定，如果發生其他任何互聯網用戶未經授權使用您帳號的情況或其他任何安全性漏洞，您應當立即通知我們。您的協助將有助於我們保護您個人資訊的私密性。
                                            </p>
                                            <p className='subtitle'>5.3 訪問您的設備上的其他功能</p>
                                            <p>
                                                我們的應用程式可能會訪問您設備上的某些功能，例如使電子郵件啟動連絡人、短信存儲和Wi-Fi網路狀態等功能。這些資訊用於允許這些應用程式在您的設備上運行，並且允許您與其互動。
                                            </p>
                                            <p className='subtitle'>5.4 保留政策</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    5.4.1
                                                    我們基於本隱私政策以及特定產品或服務的單獨隱私政策中所述的資訊收集的目的所必需的期間，或者遵守適用法律的要求保留個人資訊。具體保留時長可參見特定服務或相關產品頁面。個人資訊在完成收集目的，或在我們確認過您的刪除或註銷申請後，或我們終止運營相應產品或服務後，我們將停止保留，並做刪除或匿名化處理。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    5.4.2
                                                    根據您的司法管轄區要求，對於我們出於公共利益、科學、歷史研究或統計目的而處理的個人資訊可能會有例外情況。基於適用法律或您的要求，在必要和允許的情況下，即使進一步的資料處理與最初的收集目的無關，我們可能會繼續保留此類資訊超過其標準保留期。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section6'>
                                        <h2>6. 您的權利</h2>
                                        <p>您可以控制您的個人資訊。</p>
                                        <div className='subSection subSection1'>
                                            <p className='subtitle'>6.1 控制設置</p>
                                            <p>
                                                我們意識到每個人對隱私權的關注各不相同。因此，我們提供了一些示例，說明我們提供的各種方式，供您選擇，以限制收集、使用、披露或處理您的個人資訊，並控制您的隱私權設置：
                                            </p>
                                            <p>(1) 打開或者關閉用戶體驗計畫和位置訪問功能。</p>
                                            <p>(2) 登入或登出我們帳號。</p>
                                            <p>(3) 打開或關閉我們雲同步功能。</p>
                                            <p>(4) 刪除存儲在我們雲中的任何資訊。</p>
                                            <p>(5) 打開或關閉其他處理敏感資訊或個人資訊的服務和功能。</p>
                                            <p>
                                                如果您之前因為上述目的同意我們使用您的個人資訊，您可以通過訪問我們的網站來改變您的決定。
                                            </p>
                                        </div>
                                        <div className='subSection subSection2'>
                                            <p className='subtitle'>6.2 您對您的個人資訊享有的權利</p>
                                            <p>
                                                根據您所適用的國家或地區法律法規，您有權要求訪問、更正、刪除我們持有的與您相關的任何個人資訊（以下簡稱請求）。這些權利將受適用法律規定的特定排除和例外情況的限制。
                                                如您滿足以下條件，我們將有效地處理您的請求：
                                            </p>
                                            <p>
                                                (1)
                                                請求是通過我們專有的請求管道提交的，並為保護您的資訊安全，您的請求應以書面形式提出。
                                            </p>
                                            <p>
                                                (2)
                                                提供足夠的資訊使我們可以驗證您的身份，確保請求人是所請求資訊主體本人或合法授權人。
                                            </p>
                                            <p>
                                                一旦我們獲得充分資訊確認可處理您的請求時，我們將在適用資料保護法律規定的時間內對您的請求做出回應。
                                                我們有權在適用法律規定的豁免情況，或有其他因適用法律賦予我們的相應權利的情況下拒絕處理或僅回應部分您的請求，例如某些請求明顯沒有根據、明顯過度，或需要披露協力廠商的資訊的請求。某些情況下，我們可能會在適用法律允許時收取相應費用。如果我們認為刪除資訊的請求在某些方面可能導致我們無法合法地將該資訊用於確立、行使或辯護適用法律允許的法律主張和理由，該請求也有可能被拒絕。
                                            </p>
                                        </div>
                                        <div className='subSection subSection3'>
                                            <p className='subtitle'>6.3 撤銷同意</p>
                                            <p>
                                                您可以通過提交請求撤銷之前出於特定目的授予我們的同意，包括收集、使用和/或披露我們掌握或控制的您的個人資訊。根據您所使用的具體服務，您可以通過訪問我們的網站來進行相關操作。我們將會在您做出請求後的合理時間內處理您的請求，並且會根據您的請求，此後不再收集、使用和/或披露您的個人資訊。
                                            </p>
                                            <p>
                                                取決於您撤銷同意的範圍，可能導致您不能繼續享受我們的產品或服務。但您撤回同意或授權的決定，不會影響我們此前基於您的同意而開展的個人資訊處理活動的有效性。
                                            </p>
                                        </div>
                                        <div className='subSection subSection4'>
                                            <p className='subtitle'>6.4 登出服務或帳號</p>
                                            <p>如您希望登出特定的產品或服務，您可以通過訪問我們的網站來聯繫我們。</p>
                                            <p>
                                                如您希望註銷帳號，由於註銷帳號的操作將使您無法使用我們全線產品或服務，請您謹慎操作。在某些特定情況下，取消可能會被阻止或延遲。
                                            </p>
                                            <p>
                                                當您通過協力廠商帳號授權登錄我們的網站時，需要向協力廠商申請登出帳號。
                                            </p>
                                        </div>
                                    </section>

                                    <section className='section7'>
                                        <h2>7. 未成年人資訊的保護</h2>
                                        <p>
                                            我們認為監督孩子使用我們的產品或服務是家長或監護人的責任。但是，我們不直接向兒童提供服務，也不將兒童的個人資訊用於行銷目的。
                                        </p>
                                        <p>
                                            如果您是家長或監護人，並且您認為未成年人向我們提交了個人資訊，可以通過訪問我們的網站來聯繫我們，以確保此類個人資訊被立即刪除，並取消未成年人對任何適用的我們服務的訂閱。
                                        </p>
                                    </section>

                                    <section className='section8'>
                                        <h2>8. 協力廠商網站和服務</h2>
                                        <p>
                                            本政策不適用於協力廠商提供的產品或服務。取決於您所使用的我們產品或服務，其中可能包括協力廠商的產品或服務，涉及語音支援、相機處理、視頻播放、系統清理及安全相關服務、遊戲、統計、社交、分享、推送、資訊過濾、輸入法等類型，
                                            其中一些會以協力廠商網站的連結形式提供，還有一些會以SDK、API等形式接入。當您使用這些產品或服務時，也可能收集您的資訊。因此，我們強烈建議您花時間閱讀該協力廠商的隱私政策，就像閱讀本政策一樣。
                                            我們不對協力廠商如何使用他們向您收集的個人資訊負責，也不能控制其使用。本政策也不適用於通過我們的服務連結的其他網站。
                                        </p>
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Privacy;
