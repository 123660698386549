import './productFeature.scss';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SupportBottom from '../../support/SupportBottom';
import { useTranslation } from 'react-i18next';

function ProductFeature() {
    const { t } = useTranslation('productfeature'); // i18n
    const [sectionSlider, setSectionSlider] = useState(1);
    const [section14Slider, setSection14Slider] = useState(1);

    return (
        <div className='container-fluid p-0'>
            <article
                id='container-productFeature'
                className='d-flex flex-column align-items-center px-0 mx-auto bg-black'
            >
                <section className='productFeature-section productCenterNavPhone section0 position-fixed w-100 row justify-content-center bg-white'>
                    <div className='col-12 section0Wrapper p-0 text-center'>
                        <div className='h-100 d-flex justify-content-between align-items-center px-4'>
                            <h6 className='text-grey8 mb-0'>{t('pingpong_robot')}</h6>
                            <Dropdown>
                                <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
                                    {t('feature_highlights')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link className='text-primary' to='/productcenter/productfeature'>
                                            {t('feature_highlights')}
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link className='text-grey8' to='/productcenter/productstat'>
                                            {t('specifications')}
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link className='text-grey8' to='/app/download'>
                                            {t('app_guide')}
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section1 w-100 row align-items-top'>
                    <div className='offset-lg-3 col p-0 text-center text-lg-start'>
                        <h1 className='text-white mb-4 mb-lg-7'>
                            <span className='mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>
                                {t('productfeature:professional_serve')}
                            </span>
                        </h1>
                        {/* <Link to='/support/contactus'> */}
                        <Link to='/onlineStore'>
                            <button type='button' className='btn btn-size-m btn-hover-white  rounded-pill mb-9 mb-lg-0'>
                                {t('productfeature:buy_now')}
                            </button>
                        </Link>
                    </div>
                </section>
                <section className='productFeature-section section2 w-100 row mt-lg-13'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <p className='px-6 mx-auto mb-lg-7 text-white'>{t('product_description')}</p>
                        <p className='px-6 mx-auto mb-lg-13 text-white'>{t('productfeature:best_companion')}</p>
                        <img
                            className='d-block d-lg-none section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                            src='./images/productCenter/productFeature/section2_1_2x.png'
                            alt='section2Img'
                        />
                        <h1 className='text-primary mt-12 mt-lg-4 mb-5 mb-lg-12'>
                            <span className='mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>
                                {t('productfeature:outstanding_appearance')}
                            </span>
                            <span className='d-block d-lg-inline'>{t('productfeature:impressive_performance')}</span>
                        </h1>
                        <p className='px-6 mx-auto mb-7 mb-lg-11 text-white'>{t('modern_design')}</p>
                    </div>
                    <div className='col-12 p-0 text-center d-none d-lg-block'>
                        <picture>
                            <source
                                className='section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                                media='(min-width: 992px)'
                                srcSet='./images/productCenter/productFeature/section2_1_2x.png'
                                alt='section2Img'
                            />
                            <img
                                className='section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                                src='./images/productCenter/productFeature/section2_1_1x.png'
                                alt='section2Img'
                            />
                        </picture>
                    </div>
                </section>
                <section className='productFeature-section section3 w-100 row mt-lg-13 mb-lg-14'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <img
                            className='d-block d-lg-none section3_1Img w-100 mx-0 ms-lg-auto mt-0'
                            src='./images/productCenter/productFeature/section3_1_1x.png'
                            alt='section3_1Img'
                        />
                        <h2 className='px-6 mx-auto mt-6 mt-lg-0 mb-4 mb-lg-6 text-white'>
                            {t('productfeature:flexible_variation')}
                        </h2>
                        <p className='px-6 mx-auto mb-6 mb-lg-13 text-white'>{t('triple-axis_design')}</p>
                        <div className='textWrap d-flex justify-content-between mx-auto px-8 px-lg-0'>
                            <div>
                                <h2 className='mb-1 text-primary'>-40~40{t('productfeature:degrees')}</h2>
                                <h6 className='text-white'>{t('productfeature:tilt_angle')}</h6>
                            </div>
                            <div className='me-lg-4'>
                                <h2 className='mb-1 text-primary'>16{t('productfeature:types')}</h2>
                                <h6 className='text-white'>{t('productfeature:spin_types')}</h6>
                            </div>
                            <div className='me-lg-4'>
                                <h2 className='mb-1 text-primary'>-30~30{t('productfeature:degrees')}</h2>
                                <h6 className='text-white'>{t('productfeature:horizontal_angle')}</h6>
                            </div>
                        </div>
                        <img
                            className='d-block d-lg-none section3_2Img w-100 mx-0 ms-lg-auto mt-0'
                            src='./images/productCenter/productFeature/section3_2_1x.png'
                            alt='section3_2Img'
                        />
                    </div>
                </section>
                <section className='productFeature-section section4 w-100 row align-items-top'>
                    <div className='col-12 p-0 text-center'>
                        <h2 className='text-white mt-6 mb-4'>{t('productfeature:versatile_portability')}</h2>
                        <p className='px-6 mx-auto mb-9 mb-lg-0 text-white'>{t('adjustable_support_stand')}</p>
                        <Link to='/explore'>
                            <button
                                type='button'
                                className='btn btn-outline-primary text-white rounded-pill py-2 mt-lg-8'
                            >
                                {t('productfeature:appreciation_journey')}
                            </button>
                        </Link>
                    </div>
                </section>

                <section className='productFeature-section section5 w-100 row mt-lg-13'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h1 className='text-primary mb-4 mb-lg-12'>
                            <span className='mb-2 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>
                                {t('productfeature:smooth_experience')}
                            </span>
                            <span className='d-block d-lg-inline'>{t('productfeature:realistic_training')}</span>
                        </h1>
                        <p className='px-6 mx-auto mb-lg-7 text-white'>{t('fully_functional_mainboard')}</p>
                        <p className='px-6 mx-auto mb-lg-15 text-white'>{t('unleash_potential')}</p>
                    </div>
                    <div className='col-12 p-0 text-center'>
                        <picture>
                            <source
                                className='section5Img w-100 mx-0 ms-lg-auto mt-9 mt-lg-0'
                                media='(min-width: 992px)'
                                srcSet='./images/productCenter/productFeature/section5_1_2x.png'
                                alt='section5Img'
                            />
                            <img
                                className='section5Img w-100 mx-0 ms-lg-auto'
                                src='./images/productCenter/productFeature/section5_1_1x.png'
                                alt='section5Img'
                            />
                        </picture>
                    </div>
                </section>
                <section className='productFeature-section section6 w-100 row mt-lg-7 mb-lg-15'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mt-4 mt-lg-0 mb-4 mb-lg-5 text-white'>
                            {t('productfeature:comprehensive_high_config')}
                        </h2>
                        <p className='px-6 mx-auto mb-6 mb-lg-12 text-white'>
                            {t('high_precision_brushless_DC_motor')}
                        </p>
                        <div className='textWrap d-flex justify-content-between mx-auto px-5 px-lg-0'>
                            <div>
                                <h2 className='mb-1 text-primary'>20000+{t('productfeature:hour')}</h2>
                                <h6 className='text-white'>{t('productfeature:longevity_stable_output')}</h6>
                            </div>
                            <div className=''>
                                <h2 className='mb-1 text-primary'>1000000+{t('productfeature:ball_count')}</h2>
                                <h6 className='text-white'>{t('productfeature:durable_serving_wheel')}</h6>
                            </div>
                            <div className='me-4'>
                                <h2 className='mb-1 text-primary'>60000+{t('productfeature:ball_count')}</h2>
                                <h6 className='text-white'>{t('productfeature:smooth_continuous_serving')}</h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section7 w-100 row align-items-top'>
                    <div className='col-12 position-relative p-0 text-center'>
                        <img
                            className='d-block d-lg-none section7Img w-100 mx-0 ms-lg-auto'
                            src='./images/productCenter/productFeature/section7_1_1x.png'
                            alt='section7Img'
                        />
                        <h2 className='text-white mt-7 mt-lg-0 mb-5 mb-lg-0'>
                            {t('productfeature:easy_adjustment')} {t('productfeature:easy_spin_practice')}
                        </h2>
                        <img
                            className='d-none d-lg-block section7Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                            src='./images/productCenter/productFeature/section7_1_2x.png'
                            alt='section7Img'
                        />
                        <div className='d-none d-lg-block section7InfoBox position-absolute'>
                            <div className='leftWrap position-absolute'>
                                <h3 className='title text-primary text-end mb-5'>{t('360_degree_full_rotation')}</h3>
                                <h6 className='text text-white text-start'>{t('double_wheel_drive')}</h6>
                            </div>
                            <div className='rightTopWrap position-absolute'>
                                <h6 className='text text-white text-start mb-4'>{t('spin_direction_indicator')}</h6>
                                <h3 className='title text-primary text-start'>
                                    {t('productfeature:spin_indicator_light')}{' '}
                                    {t('productfeature:intuitive_recognition')}
                                </h3>
                            </div>
                            <div className='rightBottomWrap position-absolute'>
                                <h6 className='text text-white text-start mb-4'>{t('built_in_hd_audio_assistant')}</h6>
                                <h3 className='title text-primary text-start'>{t('friendly_voice_prompt')}</h3>
                            </div>
                        </div>

                        {/* mobile */}
                        <div className='d-block d-lg-none section7mobileBox'>
                            <div className={`box1 ${sectionSlider === 1 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-primary text-center mb-2'>{t('360_degree_full_rotation')}</h3>
                                <h6 className='text text-white text-start px-7'>{t('double_wheel_drive')}</h6>
                            </div>
                            <div className={`box2 ${sectionSlider === 2 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-primary text-center mb-2'>{t('spin_light_indicator')}</h3>
                                <h6 className='text-white text-start px-7'>{t('spin_direction_indicator')}</h6>
                            </div>
                            <div className={`box3 ${sectionSlider === 3 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-primary text-center mb-2'>{t('friendly_voice_prompt')}</h3>
                                <h6 className='text-white text-start px-7'>{t('built_in_hd_audio_assistant')}</h6>
                            </div>

                            <div className='sliderBtnWrapper d-flex justify-content-between p-2 border border-grey4 rounded-pill mt-9 mx-auto'>
                                <div
                                    className={`btn-1 p-1 rounded-circle ${
                                        sectionSlider === 1 ? 'bg-white' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSectionSlider(1)}
                                ></div>
                                <div
                                    className={`btn-2 p-1 rounded-circle ${
                                        sectionSlider === 2 ? 'bg-white' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSectionSlider(2)}
                                ></div>
                                <div
                                    className={`btn-3 p-1 rounded-circle ${
                                        sectionSlider === 3 ? 'bg-white' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSectionSlider(3)}
                                ></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section8 w-100 row mt-lg-11 mb-lg-15'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <div className='col-12 p-0 text-center'>
                            <picture>
                                <source
                                    className='section8Img w-75 mx-0 ms-lg-auto mt-12 mt-lg-0'
                                    media='(min-width: 992px)'
                                    srcSet='./images/productCenter/productFeature/section8_1_2x.png'
                                    alt='section8Img'
                                />
                                <img
                                    className='section8Img w-75 mx-0 ms-lg-auto mt-2'
                                    src='./images/productCenter/productFeature/section8_1_1x.png'
                                    alt='section8Img'
                                />
                            </picture>
                        </div>
                        <h2 className='text-white text-center text-lg-start px-4 px-lg-6 mx-auto mb-4 mb-lg-3'>
                            {t('productfeature:brushless_motor')} {t('productfeature:efficient_smart_computing')}
                        </h2>
                        <p className='text-center text-lg-start px-6 mx-auto mb-6 mb-lg-12 text-white'>
                            {t('european_technology_real-time_system')}
                        </p>
                        <div className='textWrap d-flex justify-content-between mx-auto px-5 px-lg-0'>
                            <div>
                                <h2 className='mb-1 text-primary'>
                                    12000 {t('productfeature:revolutions_per_minute')}
                                </h2>
                                <h6 className='text-white'>{t('productfeature:motor_max_speed')}</h6>
                            </div>
                            <div className=''>
                                <h2 className='mb-1 text-primary'>120 {t('productfeature:balls_per_minute')}</h2>
                                <h6 className='text-white'>{t('productfeature:max_serving_frequency')}</h6>
                            </div>
                            <div className='me-4'>
                                <h2 className='mb-1 text-primary'>2~25 {t('productfeature:meters_per_second')}</h2>
                                <h6 className='text-white'>{t('productfeature:ball_speed')}</h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section9 w-100 row mt-lg-7 mb-lg-16'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-4 mb-lg-5 text-white'>{t('lightweight_yet_powerful')}</h2>
                        <p className='px-6 mx-auto text-white'>{t('compact_and_powerful')}</p>
                    </div>
                </section>
                <section className='productFeature-section section10 w-100 row align-items-top mb-lg-10'>
                    <div className='d-none d-lg-block col-12 position-relative p-0 text-center'>
                        <h2 className='text-white mb-5 mb-lg-12'>{t('advanced_technology_for_easy_control')}</h2>
                        <div className='section10CardBox row flex-wrap justify-content-lg-around mx-auto mb-lg-13'>
                            <div className='col6 cardLeftTop position-relative mb-4'>
                                <div className='cardInfo position-absolute'>
                                    <h6 className='text-white text-start mt-3 mb-5'>
                                        {t('triple_axis_synchronized_design')}
                                    </h6>
                                    <h6 className='text-white text-start mt-lg-5'>✓ {t('divided_into_16_angles')}</h6>
                                    <h6 className='text-white text-start'>✓ {t('tilt_up_to_80_degrees')}</h6>
                                    <h6 className='text-white text-start'>
                                        ✓ {t('horizontal_adjustment_up_to_60_degrees')}
                                    </h6>
                                    <h6 className='text-white text-start mb-4'>✓ {t('one_bounce_shot')}</h6>
                                    <h3 className='title text-primary text-start'>{t('multiple_spin_varieties')}</h3>
                                </div>
                            </div>
                            <div className='col6 cardRightTop position-relative mb-4'>
                                <div className='cardInfo position-absolute'>
                                    <h3 className='title text-primary text-end mb-5'>
                                        {t('automatic_cycle_for_smooth_feeding')}
                                    </h3>
                                    <h6 className='text text-white text-start mb-5'>
                                        {t('precision_calculated_ball_feeding_curve_and_angle')}
                                    </h6>
                                </div>
                            </div>
                            <div className='col6 cardLeftBottom position-relative'>
                                <div className='cardInfo position-absolute ms-9'>
                                    <h3 className='title text-primary text-start mb-5'>
                                        {t('smooth_ball_trajectory')}
                                    </h3>
                                    <h6 className='text text-white text-start mb-4'>
                                        {t('multiple_detection_mechanism')}
                                    </h6>
                                </div>
                            </div>
                            <div className='col6 cardRightBottom position-relative'>
                                <div className='cardInfo position-absolute'>
                                    <h3 className='title text-primary text-start mt-8 mb-5'>
                                        {t('one_button_ball_return')}
                                    </h3>
                                    <h6 className='text text-white text-start'>{t('one_button_auto_ball_return')}</h6>
                                </div>
                            </div>
                        </div>
                        <Link
                            to={{
                                pathname: '/support/downloadcenter',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <button type='button' className='btn btn-outline-primary text-white rounded-pill py-2'>
                                {t('user_guide')}
                            </button>
                        </Link>
                    </div>

                    {/* mobile */}
                    <div className='d-block d-lg-none col-12 p-0 text-center'>
                        <h2 className='text-white mb-5 mb-lg-12'>{t('advanced_technology')}</h2>
                        <img
                            className='section10LeftTop_1_1x w-100 mx-0 ms-lg-auto mt-2 mb-4'
                            src='./images/productCenter/productFeature/section10LeftTop_1_1x.png'
                            alt='section10LeftTop_1_1x'
                        />
                        <div className='cardInfo px-6'>
                            <h3 className='title text-primary text-start mb-2'>{t('multiple_spin_varieties')}</h3>

                            <h6 className='text-white text-start'>{t('triple_axis_synchronized_design')}</h6>
                            <h6 className='text-white text-start'>✓ {t('divided_into_16_angles')}</h6>
                            <h6 className='text-white text-start'>✓ {t('tilt_up_to_80_degrees')}</h6>
                            <h6 className='text-white text-start'>✓ {t('horizontal_adjustment_up_to_60_degrees')}</h6>
                            <h6 className='text-white text-start mb-6'>✓ {t('one_bounce_shot')}</h6>
                        </div>
                        <img
                            className='section10RightTop_1_1x w-100 mx-0 ms-lg-auto mt-2 mb-4'
                            src='./images/productCenter/productFeature/section10RightTop_1_1x.png'
                            alt='section10RightTop_1_1x'
                        />
                        <div className='cardInfo px-6'>
                            <h3 className='title text-primary text-start mb-2'>
                                {t('automatic_cycle_for_smooth_feeding')}
                            </h3>
                            <h6 className='text text-white text-start mb-6'>
                                {t('precision_calculated_ball_feeding_curve_and_angle')}
                            </h6>
                        </div>
                        <h2 className='text-white mt-14 mb-6'>{t('convenient_installation_control')}</h2>
                        <img
                            className='section10LeftBottom_1_1x w-100 mx-0 ms-lg-auto mb-4'
                            src='./images/productCenter/productFeature/section10LeftBottom_1_1x.png'
                            alt='section10LeftBottom_1_1x'
                        />
                        <div className='cardInfo px-6'>
                            <h3 className='title text-primary text-start mb-2'>{t('smooth_ball_trajectory')}</h3>
                            <h6 className='text text-white text-start mb-6'>{t('multiple_detection_mechanism')}</h6>
                        </div>
                        <img
                            className='section10RightBottom_1_1x w-100 mx-0 ms-lg-auto mb-4'
                            src='./images/productCenter/productFeature/section10RightBottom_1_1x.png'
                            alt='section10RightBottom_1_1x'
                        />
                        <div className='cardInfo px-6'>
                            <h3 className='title text-primary text-start mb-2'>{t('one_button_ball_return')}</h3>
                            <h6 className='text text-white text-start mb-12'>{t('one_button_auto_ball_return')}</h6>
                        </div>

                        <Link to='/support/downloadcenter'>
                            <button
                                type='button'
                                className='btn btn-outline-primary text-white rounded-pill py-2 mb-12'
                            >
                                {t('installation_guide')}
                            </button>
                        </Link>
                    </div>
                </section>
                <section className='productFeature-section section11 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h1 className='text-primary mb-5 mb-lg-12'>
                            <span className='mb-1 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>{t('ready_to_use')}</span>
                            <span className='d-block d-lg-inline'>{t('one_click_start')}</span>
                        </h1>
                        <p className='px-5 px-lg-6 mx-auto mb-5 mb-lg-12 text-grey8'>{t('smart_interactive_system')}</p>
                        <div className='textWrap d-flex justify-content-between px-5 px-lg-0 mx-auto'>
                            <div>
                                <h2 className='mb-1 text-primary'>{t('intuitive_smart')}</h2>
                                <h6 className='text-grey8'>{t('dedicated_app')}</h6>
                            </div>
                            <div className=''>
                                <h2 className='mb-1 text-primary'>{t('personalized')}</h2>
                                <h6 className='text-grey8'>{t('custom_preferences')}</h6>
                            </div>
                            <div className=''>
                                <h2 className='mb-1 text-primary'>{t('wireless_bluetooth')}</h2>
                                <h6 className='text-grey8'>{t('dual_connection')}</h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section12 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <picture>
                            <source
                                className='section12Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                                media='(min-width: 992px)'
                                srcSet='./images/productCenter/productFeature/section12_1_2x.png'
                                alt='section12Img'
                            />
                            <img
                                className='section12Img w-100 mx-0 ms-lg-auto mt-2'
                                src='./images/productCenter/productFeature/section12_1_1x.png'
                                alt='section12Img'
                            />
                        </picture>
                    </div>
                </section>
                <section className='productFeature-section section13 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-4 mb-lg-5 text-grey8'>{t('efficient_control')}</h2>
                        <p className='px-6 mx-auto mb-0 mb-lg-3 text-grey8'>{t('download_dedicated_APP')}</p>
                    </div>
                </section>
                <section className='productFeature-section section14 w-100 row align-items-top bg-white'>
                    <div className='col-12 position-relative p-0 text-center mb-lg-15'>
                        <picture>
                            <source
                                className='section14Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0'
                                media='(min-width: 992px)'
                                srcSet='./images/productCenter/productFeature/section14_1_2x.png'
                                alt='section14Img'
                            />
                            <img
                                className='section14Img w-100 px-4 mx-0 ms-lg-auto mt-lg-2'
                                src='./images/productCenter/productFeature/section14_1_1x.png'
                                alt='section14Img'
                            />
                        </picture>

                        <div className='d-none d-lg-block section14InfoBox position-absolute'>
                            <div className='leftTopWrap position-absolute'>
                                <h3 className='title text-black text-start mb-4'>{t('quick_response')}</h3>
                                <h6 className='text text-grey8 text-start'>{t('support_dual_connection')}</h6>
                            </div>
                            <div className='rightTopWrap position-absolute'>
                                <h3 className='title text-black text-start mb-4'>{t('comprehensive_parameters')}</h3>
                                <h6 className='text text-grey8 text-start'>
                                    <ul className='ps-0'>
                                        <li className='mb-1'>✓ {t('ball_frequency_speed')}</li>
                                        <li className='mb-1'>✓ {t('ball_angle')}</li>
                                        <li className='mb-1'>✓ {t('ball_delay')}</li>
                                        <li className='mb-1'>✓ {t('spin_angle_intensity')}</li>
                                        <li className='mb-1'>✓ {t('sequential_random')}</li>
                                        <li className='mb-1'>✓ {t('playing_time_balls')}</li>
                                    </ul>
                                </h6>
                            </div>
                            <div className='leftBottomWrap position-absolute'>
                                <h3 className='title text-black text-start mb-3'>{t('visual_interface')}</h3>
                                <h6 className='text text-grey8 text-start'>{t('excellent_interface_design')}</h6>
                            </div>
                        </div>

                        {/* mobile */}
                        <div className='d-block d-lg-none section14mobileBox mt-6'>
                            <div className={`box1 ${section14Slider === 1 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-black text-start px-7 mb-2'>{t('quick_response')}</h3>
                                <h6 className='text text-grey8 text-start px-7 mb-0'>{t('support_dual_connection')}</h6>
                            </div>
                            <div className={`box2 ${section14Slider === 2 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-black text-start px-7 mb-2'>
                                    {t('comprehensive_parameters')}
                                </h3>
                                <h6 className='text-grey8 text-start px-7 mb-0'>
                                    <ul className='ps-0'>
                                        <li className='mb-1'>✓ {t('ball_frequency_speed')}</li>
                                        <li className='mb-1'>✓ {t('ball_angle')}</li>
                                        <li className='mb-1'>✓ {t('ball_delay')}</li>
                                        <li className='mb-1'>✓ {t('spin_angle_intensity')}</li>
                                        <li className='mb-1'>✓ {t('sequential_random')}</li>
                                        <li className='mb-1'>✓ {t('playing_time_balls')}</li>
                                    </ul>
                                </h6>
                            </div>
                            <div className={`box3 ${section14Slider === 3 ? 'd-block' : 'd-none'}`}>
                                <h3 className='title text-black text-start px-7 mb-2'>{t('visual_interface')}</h3>
                                <h6 className='text-grey8 text-start px-7 mb-0'>{t('excellent_interface_design')}</h6>
                            </div>

                            <div className='sliderBtnWrapper d-flex justify-content-between p-2 border border-grey4 rounded-pill mt-6 mb-5 mx-auto'>
                                <div
                                    className={`btn-1 p-1 rounded-circle ${
                                        section14Slider === 1 ? 'bg-grey7' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSection14Slider(1)}
                                ></div>
                                <div
                                    className={`btn-2 p-1 rounded-circle ${
                                        section14Slider === 2 ? 'bg-grey7' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSection14Slider(2)}
                                ></div>
                                <div
                                    className={`btn-3 p-1 rounded-circle ${
                                        section14Slider === 3 ? 'bg-grey7' : 'bg-grey6'
                                    }`}
                                    onClick={() => setSection14Slider(3)}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <Link to='/explore'>
                            <button type='button' className='btn btn-outline-black rounded-pill py-2'>
                                {t('APP_display')}
                            </button>
                        </Link>
                    </div>
                </section>
                <section className='productFeature-section section15 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h1 className='text-primary mb-4 mb-lg-11'>
                            <span className='mb-1 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>
                                {t('warranty_after_sales')}
                            </span>
                            <span className='d-block d-lg-inline'>{t('pingpong_training_assured')}</span>
                        </h1>
                        <p className='px-4 mx-auto mb-6 mb-lg-12 text-grey8'>
                            {t('experience_thoughtful_after_sales_service')}
                        </p>
                        <div className='textWrap d-flex justify-content-between mx-auto px-11 px-lg-0'>
                            <div>
                                <h2 className='mb-2 text-primary'>
                                    <img
                                        className='section15_1Img h-100 mx-0'
                                        src='./images/productCenter/productFeature/section15_1_2x.png'
                                        alt='section15_1Img'
                                    />
                                </h2>
                                <h2 className='text-primary mb-0 mb-lg-2'>{t('authenticity_guarantee')}</h2>
                            </div>
                            <div className=''>
                                <h2 className='mb-2 text-primary'>
                                    <img
                                        className='section15_2Img h-100 mx-0'
                                        src='./images/productCenter/productFeature/section15_2_2x.png'
                                        alt='section15_2Img'
                                    />
                                </h2>
                                <h2 className='text-primary mb-0 mb-lg-2'>{t('premium_customer_service')}</h2>
                            </div>
                            <div className=''>
                                <h2 className='mb-2 text-primary'>
                                    <img
                                        className='section15_3Img h-100 mx-0'
                                        src='./images/productCenter/productFeature/section15_3_2x.png'
                                        alt='section15_3Img'
                                    />
                                </h2>
                                <h2 className='text-primary mb-0 mb-lg-2'>{t('one_year_warranty')}</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='productFeature-section section16 w-100 row justify-content-center px-0 bg-white'>
                    <div className='col-12 desc px-3 px-lg-0'>
                        <p className='text-grey6 mb-0 mb-lg-3'>{t('note')}</p>
                        <ul className='text-grey6 ps-0 ps-lg-5 mb-4 mb-lg-5'>
                            <li>1、{t('refresh_rate')}</li>
                            <li>2、{t('data_source')}</li>
                            <li>3、{t('non_medical_device')}</li>
                        </ul>
                        <p className='text-grey6 mb-0 mb-lg-3'>
                            {t('product_images_disclaimer')}
                            <br />
                            {t('data_disclaimer')}
                            <br />
                            {t('product_information_update')}
                            <br />
                            {t('changes_without_notice')}
                        </p>
                    </div>

                    <SupportBottom
                        leftCol={{
                            title: t('product_manual'),
                            text: t('view_more_content'),
                            link: `/support/downloadcenter`,
                        }}
                        rightCol={{
                            title: t('APP_download'),
                            text: t('view_more_content'),
                            link: `/app/download`,
                            section: '.section1',
                        }}
                    />

                    {/* <div className="col-12 p-0 w-100 afterSalesService border-grey4 border-top bg-white text-center">
                        <h2 className="mt-6 mt-lg-7 mb-2 mb-lg-3 text-grey8">
                            售后服务
                        </h2>
                        <h6 className="px-8 px-lg-0 mb-5 text-grey8">
                            欢迎使用宾乐达售后服务，期待为您提供帮助。
                        </h6>
                        <div className="btn btn-primary rounded-pill text-white mb-6 mb-lg-14">
                            跳转至服务功能
                        </div>
                    </div> */}
                </section>
            </article>
        </div>
    );
}

export default ProductFeature;
