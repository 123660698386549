// import './footerApp.scss';
import './footerAppNew.scss';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import { AccountContext } from '../../App';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { ReactComponent as Youtube } from './Youtube.svg';
import { ReactComponent as Facebook } from './Facebook.svg';

import React from 'react';

function FooterApp() {
    const { t } = useTranslation('footer'); // i18n
    const { auth, logout } = useAuth();

    // Context of apk urls
    const accountContext = useContext(AccountContext);
    const { accountInfo, getAccountInfo, homePageIndex, lang, setHomePageIndex, setLang } = accountContext;
    const iosUrl = 'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883';
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.synerter.SynerterSports&pli=1';
    const [isSimpleFooter, setIsSimpleFooter] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.split('/')[1];

    useEffect(() => {
        const pageWithSimpleFooter = [
            'login',
            'register',
            'forgotPwd',
            'exploreupdate',
            'physicalstoreupdate',
            'downloadcenterupdate',
            'questionupdate',
            'questioncategoryupdate',
        ];

        pageWithSimpleFooter.filter((e) => e === pathname).length > 0
            ? setIsSimpleFooter(true)
            : setIsSimpleFooter(false);
    }, [pathname, setIsSimpleFooter]);

    return (
        <>
            {/* <div className='container-fluid p-0'>
                <article id='footer__container'>
                    <footer
                        id='footer'
                        className='text-white bg-grey8 d-flex  flex-column justify-content-center align-items-center'
                    >
                        <section className={`footerTop w-100 h-100 py-2 py-md-4 ${isSimpleFooter && 'd-none'}`}>
                            <div className='container text-left text-md-center '>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <Accordion
                                            flush
                                            className='d-flex flex-column flex-md-row align-items-start justify-content-md-center'
                                        >
                                            <Accordion.Item
                                                eventKey='0'
                                                className='bg-grey8 w-100 px-md-2 px-lg-4 border-0'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('product_center')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='list-unstyled  ps-md-0'>
                                                        <li>
                                                            <Link to='/productcenter/productfeature'>
                                                                {t('product_highlights')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to='/productcenter/productstat'>
                                                                {t('specifications')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to='/app/download'>{t('app_guide')}</Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey='1'
                                                className='bg-grey8 w-100 px-md-2 px-lg-4 border-0'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('online_showroom')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='list-unstyled  ps-md-0'>
                                                        <li>
                                                            <Link to='/explore'>{t('online_showroom')}</Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey='2'
                                                className='bg-grey8 w-100 px-md-2 px-lg-4  border-0'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('purchasingChannel')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='list-unstyled  ps-md-0'>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/onlineStore',
                                                                }}
                                                            >
                                                                {t('online_store')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey='3'
                                                className='bg-grey8 w-100 px-md-2 px-lg-4  border-0'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('support_services')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='list-unstyled  ps-md-0'>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/support/question',
                                                                    state: {
                                                                        initialLocation: '.section2',
                                                                    },
                                                                }}
                                                            >
                                                                {t('faq')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/support/downloadcenter',
                                                                    state: {
                                                                        initialLocation: '.section2',
                                                                    },
                                                                }}
                                                            >
                                                                {t('product_manual')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/support/downloadcenter',
                                                                    state: {
                                                                        initialLocation: '.section4',
                                                                    },
                                                                }}
                                                            >
                                                                {t('app_download')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/support/contactus',
                                                                    state: {
                                                                        initialLocation: '.section2',
                                                                    },
                                                                }}
                                                            >
                                                                {t('contact_us')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span
                                                                className='navFakeLink px-0 px-lg-3'
                                                                onClick={() => {
                                                                    if (auth.accessToken) {
                                                                        navigate('/product');
                                                                    } else {
                                                                        logout('search=product');
                                                                    }
                                                                }}
                                                            >
                                                                <span className='pb-1 nav-btn'>
                                                                    {t('product_registration')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey='4'
                                                className='bg-grey8 w-100 px-md-2 px-lg-4  border-0'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('about_synerter')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='list-unstyled  ps-md-0'>
                                                        <li>
                                                            <Link to='/about'>{t('company_profile')}</Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={{
                                                                    pathname: '/agreement',
                                                                    state: {
                                                                        initialLocation: '.section10',
                                                                    },
                                                                }}
                                                            >
                                                                {t('intellectual_property')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey='5'
                                                className='bg-grey8 w-100 h-100 px-md-2 px-lg-4'
                                            >
                                                <Accordion.Header className='text-white bg-grey8'>
                                                    {t('synerter_app_download')}
                                                </Accordion.Header>
                                                <Accordion.Body className='bg-grey8'>
                                                    <ul className='imgQRcode list-unstyled ps-md-0 d-flex flex-md-column flex-xl-row'>
                                                        <li>
                                                            <div
                                                                onClick={() =>
                                                                    window.open(
                                                                        'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                                                        '_blank'
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    margin: '8px',
                                                                }}
                                                            >
                                                                <img
                                                                    className='w-100'
                                                                    src='./images/download_IOS.svg'
                                                                    alt='iosQRcode'
                                                                />
                                                                <QRCodeSVG
                                                                    size='90'
                                                                    includeMargin={true}
                                                                    value={iosUrl}
                                                                />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div
                                                                onClick={() => window.open(`${androidUrl}`, '_blank')}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    margin: '8px',
                                                                }}
                                                            >
                                                                <img
                                                                    className='w-100'
                                                                    src='./images/download_android.svg'
                                                                    alt='anQRcode'
                                                                />
                                                                <QRCodeSVG
                                                                    size='90'
                                                                    includeMargin={true}
                                                                    value={androidUrl}
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='footerBottom w-100 px-5 py-3 border-0 border-top border-grey6 d-flex flex-column flex-lg-row align-items-center justify-content-around'>
                            <div className='wrapper w-100 d-flex flex-column flex-md-row justify-content-between'>
                                <div className='d-flex mb-2 mb-md-0'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                        color='#ffffff'
                                        fill='none'
                                    >
                                        <path
                                            d='M13.6177 21.367C13.1841 21.773 12.6044 22 12.0011 22C11.3978 22 10.8182 21.773 10.3845 21.367C6.41302 17.626 1.09076 13.4469 3.68627 7.37966C5.08963 4.09916 8.45834 2 12.0011 2C15.5439 2 18.9126 4.09916 20.316 7.37966C22.9082 13.4393 17.599 17.6389 13.6177 21.367Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                        />
                                        <path
                                            d='M15.5 11C15.5 12.933 13.933 14.5 12 14.5C10.067 14.5 8.5 12.933 8.5 11C8.5 9.067 10.067 7.5 12 7.5C13.933 7.5 15.5 9.067 15.5 11Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                        />
                                    </svg>
                                    <a
                                        href='https://maps.app.goo.gl/CjXFnKfNpt7xJPB7A'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='ms-2'
                                    >
                                        {t('address')}
                                    </a>
                                </div>
                                <div className='d-flex mb-2 mb-md-0'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                        color='#ffffff'
                                        fill='none'
                                    >
                                        <path
                                            d='M4.74038 14.3685L6.69351 12.9816C7.24445 12.5904 7.80305 12.3282 8.44034 12.1585C9.17201 11.9636 9.5 11.5644 9.5 10.711C9.5 8.54628 14.5 8.31594 14.5 10.711C14.5 11.5644 14.828 11.9636 15.5597 12.1585C16.202 12.3295 16.7599 12.5934 17.3065 12.9816L19.2596 14.3685C20.1434 14.9961 20.5547 15.2995 20.7842 15.7819C21 16.2358 21 16.768 21 17.8324C21 19.7461 21 20.703 20.4642 21.3164C19.8152 22.0593 18.128 21.9955 17.0917 21.9955H6.90833C5.87197 21.9955 4.21909 22.0986 3.5358 21.3164C3 20.703 3 19.7461 3 17.8324C3 16.768 3 16.2358 3.21584 15.7819C3.44526 15.2995 3.85662 14.9961 4.74038 14.3685Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                        />
                                        <path
                                            d='M14 17C14 18.1046 13.1046 19 12 19C10.8954 19 10 18.1046 10 17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                        />
                                        <path
                                            d='M6.96014 3.69772C5.6417 4.07415 4.69384 4.54112 3.82645 5.10455C2.45318 5.9966 1.86443 7.60404 2.02607 9.15513C2.09439 9.81068 2.62064 10.1241 3.23089 9.95455C3.69451 9.82571 4.15888 9.7003 4.61961 9.56364C5.96706 9.16397 6.28399 8.67812 6.47124 7.29885L6.96014 3.69772ZM6.96014 3.69772C10.2186 2.76743 13.7814 2.76743 17.0399 3.69772M17.0399 3.69772C18.3583 4.07415 19.3062 4.54112 20.1735 5.10455C21.5468 5.9966 22.1356 7.60404 21.9739 9.15513C21.9056 9.81068 21.3794 10.1241 20.7691 9.95455C20.3055 9.82571 19.8411 9.7003 19.3804 9.56364C18.0329 9.16397 17.716 8.67812 17.5288 7.29885L17.0399 3.69772Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinejoin='round'
                                        />
                                    </svg>

                                    <Link
                                        to='#'
                                        onClick={(e) => {
                                            window.location.href = 'tel:02-88666008';
                                            e.preventDefault();
                                        }}
                                    >
                                        <div className='ms-2'>{t('phone')}</div>
                                    </Link>
                                </div>
                                <div className='d-flex'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                        color='#ffffff'
                                        fill='none'
                                    >
                                        <path
                                            d='M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinejoin='round'
                                        />
                                    </svg>

                                    <Link
                                        to='#'
                                        onClick={(e) => {
                                            window.location.href = 'mailto:contact@synerter.com';
                                            e.preventDefault();
                                        }}
                                    >
                                        <div className='ms-2'>contact@synerter.com</div>
                                    </Link>
                                </div>
                            </div>
                        </section>
                        <section className='footerBottom w-100 px-5 py-3 border-0 border-top border-grey6 d-flex flex-column flex-lg-row align-items-center justify-content-center'>
                            <div className='wrapper w-100'>
                                <div className='border-0 d-flex flex-column flex-lg-row align-items-center justify-content-center'>
                                    <div className='d-flex col-10 copyRightFooter'>
                                        <span className='text-center'>{t('copyright')}</span>
                                    </div>
                                    <div className='privacyFooter d-flex col-2 justify-content-end'>
                                        <div className='userAgreementHref'>
                                            <Link
                                                to={{
                                                    pathname: `/agreement/${lang}`,
                                                }}
                                            >
                                                {t('user_agreement')}
                                            </Link>
                                        </div>
                                        <div className='privacyPolicyHref'>
                                            <Link
                                                to={{
                                                    pathname: `/privacy/${lang}`,
                                                }}
                                            >
                                                {t('privacy_policy')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </footer>
                </article>
            </div> */}
            <article id='footer__container'>
                <footer className='container my-5'>
                    <section className='row gy-2'>
                        <div className='col col-md-4'>
                            <div>
                                <h5 className='mb-md-3 mb-2 text-primary'>{t('synerter_co_ltd')}</h5>
                                <div className='text-start'>
                                    <p className='mb-1 text-black'>{t('address')}</p>
                                    <p className='mb-1 text-black'>{t('phone')}</p>
                                    <p className='mb-2 text-black'>contact@synerter.com</p>
                                </div>
                                <div className='d-flex'>
                                    <a
                                        href='https://www.facebook.com/SynerterTableTennisRobot?locale=zh_TW'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <button className='circle_btn facebook'>
                                            <Facebook />
                                        </button>
                                    </a>
                                    <a href='https://www.youtube.com/@synerter' target='_blank' rel='noreferrer'>
                                        <button className='circle_btn youtube'>
                                            <Youtube />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-2 col-lg-2'>
                            <Accordion flush>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>
                                        <h6 className='mb-md-3 mb-2 text-primary'>{t('product_center')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/productcenter/productfeature'>
                                            <p>{t('synerter_ping_pong_robots')}</p>
                                        </Link>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <Link to='/explore'>
                                            <p>{t('online_showroom')}</p>
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='col-12 col-md-2 col-lg-2'>
                            <Accordion flush>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header>
                                        <h6 className='mb-md-3 mb-2 text-primary'>{t('support_services')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/support/question'>
                                            <p>{t('faq')}</p>
                                        </Link>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <Link to='/support/downloadcenter'>
                                            <p>{t('product_manual')}</p>
                                        </Link>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <Link to='/app/download'>
                                            <p>{t('app_guide')}</p>
                                        </Link>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <Link to='/product'>
                                            <p>{t('product_registration')}</p>
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='col-12 col-md-2 col-lg-2'>
                            <Accordion flush>
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header>
                                        <h6 className='mb-md-3 mb-2 text-primary'>{t('about_synerter')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/about'>
                                            <p>{t('company_profile')}</p>
                                        </Link>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <Link to='/support/contactus'>
                                            <p>{t('contact_us')}</p>
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='col-12 col-md-2 col-lg-2'>
                            <Accordion flush>
                                <Accordion.Item eventKey='4'>
                                    <Accordion.Header>
                                        <h6 className='mb-md-3 mb-2 text-primary'>{t('synerter_app_download')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-lg-flex'>
                                            <div
                                                className='d-flex flex-column align-items-center justify-content-center me-2'
                                                onClick={() =>
                                                    window.open(
                                                        'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                                        '_blank'
                                                    )
                                                }
                                                style={{
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    backgroundColor: 'black',
                                                    padding: '5px',
                                                }}
                                            >
                                                <img
                                                    className='QR_code_img'
                                                    src='./images/download_IOS.svg'
                                                    alt='iosQRcode'
                                                />
                                                <QRCodeSVG size='90' includeMargin={true} value={iosUrl} />
                                            </div>
                                            <div
                                                className='d-flex flex-column align-items-center justify-content-center QR__width'
                                                onClick={() => window.open(`${androidUrl}`, '_blank')}
                                                style={{
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    backgroundColor: 'black',
                                                    padding: '5px',
                                                }}
                                            >
                                                <img
                                                    className='QR_code_img'
                                                    src='./images/download_android.svg'
                                                    alt='anQRcode'
                                                />
                                                <QRCodeSVG size='90' includeMargin={true} value={androidUrl} />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </section>
                    <section>
                        <div className='border my-5'></div>
                    </section>
                    <section className='d-md-flex justify-content-between'>
                        <p>{t('copyright')}</p>
                        <div className='d-flex'>
                            <div className='border-end pe-2'>
                                <Link
                                    to={{
                                        pathname: `/agreement/${lang}`,
                                    }}
                                >
                                    <p>{t('user_agreement')}</p>
                                </Link>
                            </div>
                            <div className='ps-2'>
                                <Link
                                    to={{
                                        pathname: `/privacy/${lang}`,
                                    }}
                                >
                                    <p>{t('privacy_policy')}</p>
                                </Link>
                            </div>
                        </div>
                    </section>
                </footer>
            </article>
        </>
    );
}

export default FooterApp;
