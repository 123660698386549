import Layout from '../components/layout/Layout';
import LayoutAccount from '../components/layoutAccount/LayoutAccount';

import {
    Homepage,
    AccountDelete,
    BaseInfo,
    ForgotPwd,
    Login,
    PwdReset,
    Register,
    AppDownload,
    ProductFeature,
    ProductStat,
    MyProduct,
    ProductRegister,
    ProductVip,
    ProductVipSearch,
    ErrorPage,
    MaintenancePage,
    NotFoundPage,
    About,
    Explore,
    PhysicalStore,
    OnlineStore,
    ProductDetail,
    Cart,
    SupportContactUs,
    SupportDownloadCenter,
    SupportQuestion,
    OTAAudioUpdate,
    OTAAudioUpdateDetail,
    OTAFirmwareUpdate,
    OTAFirmwareUpdateDetail,
    OTAMainUpdate,
    OTAMainUpdateDetail,
    AppMainUpdate,
    AppMainUpdateDetail,
    AppUpdate,
    AppUpdateDetail,
    AuthUpdate,
    AuthUpdateEdit,
    AuthUpdateGroupName,
    AuthUpdateMemberCreate,
    Bldc,
    Cms,
    DownloadCenterUpdate,
    DownloadCenterUpdateDetail,
    DrillCategoryUpdate,
    DrillCategoryUpdateDetail,
    ExploreUpdate,
    ExploreUpdateChange,
    Fan,
    Feedback,
    FileUpdateLayout,
    PhysicalStoreUpdate,
    PhysicalStoreUpdateChange,
    PresetDrill,
    PresetDrillCategoryItemUpdateDetail,
    PresetDrillCategoryUpdateDetail,
    PresetDrillUpdate,
    QuestionCategoryUpdate,
    QuestionCategoryUpdateDetail,
    QuestionUpdate,
    QuestionUpdateDetail,
    Stp,
    UserDrill,
    UserDrillAll,
    UserDrillItem,
    UsersUpdate,
    UsersUpdateDetail,
    FactoryRecord,
    FactoryRecordChange,
    FactoryRecordLog,
    ProductionRecord,
    ProductionRecordChange,
    ProductionRecordLog,
    NewsList,
    News,
    NewsUpdateChange,
    NewsUpdate,
    UserDrillClass,
    UserDrillClassItem,
    ActRegister,
    ActRegisterInfoForUser,
    ActActivity,
    ActivityUpdate,
    ActSchedule,
    ActScheduleUpdate,
    ActCallingSystem,
    ActScheduleRegistration,
    PdCreditCard,
    PdCheckout,
    PdUnpaid,
    SalSalesKitClass,
    SalSalesKitClassUpdate,
    SalSalesKitCreate,
    SalSalesKit,
    SalSalesKitUpdate,
    SalSalesKitAppendix,
    SalSalesKitAppendixUpdate,
    SalSalesKitPreview,
    Privacy,
    Privacy_en,
    Agreement,
    Agreement_en,
    OrderOverview,
    OrderDetails,
    OrderInvoiceDetails,
    OrderInvoiceDetailsPrint,
    Payout,
    QueryTransaction,
    CheckoutInfo,
    PdCreditCardFail,
    OrderIssueInvoice,
    OrderIssueInvoiceChange,
    OrderIssueInvoiceDetails,
} from '../pages';

const pagesData = [
    {
        // 首頁
        path: '/',
        title: 'home-page',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Homepage />
            </Layout>
        ),
    },
    {
        // PD 信用卡結帳
        path: '/PdCreditCard/:orderGuid',
        title: 'pd-credit-card',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <PdCreditCard />
            </Layout>
        ),
    },
    {
        // PD 信用卡結帳失敗
        path: '/PdCreditCardFail',
        title: 'pd-credit-card-fail',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <PdCreditCardFail />
            </Layout>
        ),
    },
    {
        // PD 訂單
        path: '/PdOrder',
        title: 'pd-order',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <CheckoutInfo />
            </Layout>
        ),
    },
    {
        // PD 結帳
        path: '/PdCheckout/:orderGuid',
        title: 'pd-checkout',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <PdCheckout />
            </Layout>
        ),
    },
    {
        // PD 未付提醒
        path: '/PdUnpaid/:orderGuid',
        title: 'pd-unpaid',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <PdUnpaid />
            </Layout>
        ),
    },
    {
        // 產品中心 - 產品特色
        path: '/productCenter/productFeature',
        title: 'product-feature',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ProductFeature />
            </Layout>
        ),
    },
    {
        // 產品中心 - 產品統計
        path: '/productCenter/productStat',
        title: 'product-stat',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ProductStat />
            </Layout>
        ),
    },
    {
        // 購買渠道 - 線上商店
        path: '/onlineStore',
        title: 'online-store',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <OnlineStore />
            </Layout>
        ),
    },
    {
        // 購買渠道 - 實體商店
        path: '/physicalstore',
        title: 'physical-store',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <PhysicalStore />
            </Layout>
        ),
    },
    {
        // 產品詳細
        path: '/products/:productGuid',
        title: 'product-detail',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ProductDetail />
            </Layout>
        ),
    },
    {
        // 購物車
        path: '/cart',
        title: 'cart',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Cart />
            </Layout>
        ),
    },
    {
        // 服務支持 - 常見問題
        path: '/support/question',
        title: 'support-question',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <SupportQuestion />
            </Layout>
        ),
    },
    {
        // 服務支持 - TT機器人
        path: '/support/ttrobot',
        title: 'support-ttrobot',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <SupportQuestion />
            </Layout>
        ),
    },
    {
        // 服務支持 - 下載中心
        path: '/support/downloadcenter',
        title: 'support-downloadcenter',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <SupportDownloadCenter />
            </Layout>
        ),
    },
    {
        // 服務支持 - 聯繫我們
        path: '/support/contactus',
        title: 'support-contactus',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <SupportContactUs />
            </Layout>
        ),
    },
    {
        // 關於賓樂達
        path: '/about',
        title: 'about',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <About />
            </Layout>
        ),
    },
    {
        // 賓樂達APP下載
        path: '/app/download',
        title: 'app-download',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <AppDownload />
            </Layout>
        ),
    },
    {
        // 用戶協議 - APP
        path: '/agreement',
        title: 'agreement',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Agreement />
            </Layout>
        ),
    },
    {
        // 用戶協議 - zh-TW
        path: '/agreement/zh-TW',
        title: 'agreement-zh-TW',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Agreement />
            </Layout>
        ),
    },
    {
        // 用戶協議 - en
        path: '/agreement/en',
        title: 'agreement-en',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Agreement />
            </Layout>
        ),
    },
    {
        // 隱私政策 - APP
        path: '/privacy',
        title: 'privacy',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Privacy />
            </Layout>
        ),
    },
    {
        // 隱私政策 - zh-TW
        path: '/privacy/zh-TW',
        title: 'privacy-zh-TW',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Privacy />
            </Layout>
        ),
    },
    {
        // 隱私政策 - en
        path: '/privacy/en',
        title: 'privacy-en',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Privacy />
            </Layout>
        ),
    },
    {
        // 网上展示厅
        path: '/explore',
        title: 'explore',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Explore />
            </Layout>
        ),
    },
    {
        // 新聞動態 - 列表
        path: '/news',
        title: 'news-list',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <NewsList />
            </Layout>
        ),
    },
    {
        // 新聞動態 - 內容
        path: '/news/:bulletinGuid',
        title: 'news-content',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <News />
            </Layout>
        ),
    },
    {
        // 訂單總覽
        path: '/orders',
        title: 'orders-overview',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <OrderOverview />
            </LayoutAccount>
        ),
    },
    {
        // 訂單詳情
        path: '/orderDetails/:orderGuid',
        title: 'order-details',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <OrderDetails />
            </LayoutAccount>
        ),
    },
    {
        // 訂單發票詳情
        path: '/OrderInvoiceDetails/:orderGuid',
        title: 'order-invoice-details',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <OrderInvoiceDetails />
            </LayoutAccount>
        ),
    },
    {
        // 訂單發票詳情列印
        path: '/OrderInvoiceDetailsPrint/:orderGuid',
        title: 'order-invoice-details-print',
        permission: '',
        requireAuth: true,
        element: <OrderInvoiceDetailsPrint />,
    },
    {
        // 登入
        path: '/login',
        title: 'login',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Login />
            </Layout>
        ),
    },
    {
        // 註冊
        path: '/register',
        title: 'register',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <Register />
            </Layout>
        ),
    },
    {
        // 忘記密碼
        path: '/forgotpwd',
        title: 'forgot-password',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ForgotPwd />
            </Layout>
        ),
    },
    /* ------登入後頁面------ */
    {
        // 個人頁面 - 基本信息
        path: '/baseInfo',
        title: 'base-info',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <BaseInfo />
            </LayoutAccount>
        ),
    },
    {
        // 修改密碼
        path: '/pwdreset',
        title: 'pwd-reset',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <PwdReset />
            </LayoutAccount>
        ),
    },
    {
        // 刪除帳號
        path: '/accountDelete',
        title: 'account-delete',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <AccountDelete />
            </LayoutAccount>
        ),
    },
    {
        // 我的產品
        path: '/product',
        title: 'my-product',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <MyProduct />
            </LayoutAccount>
        ),
    },
    {
        // 產品註冊
        path: '/product/productregister',
        title: 'product-register',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <ProductRegister />
            </LayoutAccount>
        ),
    },
    {
        // 會員產品VIP
        path: '/member/productvip',
        title: 'product-vip',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <ProductVip />
            </LayoutAccount>
        ),
    },
    {
        // 會員產品VIP搜索
        path: '/member/productvipsearch',
        title: 'product-vip-search',
        permission: '',
        requireAuth: true,
        element: (
            <LayoutAccount>
                <ProductVipSearch />
            </LayoutAccount>
        ),
    },
    {
        // 活動註冊
        path: '/activity/:activityGuid',
        title: 'activity-register',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ActRegister />
            </Layout>
        ),
    },
    {
        // 活動註冊成功頁面
        path: '/actRegisterInfoForUser/:registrationGuid',
        title: 'activity-register-info',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ActRegisterInfoForUser />
            </Layout>
        ),
    },
    {
        // 活動註冊查詢頁面
        path: '/getRegistrationList/:scheduleGuid',
        title: 'activity-registration-list',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ActRegisterInfoForUser />
            </Layout>
        ),
    },
    {
        // 叫號系統 後台
        path: '/admin/activity/callingSystem/:scheduleGuid',
        title: 'activity-calling-system',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ActCallingSystem />
            </Layout>
        ),
    },

    {
        /* 活動列表 後台 */
        path: '/admin/actActivity',
        title: 'act-activity',
        permission: 'authActivity',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ActActivity />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 活動註冊 後台 - 新增 */
        path: '/admin/actActivity/create',
        title: 'activity-update',
        permission: 'authActivity',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ActivityUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 活動註冊 後台 */
        path: '/admin/actActivity/:activityGuid',
        title: 'admin-activity-update',
        permission: 'authActivity',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ActivityUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    // {
    //     /* 活動日程列表 後台 */
    //     path: '/admin/actSchedule',
    //     title: 'act-schedule',
    //     permission: 'authActivity',
    //     requireAuth: false,
    //     element: (
    //         <Layout>
    //             <FileUpdateLayout>
    //                 <ActSchedule />
    //             </FileUpdateLayout>
    //         </Layout>
    //     ),
    // },
    {
        /* 活動日程註冊 後台 - 新增 */
        path: '/admin/actSchedule/create',
        title: 'act-schedule-create',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ActScheduleUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 活動日程註冊 後台 - 編輯 */
        path: '/admin/actSchedule/:scheduleGuid',
        title: 'act-schedule-edit',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ActScheduleUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 活動日程的報名表 後台 */
        path: '/admin/actscheduleregistration/:scheduleGuid',
        title: 'act-schedule-registration-admin',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <ActScheduleRegistration />
            </Layout>
        ),
    },
    // {
    //     /* 工廠與韌體 - BLDC */
    //     path: '/bldc',
    //     title: 'bldc',
    //     permission: '',
    //     requireAuth: true,
    //     element: <Bldc />,
    // },
    // {
    //     /* 工廠與韌體 - STP */
    //     path: '/stp',
    //     title: 'stp',
    //     permission: '',
    //     requireAuth: true,
    //     element: <Stp />,
    // },
    // {
    //     /* 工廠與韌體 - Fan */
    //     path: '/fan',
    //     title: 'fan',
    //     permission: '',
    //     requireAuth: true,
    //     element: <Fan />,
    // },
    {
        /* 工廠與韌體 - Feedback */
        path: '/feedback',
        title: 'feedback',
        permission: '',
        requireAuth: true,
        element: <Feedback />,
    },
    {
        /* 後台入口 - CMS */
        path: '/cms',
        title: 'cms',
        permission: '',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <Cms />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 系統管理 */
    {
        /* APP基本设定 - 主要更新 */
        path: '/appMainUpdate',
        title: 'app-main-update',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppMainUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* APP基本设定 - 主要更新 - 編輯 */
        path: '/appMainUpdate/edit/:appGuid',
        title: 'app-main-update-edit',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppMainUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* APP基本设定 - 主要更新 - 新增 */
        path: '/appMainUpdate/create',
        title: 'app-main-update-create',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppMainUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA基本设定 - 主要更新 */
        path: '/OTAMainUpdate',
        title: 'ota-main-update',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAMainUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA基本设定 - 主要更新 - 編輯 */
        path: '/OTAMainUpdate/edit/:otaGuid',
        title: 'ota-main-update-edit',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAMainUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA基本设定 - 主要更新 - 新增 */
        path: '/OTAMainUpdate/create',
        title: 'ota-main-update-create',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAMainUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 */
        path: '/authUpdate',
        title: 'auth-update',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 - 群組名稱 - 新增 */
        path: '/authUpdate/authUpdateGroupName/create',
        title: 'auth-update-groupname-create',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdateGroupName />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 - 群組名稱 - 編輯 */
        path: '/authUpdate/authUpdateGroupName/edit/:authorizationGuid',
        title: 'auth-update-groupname-edit',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdateGroupName />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 - 成員頁面 - 編輯 */
        path: '/authUpdate/memberPage/edit',
        title: 'auth-update-memberpage-edit',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdateEdit />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 - 權限頁面 - 編輯 */
        path: '/authUpdate/authPage/edit/:authorizationGuid',
        title: 'auth-update-authpage-edit',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdateEdit />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 權限設定 - 成員頁面 - 新增 */
        path: '/authUpdate/memberPage/create',
        title: 'auth-update-memberpage-create',
        permission: 'authSystem',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AuthUpdateMemberCreate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* APP管理 */
        path: '/appupdate',
        title: 'app-update',
        permission: 'authApp',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* APP管理 - 新增 */
        path: '/appupdate/create',
        title: 'app-update-create',
        permission: 'authApp',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* APP管理 - 編輯 */
        path: '/appupdate/edit',
        title: 'app-update-edit',
        permission: 'authApp',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <AppUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Firmware */
        path: '/otafirmwareupdate',
        title: 'ota-firmware-update',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAFirmwareUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Firmware - 新增 */
        path: '/otafirmwareupdate/create',
        title: 'ota-firmware-update-create',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAFirmwareUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Firmware - 編輯 */
        path: '/otafirmwareupdate/edit',
        title: 'ota-firmware-update-edit',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAFirmwareUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Audio */
        path: '/otaaudioupdate',
        title: 'ota-audio-update',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAAudioUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Audio - 新增 */
        path: '/otaaudioupdate/create',
        title: 'ota-audio-update-create',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAAudioUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* OTA管理 - Audio - 編輯 */
        path: '/otaaudioupdate/edit',
        title: 'ota-audio-update-edit',
        permission: 'authOta',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OTAAudioUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用户管理 */
        path: '/usersUpdate',
        title: 'users-update',
        permission: 'authUser',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UsersUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用户管理 - 编辑 */
        path: '/usersUpdate/edit/:userGuid',
        title: 'users-update-edit',
        permission: 'authUser',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UsersUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 賓樂達球譜管理 */
    {
        /* 用戶球譜 */
        path: '/userDrill',
        title: 'user-drill',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillAll />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜 - 個別用戶 */
        path: '/userDrill/:userGuid',
        title: 'user-drill-user',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrill />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜 - 單項球譜 */
        path: '/userDrillItem/:userDrillGuid',
        title: 'user-drill-item',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillItem />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜 - 創建單項球譜 */
        path: '/userDrillItem/create',
        title: 'user-drill-item-create',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillItem />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜分類 */
        path: '/userDrillClass',
        title: 'user-drill-class',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillAll />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜分類 - 單項分類 */
        path: '/userDrillClass/:userGuid',
        title: 'user-drill-class-item',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillClass />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜分類 - 單項分類項目 */
        path: '/userDrillClassItem/:classGuid',
        title: 'user-drill-class-item',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillClassItem />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 用戶球譜分類 - 創建單項分類項目 */
        path: '/userDrillClassItem/create',
        title: 'user-drill-class-item-create',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <UserDrillClassItem />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 */
        path: '/presetDrill',
        title: 'preset-drill',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrill />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 - 創建球譜類別 */
        path: '/presetDrill/drillCategory/create',
        title: 'preset-drill-category-create',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrillCategoryUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 - 編輯球譜類別 */
        path: '/presetDrill/drillCategory/edit/:categoryGuid',
        title: 'preset-drill-category-edit',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrillCategoryUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 - 創建球譜類別項目 */
        path: '/presetDrill/drillCategoryItem/create',
        title: 'preset-drill-category-item-create',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrillCategoryItemUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 - 編輯球譜類別項目 */
        path: '/presetDrill/drillCategoryItem/edit/:categoryItemGuid',
        title: 'preset-drill-category-item-edit',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrillCategoryItemUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 賓樂達球譜 - 編輯預設球譜 */
        path: '/presetDrill/PresetDrillUpdate/edit/:presetDrillGuid',
        title: 'preset-drill-update',
        permission: 'authPresetDrill',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PresetDrillUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    // {
    //     /* 球譜管理-宾乐达球谱型态 */
    //     path: '/drillCategoryUpdate',
    //     title: 'drill-category-update',
    //     permission: 'authPresetDrill',
    //     requireAuth: true,
    //     element: (
    //         <Layout>
    //             <FileUpdateLayout>
    //                 <DrillCategoryUpdate />
    //             </FileUpdateLayout>
    //         </Layout>
    //     ),
    // },
    // {
    //     /* 球譜管理-宾乐达球谱型态 - 创建 */
    //     path: '/drillCategoryUpdate/create',
    //     title: 'drill-category-create',
    //     permission: 'authPresetDrill',
    //     requireAuth: true,
    //     element: (
    //         <Layout>
    //             <FileUpdateLayout>
    //                 <DrillCategoryUpdateDetail />
    //             </FileUpdateLayout>
    //         </Layout>
    //     ),
    // },
    // {
    //     /* 球譜管理-宾乐达球谱型态 - 编辑 */
    //     path: '/drillCategoryUpdate/edit/:categoryCode',
    //     title: 'drill-category-edit',
    //     permission: 'authPresetDrill',
    //     requireAuth: true,
    //     element: (
    //         <Layout>
    //             <FileUpdateLayout>
    //                 <DrillCategoryUpdateDetail />
    //             </FileUpdateLayout>
    //         </Layout>
    //     ),
    // },
    /* authWeb */
    {
        /* 后台管理-网上展示厅 */
        path: '/exploreupdate',
        title: 'explore-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ExploreUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-网上展示厅-创建 */
        path: '/exploreupdate/create',
        title: 'explore-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ExploreUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-网上展示厅-编辑 */
        path: '/exploreupdate/edit/:videoGuid',
        title: 'explore-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ExploreUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-新聞動態 */
        path: '/newsupdate',
        title: 'news-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <NewsUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-新聞動態-创建 */
        path: '/newsupdate/create',
        title: 'news-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <NewsUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-新聞動態-编辑 */
        path: '/newsupdate/edit/:bulletinGuid',
        title: 'news-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <NewsUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-销售网点 */
        path: '/physicalstoreupdate',
        title: 'physical-store-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PhysicalStoreUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-销售网点-创建 */
        path: '/physicalstoreupdate/create',
        title: 'physical-store-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PhysicalStoreUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-销售网点-编辑 */
        path: '/physicalstoreupdate/edit/:storeGuid',
        title: 'physical-store-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <PhysicalStoreUpdateChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-下载中心 */
        path: '/downloadcenterupdate',
        title: 'download-center-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <DownloadCenterUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-下载中心-创建 */
        path: '/downloadcenterupdate/create',
        title: 'download-center-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <DownloadCenterUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-下载中心-编辑 */
        path: '/downloadcenterupdate/edit/:manualGuid',
        title: 'download-center-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <DownloadCenterUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题 */
        path: '/questionupdate',
        title: 'question-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题-创建 */
        path: '/questionupdate/create',
        title: 'question-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题-编辑 */
        path: '/questionupdate/edit/:qaGuid',
        title: 'question-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题型态 */
        path: '/questioncategoryupdate',
        title: 'question-category-update',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionCategoryUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题型态-创建 */
        path: '/questioncategoryupdate/create',
        title: 'question-category-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionCategoryUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 后台管理-常见问题型态-编辑 */
        path: '/questioncategoryupdate/edit/:categoryCode',
        title: 'question-category-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <QuestionCategoryUpdateDetail />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* authWeb */
    /* 發球機生管 */
    {
        /* 生产记录 */
        path: '/productionRecord',
        title: 'production-record',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ProductionRecord />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 生产记录-创建 */
        path: '/productionRecord/create',
        title: 'production-record-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ProductionRecordChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 生产记录-编辑 */
        path: '/productionRecord/edit/:productionRecordGuid',
        title: 'production-record-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ProductionRecordChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 生产记录-日志 */
        path: '/productionRecord/log/:productionRecordGuid',
        title: 'production-record-log',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <ProductionRecordLog />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 發球機生管 */
    {
        /* 出厂记录 */
        path: '/factoryRecord',
        title: 'factory-record',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <FactoryRecord />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 出厂记录-创建 */
        path: '/factoryRecord/create',
        title: 'factory-record-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <FactoryRecordChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 出厂记录-编辑 */
        path: '/factoryRecord/edit/:factoryRecordGuid',
        title: 'factory-record-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <FactoryRecordChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 出厂记录-日志 */
        path: '/factoryRecord/log/:factoryRecordGuid',
        title: 'factory-record-log',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <FactoryRecordLog />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 商品管理 */
    {
        /* 商品分類 */
        path: '/SalSalesKitClass',
        title: 'sales-kit-class',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitClass />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 新增商品分類 */
        path: '/SalSalesKitClass/create',
        title: 'sales-kit-class-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitClassUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 新增商品列表 */
        path: '/SalSalesKit/create',
        title: 'sales-kit-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitCreate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 編輯商品列表 */
        path: '/SalSalesKit',
        title: 'sales-kit-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKit />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 預覽商品 */
        path: '/SalSalesKit/preview',
        title: 'sales-kit-preview',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <SalSalesKitPreview />
            </Layout>
        ),
    },
    {
        /* 編輯商品列表 */
        path: '/SalSalesKit/:salesKitGuid',
        title: 'sales-kit-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 商品分類編輯 */
        path: '/SalSalesKitClass/:salesKitClassGuid',
        title: 'sales-kit-class-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitClassUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 商品附件管理 */
        path: '/SalSalesKitAppendix',
        title: 'sales-kit-appendix',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitAppendix />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        /* 商品附件編輯 */
        path: '/SalSalesKitAppendix/:salesKitGuid',
        title: 'sales-kit-appendix-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <SalSalesKitAppendixUpdate />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 訂單管理 */
    /* 訂單開發票 */
    {
        path: '/orderIssueInvoice',
        title: 'order-issue-invoice',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OrderIssueInvoice />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        path: '/orderIssueInvoice/create',
        title: 'order-issue-invoice-create',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OrderIssueInvoiceChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        path: '/orderIssueInvoice/edit/:orderGuid',
        title: 'order-issue-invoice-edit',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OrderIssueInvoiceChange />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    {
        path: '/orderIssueInvoiceDetails/:orderGuid',
        title: 'order-issue-invoice-details',
        permission: 'authWeb',
        requireAuth: true,
        element: (
            <Layout>
                <FileUpdateLayout>
                    <OrderIssueInvoiceDetails />
                </FileUpdateLayout>
            </Layout>
        ),
    },
    /* 錯誤提示網頁  */
    {
        path: '/error',
        title: 'error-page',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <ErrorPage />
            </Layout>
        ),
    },
    /* 維護中  */
    {
        path: '/maintenance',
        title: 'maintenance-page',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <MaintenancePage />
            </Layout>
        ),
    },
    /* 找不到網頁  */
    {
        path: '*',
        title: 'not-found-page',
        permission: '',
        requireAuth: false,
        element: (
            <Layout>
                <NotFoundPage />
            </Layout>
        ),
    },
];

export default pagesData;
