import './orderIssueInvoice.scss';
import { useEffect, useState } from 'react';
import useAxios from '../../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { sortTable } from '../../../components/utils/utils';
import Pagination from '../../../components/Pagination/Pagination';
import { ReactComponent as Icn_Reload } from '../../../assets/images/icn_Reload.svg';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';

const OrderIssueInvoice = () => {
    const [isGetList, setIsGetList] = useState(false);
    const [filterList, setFilterList] = useState('');
    const [paginationData, setPaginationData] = useState({});
    const sortBy = ['orderNo desc'];
    const navigate = useNavigate();
    const { operation } = useAxios();

    const pageTitle = '訂單開發票';

    const titleList = ['#', '訂單編號', '銷售日期', '訂購人', '訂單狀態', '發票狀態'];

    const orderStatus = {
        0: '待付款',
        1: '待理貨',
        2: '待出貨',
        3: '待收貨',
        4: '訂單完成',
        5: '待退貨',
        6: '待換貨',
        7: '待退款',
        8: '退換貨、退款完成',
        9: '訂單不成立',
    };

    const orderStatusList = Object.values(orderStatus).map((el, index) => {
        return {
            name: el,
            value: index,
        };
    });

    const invoiceStatus = {
        0: '尚未開發票',
        1: '完成開發票',
        2: '部分開發票',
    };

    const invoiceStatusList = Object.values(invoiceStatus).map((el, index) => {
        return {
            name: el,
            value: index,
        };
    });

    const fixedListData = {
        orderStatusList,
        invoiceStatusList,
    };

    //#region AdvancedSearch region
    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);

    const searchContentList = [
        {
            column: 'orderNo',
            name: '訂單編號',
            type: 'text',
            tableColumn: 'orderNo', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'orderNo',
            selectOptionList: ['contains', '!contains'],
        },
        {
            column: 'orderDate',
            name: '銷售日期',
            type: 'date',
            tableColumn: 'orderDate',
            searchFromDataBase: 'orderDate',
            selectOptionList: ['==', '!=', '>', '>=', '<', '<='],
        },
        {
            column: 'fullName',
            name: '訂購人',
            type: 'text',
            tableColumn: 'fullName', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'fullName',
            selectOptionList: ['contains', '!contains'],
        },
        {
            column: 'status',
            name: '訂單狀態',
            tableColumn: 'status',
            listName: 'orderStatusList',
            searchFromDataBase: 'status',
            type: 'list',
            options: ['name'],
            selectOptionList: ['==', '!='],
            optionsValue: 'value',
        },
        {
            column: 'invoiceStatus',
            name: '發票狀態',
            tableColumn: 'invoiceStatus',
            listName: 'invoiceStatusList',
            searchFromDataBase: 'invoiceStatus',
            type: 'list',
            options: ['name'],
            selectOptionList: ['==', '!='],
            optionsValue: 'value',
        },
    ];

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'orderNo',
        searchFilter: 'orderNo',
        searchOperator: 'contains',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['contains', '!contains'],
    };

    const fetchData = (advancedSearchDataList = [], pageSize = 20, sort = sortBy, pageNumber = 1) => {
        setIsGetList(false);
        console.log(advancedSearchDataList);

        const gettingList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/SalSalesOrderAdmin/getSalesOrderList',
                    method: 'POST',
                    data: {
                        sort,
                        pageSize,
                        page: pageNumber,
                        advancedSearchDataList: advancedSearchDataList?.length ? advancedSearchDataList : null,
                    },
                },
                'getSalesOrderList'
            );
            if (success) {
                // console.log(response.data);

                // pagination
                const { numberOfData } = response.data;
                setPaginationData({
                    ...paginationData,
                    numberOfData: numberOfData,
                    totalPage: Math.ceil(numberOfData / pageSize),
                    pageNumber: pageNumber,
                });

                const data = response.data.data;
                let list = [];

                data.forEach((el, index) => {
                    el.guid = el.orderGuid;
                    el.detailUrl = `/orderIssueInvoice/edit/${el.guid}`;

                    el.dataList = [
                        index + 1,
                        el.orderNo,
                        el.orderDate.split('T')[0],
                        el.fullNameDisplay,
                        orderStatus[el.status],
                        invoiceStatus[el.invoiceStatus],
                    ];

                    const filterItemsObject = new Object();
                    filterItemsObject.guid = el.orderGuid;
                    filterItemsObject.displayOrder = (index + 1).toString();
                    filterItemsObject.orderNo = el.orderNo;
                    filterItemsObject.orderDate = el.orderDate;
                    filterItemsObject.fullName = el.fullNameDisplay;
                    filterItemsObject.status = orderStatus[el.status] ?? '';
                    filterItemsObject.invoiceStatus = invoiceStatus[el.invoiceStatus] ?? '';

                    list = [filterItemsObject, ...list];
                });

                setFilterList(data);
                setIsGetList(true);
            } else if (error) {
                console.log(error);
            }
        };

        gettingList();
    };

    useEffect(() => {
        fetchData();
        sortTable(1);
    }, []);

    return (
        <section id='section-main'>
            <div id='container-orderIssueInvoice'>
                <>
                    <h5 className='fw-bold mb-4'>{pageTitle}</h5>

                    <div className='searchTableWrapper bg-white p-4 rounded-4'>
                        <div className='searchTableTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold mb-0'>資料查詢</h6>
                            <button
                                className='searchBtn btn btn-primary text-white px-3 py-1 ms-auto me-2'
                                onClick={() => setAdvancedSearchModalOpen(true)}
                            >
                                搜尋
                            </button>

                            <span
                                className='reloadAppList me-2'
                                onClick={() => {
                                    setAdvancedSearchDataList([]);
                                    fetchData();
                                }}
                            >
                                <Icn_Reload />
                            </span>

                            <AdvancedSearchModal
                                isOpen={advancedSearchModalOpen}
                                handleClose={() => setAdvancedSearchModalOpen(false)}
                                advancedSearchDataTemp={advancedSearchDataTemp}
                                setAdvancedSearchDataList={setAdvancedSearchDataList}
                                displayList={[advancedSearchDataTemp]}
                                searchContentList={searchContentList}
                                fetchData={fetchData}
                                ns='ActScheduleRegistration'
                                restSearchData={[]}
                                fixedListData={fixedListData}
                            />
                        </div>
                        <div className='renderIndexListSearchTableBottom'>
                            <section>
                                <table className='appListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            {titleList.map((el) => (
                                                <th
                                                    key={titleList.indexOf(el)}
                                                    scope='col'
                                                    className='text-grey7 border-0'
                                                    onClick={() => sortTable(titleList.indexOf(el))}
                                                >
                                                    {el}
                                                    <span></span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {isGetList &&
                                            (filterList.length !== 0 ? (
                                                filterList.map((el) => {
                                                    return (
                                                        <tr
                                                            key={el.guid}
                                                            onClick={(e) => {
                                                                navigate(el.detailUrl);
                                                            }}
                                                        >
                                                            {el.dataList.map((el) => (
                                                                <td key={Math.random()}>{el}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='7'>無版本紀錄</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/*讀取畫面*/}
                                <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                    <h6 className='sr-only'>Loading...</h6>

                                    <div className='spinner-border text-primary' role='status'></div>
                                </div>
                            </section>

                            <Pagination
                                paginationData={paginationData}
                                setPaginationData={setPaginationData}
                                fetchData={fetchData}
                                sortBy={sortBy}
                                advancedSearchDataList={advancedSearchDataList}
                            />
                        </div>
                    </div>
                </>
            </div>
        </section>
    );
};

export default OrderIssueInvoice;
