import './orderIssueInvoiceChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import useAxios from '../../../hooks/useAxios';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const OrderIssueInvoiceChange = () => {
    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [donateList, setDonateList] = useState([]);
    const [donateDisplayContent, setDonateDisplayContent] = useState('');

    const paramsGuid = useParams('orderGuid');
    const navigate = useNavigate();
    const { operation } = useAxios();

    const orderStatus = {
        0: '待付款',
        1: '待理貨',
        2: '待出貨',
        3: '待收貨',
        4: '訂單完成',
        5: '待退貨',
        6: '待換貨',
        7: '待退款',
        8: '退換貨、退款完成',
        9: '訂單不成立',
    };

    const invoiceFormatObject = {
        0: '電子發票',
        1: '公司戶發票 (統一編號)',
        2: '捐贈發票',
        3: '手機條碼載具',
    };

    const invoiceStatus = {
        0: '尚未開發票',
        1: '完成開發票',
        2: '部分開發票',
    };

    const paymentMethod = {
        0: '信用卡付款',
        1: '付現金',
        2: '匯款',
        3: '貨到付款',
    };

    //#endregion

    //#region [function]

    // 訂單狀態改為待出貨
    const handleOrderStatus = async () => {
        Swal.fire({
            title: '確定要改為待出貨嗎?',
            text: '注意!! 訂單狀態修改後將無法回到先前狀態',
            icon: 'warning',
            allowOutsideClick: true,
            confirmButtonText: '確定',
            confirmButtonColor: 'var(--primary)',
            allowEscapeKey: false,

            cancelButtonText: '取消',
            cancelButtonColor: 'var(--secondary)',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);

                await axios
                    .get(`web/SalSalesOrderAdmin/updateSalesOrderStatusForPicking/${paramsGuid.orderGuid}`)
                    .then((res) => {
                        setIsLoading(false);
                        console.log(res.data);

                        if (res.data.status === '0') {
                            getOrderData();
                            Swal.fire({
                                icon: 'success',
                                title: '訂單狀態修改成功',
                            });
                        } else if (res.data.status === '400') {
                            Swal.fire({
                                icon: 'error',
                                title: res.data.message,
                            });
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: '網路不穩，儲存失敗',
                            text: '請再次儲存。',
                        });
                        console.log(err);
                    });
            }
        });
    };

    const handleInvoiceSubmitAction = async () => {
        let options = {
            title: '選擇操作',
            icon: 'warning',
            allowOutsideClick: true,
            confirmButtonText: '開立發票 & 列印發票',
            confirmButtonColor: 'var(--primary)',
            cancelButtonText: '取消',
            cancelButtonColor: 'var(--secondary)',
            showCancelButton: true,
            denyButtonText: '開立發票',
            denyButtonColor: 'var(--info)',
            showDenyButton: true,
        };

        Swal.fire(options).then(async (result) => {
            setIsLoading(true);
            try {
                if (result.isConfirmed) {
                    // 開立 + 列印
                    const successCreateInvoice = await handleCreateInvoice();
                    if (successCreateInvoice) {
                        await handlePrintInvoice(false);
                    }
                } else if (result.isDenied) {
                    // 只開立, 不列印
                    const successCreateInvoice = await handleCreateInvoice();

                    if (successCreateInvoice) {
                        await Swal.fire({
                            icon: 'success',
                            title: '成功',
                            text: '已成功開立發票, 頁面將自動重新整理',
                            timer: 3000, // 3秒後自動關閉彈窗
                            timerProgressBar: true, // 顯示進度條
                            showConfirmButton: false, // 隱藏確認按鈕
                            willClose: () => {
                                window.location.reload(); // 彈窗關閉時重新整理頁面
                            },
                        });
                    }

                    if (!successCreateInvoice) {
                        await Swal.fire({
                            icon: 'error',
                            title: '失敗',
                            text: '發票開立失敗, 請重新嘗試',
                        });
                    }
                }
            } catch (error) {
                console.error('操作失敗', error);
                // 這裡可以顯示錯誤提示
            } finally {
                setIsLoading(false);
            }
            // 取消的情況下，不執行任何操作
        });
    };

    const handleCreateInvoice = async () => {
        const { success, response, error } = await operation({
            url: `/invInvoice/createInvoice`,
            method: 'POST',
            data: {
                orderGuid: paramsGuid.orderGuid,
            },
        });

        if (success) {
            console.log(response);
            // alert('已成功開立發票');
            return true;
        } else if (error) {
            console.error(error); // 這裡可以記錄錯誤，方便除錯
            return false;
        }
    };

    const handlePrintInvoice = async (isReprint) => {
        const { success, response, error } = await operation({
            url: `/invInvoice/printInvoice`,
            method: 'POST',
            data: {
                orderGuid: paramsGuid.orderGuid,
                isReprint: isReprint,
            },
        });

        if (success) {
            await Swal.fire({
                icon: 'success',
                title: '成功',
                text: '已成功開立發票, 頁面將自動重新整理',
                timer: 3000, // 3秒後自動關閉彈窗
                timerProgressBar: true, // 顯示進度條
                showConfirmButton: false, // 隱藏確認按鈕
                willClose: () => {
                    window.location.reload(); // 彈窗關閉時重新整理頁面
                },
            });
        } else if (error) {
            console.error(error); // 這裡可以記錄錯誤，方便除錯
        }
    };

    //#endregion

    const getOrderData = async () => {
        const { success, response, error } = await operation({
            url: `/SalSalesOrderAdmin/getSalesOrder/${paramsGuid.orderGuid}`,
        });
        if (success) {
            // console.log(response.data.result);
            setOrderData(response.data.result);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getOrderData();
    }, []);

    useEffect(() => {
        const getDonateList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/SalSalesOrder/getDonationCodes',
                },
                'getDonateList'
            );
            if (success) {
                // console.log(response.data);
                setDonateList(response.data);
            } else if (error) {
                console.log(error);
            }
        };

        if (orderData?.invoiceFormat === '2') {
            getDonateList();
        }

        return () => {};
    }, [orderData?.invoiceFormat]);

    useEffect(() => {
        const getDonateDisplayContent = (npoban) => {
            if (npoban) {
                const target = donateList.find((el) => {
                    return el.donateCode === npoban;
                });

                const donateDisplayContent = target?.donateCode + ' ' + target?.donateNm;

                setDonateDisplayContent(donateDisplayContent);
            }
        };

        if (donateList.length) {
            getDonateDisplayContent(orderData.npoban);
        }
        return () => {};
    }, [donateList]);

    return (
        <>
            <section id='section-main'>
                <form id='container-orderIssueInvoiceChange'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>訂單開發票</h5>
                        <button
                            className='btnDelete btn btn-success px-4 ms-auto ms-auto me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleOrderStatus();
                            }}
                            type='button'
                            disabled={orderData?.status === '2' ? true : false}
                        >
                            {orderData?.status === '2' ? orderStatus[orderData?.status] : '訂單改為待出貨'}
                        </button>
                        {orderData?.invoiceStatus === '0' ? (
                            <button
                                className='btn btn-primary text-white px-4 me-2'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleInvoiceSubmitAction();
                                }}
                                type='button'
                            >
                                開立發票/列印發票
                            </button>
                        ) : (
                            <button
                                className='btn btn-primary text-white px-4 me-2'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/orderIssueInvoiceDetails/${paramsGuid.orderGuid}`);
                                }}
                                type='button'
                            >
                                發票查詢
                            </button>
                        )}
                        <button
                            className='btnCancel btn btn-outline-primary px-4'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/orderIssueInvoice');
                            }}
                            type='button'
                        >
                            回上頁
                        </button>
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>訂單編號：{orderData?.orderNo}</h6>
                        </div>
                        <div className='infoBottom row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='fullName' className='fontSize75Rem mb-2'>
                                    訂購人
                                </label>
                                <input
                                    type='text'
                                    id='fullName'
                                    name='fullName'
                                    className='form-control mb-3'
                                    value={`${orderData?.lastname ?? ''} ${orderData?.firstname ?? ''}`}
                                    onChange={() => {}}
                                    disabled
                                />
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    訂單狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='status'
                                    id='status'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            status: e.target.value,
                                        })
                                    }
                                    value={orderData?.status ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(orderStatus).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='orderDate' className='fontSize75Rem mb-2'>
                                    銷售日期
                                </label>
                                <input
                                    type='datetime-local'
                                    id='orderDate'
                                    name='orderDate'
                                    className='form-control mb-3'
                                    value={orderData?.orderDate.split('+')[0].split('.')[0] ?? ''}
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            orderDate: e.target.value,
                                        })
                                    }
                                    disabled
                                />
                                <label htmlFor='invoiceStatus' className='fontSize75Rem mb-2'>
                                    發票狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='invoiceStatus'
                                    id='invoiceStatus'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            invoiceStatus: e.target.value,
                                        })
                                    }
                                    value={orderData?.invoiceStatus ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(invoiceStatus).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='grossAmount' className='fontSize75Rem mb-2'>
                                    本幣應稅總金額
                                </label>
                                <input
                                    type='text'
                                    id='grossAmount'
                                    name='grossAmount'
                                    className='form-control mb-3'
                                    value={orderData?.grossAmount ?? ''}
                                    onChange={() => {}}
                                    disabled
                                />
                                <label htmlFor='paymentMethod' className='fontSize75Rem mb-2'>
                                    付款方式
                                </label>
                                <select
                                    className='form-control'
                                    name='paymentMethod'
                                    id='paymentMethod'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            paymentMethod: e.target.value,
                                        })
                                    }
                                    value={orderData?.paymentMethod ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(paymentMethod).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-4'>
                                <label htmlFor='fullName' className='fontSize75Rem mb-2'>
                                    發票格式
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='invoiceFormat'
                                    id='invoiceFormat'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            invoiceFormat: e.target.value,
                                        })
                                    }
                                    value={orderData?.invoiceFormat ?? ''}
                                    disabled
                                >
                                    {Object.values(invoiceFormatObject).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {/* 電子發票 */}
                            {invoiceStatus === '1' ? (
                                <div className='col-4'>
                                    <label htmlFor='invoiceTrackNumber' className='fontSize75Rem mb-2'>
                                        發票號碼
                                    </label>
                                    <input
                                        type='text'
                                        id='invoiceTrackNumber'
                                        name='invoiceTrackNumber'
                                        className='form-control mb-3'
                                        value={orderData?.invoiceTrackNumber ?? '53267964'}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            ) : null}
                            {/* 公司戶發票 (統一編號) */}
                            {orderData?.invoiceFormat === '1' ? (
                                <div className='col-4'>
                                    <label htmlFor='taxID' className='fontSize75Rem mb-2'>
                                        賣方公司統一編號
                                    </label>
                                    <input
                                        type='text'
                                        id='taxID'
                                        name='taxID'
                                        className='form-control mb-3'
                                        value={orderData?.taxID ?? ''}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            ) : null}

                            {/* 捐贈發票 */}
                            {orderData?.invoiceFormat === '2' ? (
                                <div className='col-4'>
                                    <label htmlFor='npoban' className='fontSize75Rem mb-2'>
                                        捐贈碼
                                    </label>
                                    <input
                                        type='text'
                                        id='npoban'
                                        name='npoban'
                                        className='form-control mb-3'
                                        value={donateDisplayContent ?? ''}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            ) : null}
                            {/* 手機條碼載具 */}
                            {orderData?.invoiceFormat === '3' ? (
                                <div className='col-4'>
                                    <label htmlFor='carrierId' className='fontSize75Rem mb-2'>
                                        載具號碼
                                    </label>
                                    <input
                                        type='text'
                                        id='carrierId'
                                        name='carrierId'
                                        className='form-control mb-3'
                                        value={orderData?.carrierId ?? ''}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor='note' className='fontSize75Rem mb-2'>
                                    備註
                                </label>
                                <textarea
                                    name=''
                                    id='note'
                                    className='form-control'
                                    rows='2'
                                    style={{
                                        resize: 'none',
                                    }}
                                    value={orderData?.note ?? ''}
                                    disabled
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>訂單明細</h6>
                        </div>
                        <div className='infoBottom row mb-3'>
                            <div className='col-12'>
                                <table
                                    className='table table-striped border'
                                    style={{
                                        tableLayout: 'fixed',
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '50px',
                                                }}
                                            >
                                                #
                                            </th>
                                            <th scope='col' className='text-start'>
                                                商品名稱
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '50px',
                                                }}
                                            >
                                                數量
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '200px',
                                                }}
                                            >
                                                單價
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '200px',
                                                }}
                                            >
                                                金額
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderData?.salesOrderItemList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope='col' className='text-center'>
                                                        {item?.displayOrder}
                                                    </td>
                                                    <td scope='col' className='text-start'>
                                                        {item?.name_01}
                                                    </td>
                                                    <td scope='col' className='text-center'>
                                                        {item?.quantitySales}
                                                    </td>
                                                    <td scope='col' className='text-center'>
                                                        {item?.grossAmount}
                                                    </td>
                                                    <td scope='col' className='text-center'>
                                                        {item?.grossAmount
                                                            ? item?.quantitySales * item?.grossAmount
                                                            : 0}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default OrderIssueInvoiceChange;
