import useAxios from '../../../hooks/useAxios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SalSalesKitClass() {
    // alert(87);
    const { operation } = useAxios();
    const [isGetList, setIsGetList] = useState(false);
    const [salesKitClassList, setSalesKitClassList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // Sorting states
    const [sortBy, setSortBy] = useState('statusTime');
    const [orderBy, setOrderBy] = useState('desc');

    const location = useLocation();
    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    //過濾搜尋
    const resetFilterSalesKitClassList = () => {
        setSearchText('');
        fetchData();
    };

    const filterSalesKitClassList = () => {
        if (searchText) {
            const advancedSearchDataList = [
                {
                    searchLogic: '',
                    SearchFilter: 'name_01',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_02',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_03',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
            ];

            fetchData(advancedSearchDataList, 100000, sortBy, 1);
        }
    };

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = async (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/SalSalesKitClass/getSalesKitClassList`;
        console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sort} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        // console.log(reqBody);

        const { success, response, error } = await operation(
            {
                url: '/SalSalesKitClass/getSalesKitClassList',
                method: 'POST',
                data: reqBody,
            },
            'fetchData'
        );
        if (success) {
            // console.log(response.data);

            setSalesKitClassList(response.data.data);
            setIsGetList(true);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // console.log(salesKitClassList);
    return (
        <section id='section-main'>
            <div id='container-salSalesKitClass'>
                <h5 className='fw-bold mb-4'>商品分類</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        {/* <select
                            className="form-control me-2"
                            style={{ width: '160px' }}
                            name="searchColumn"
                            id="searchColumn"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            required
                        >
                            <option value="all">全部</option>
                            <option value="fullname" id="fullnameSearch">
                                显示名称
                            </option>
                            <option value="userID" id="userIDSearch">
                                用户名(ID)
                            </option>
                            <option value="phone1" id="phone1Search">
                                手机号码
                            </option>
                            <option value="email1" id="email1Search">
                                邮箱
                            </option>
                        </select> 
                    */}
                        <input
                            className='searchInput form-control me-2'
                            placeholder='搜尋名稱'
                            value={searchText}
                            style={{ width: '400px' }}
                            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                            onKeyDown={(e) => e.key === 'Enter' && filterSalesKitClassList()}
                        />
                        <button
                            className='btn btn-outline-primary btn-size-s me-2'
                            onClick={resetFilterSalesKitClassList}
                        >
                            重置
                        </button>
                        <button className='btn btn-primary btn-size-s text-white' onClick={filterSalesKitClassList}>
                            查詢
                        </button>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>分類查詢</h6>
                        <button
                            className='btn btn-primary text-white ms-auto me-2'
                            onClick={() => {
                                navigate('./create');
                            }}
                        >
                            <svg
                                id='icn_Create'
                                className='me-2 mb-1'
                                viewBox='0 0 12 12'
                                width='12'
                                height='12'
                                fill='#fff'
                            >
                                <defs> </defs>
                                <path
                                    className='cls-1'
                                    id='_Color'
                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                    data-name=' ↳Color'
                                ></path>
                            </svg>
                            <span>新增分類</span>
                        </button>
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey7 border-0'>
                                            順序<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            簡中名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            英文名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            父層<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            狀態<span></span>
                                        </th>
                                        <th scope='col' className='text-grey7 border-0'>
                                            狀態變更時間<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetList &&
                                        (salesKitClassList.length !== 0 ? (
                                            salesKitClassList.map((skClass, idx) => {
                                                // console.log(skClass);
                                                return (
                                                    <tr
                                                        id={skClass?.salesKitClassGuid}
                                                        key={skClass?.salesKitClassGuid}
                                                        onClick={() => navigate(`${skClass?.salesKitClassGuid}`)}
                                                    >
                                                        <td>{idx + 1}</td>
                                                        <td>{skClass?.name_01}</td>
                                                        <td>{skClass?.name_02}</td>
                                                        <td>{skClass?.name_03}</td>
                                                        <td>{skClass?.upperName}</td>
                                                        <td>{statusList[skClass?.status]}</td>
                                                        <td>
                                                            {skClass.statusTime
                                                                ? dayjs(skClass.statusTime).format(
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  )
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='7'>无用戶纪录</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
