import './physicalStore.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { scrollToLocation } from '../../../components/utils/utils';

/// 购买渠道
/// Author : YL
function PhysicalStore() {
    const { operation } = useAxios();

    const [physicalStoreList, setPhysicalStoreList] = useState([]); // 宾乐达官方旗舰店
    const [flagshipStore, setFlagshipStore] = useState(null); // 销售网点

    const getPhysicalStoreList = async () => {
        const { success, response, error } = await operation(
            {
                url: '/service/physicalstore',
                method: 'GET',
            },
            'getPhysicalStoreList'
        );

        if (success) {
            setFlagshipStore(response.data.flagshipStore);
            setPhysicalStoreList(response.data.physicalStoreList);
        }

        if (error) {
            console.log(error);
        }
    };

    // 如果「销售网点」圖片失效,改用 fallbackSrc 灰底為圖片
    const handleImgOnError = (el) => {
        // el.target.onError = null;
        el.currentTarget.src = './images/physicalStore/fallbackSrc.png';
    };

    useEffect(() => {
        getPhysicalStoreList();

        return () => {};
    }, []);

    return (
        <div className='container-fluid p-0'>
            <article id='container-physicalStore' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <section className='physicalStore-section section1 row justify-content-center align-content-center text-center'>
                    <h1 className='col-12 word'>購買通路</h1>
                    <h2 className='col-12 word'>我們隨時提供服務</h2>
                </section>

                <div className='wrapper'>
                    <section className='physicalStore-section section2 bg-white row align-items-center'>
                        <div className='col buttonArea'>
                            <div
                                className='ms-3 me-lg-7 onlineShopTitleSectionLink'
                                onClick={() => scrollToLocation('.section3')}
                            >
                                線上商城
                            </div>
                            <div
                                className='ms-3 physicalStoreTitleSectionLink'
                                onClick={() => scrollToLocation('.section5')}
                            >
                                銷售據點
                            </div>
                        </div>
                    </section>
                </div>

                <hr className='w-100 m-0' />

                <div className='wrapper'>
                    <section
                        id='onlineShopTitleSection'
                        className='physicalStore-section section3 bg-white row align-items-center text-center'
                    >
                        <h2 className='col-12 '>線上商城</h2>
                        <div className='col-12'>線上商城購買通路，多款產品供您選擇！</div>
                    </section>
                </div>

                <div className='wrapper'>
                    {/* <section
						id="onlineShopSection"
						className="physicalStore-section section4 row align-items-center justify-content-center text-center"
					>
						<div className="col-12 onlineShopImage onlineShopImage1"></div>
						<div className="col-12 onlineShopTitle onlineShopTitle1 flagshipStoreTitle">
							賓樂達官方旗艦店
						</div>
						<button
							type="button"
							className="btn btn-size-m btn-primary rounded-pill text-white mt-3 mb-4 mb-lg-7"
							onClick={() => window.open(flagshipStore, '_blank')}
						>
							蝦皮旗艦店
						</button>
					</section> */}

                    <section
                        id='onlineShopSection'
                        className='physicalStore-section section4 row align-items-center justify-content-center text-center'
                    >
                        <div
                            className='col-12 d-flex justify-content-center align-items-center'
                            style={{ minHeight: '168px' }}
                        >
                            敬請期待
                        </div>
                    </section>
                </div>

                <div className='wrapper'>
                    <section
                        id='physicalStoreTitleSection'
                        className='physicalStore-section section5 bg-white row align-items-center text-center justify-content-center'
                    >
                        <h2 className='col-12'>銷售據點</h2>
                        <div className='col-12'>建議體驗前先聯繫店家，確認營業時間及展示型號。</div>
                        <div className='col-12'>後續各地據點將陸續上線，敬請期待</div>
                    </section>
                </div>

                <div className='wrapper mb-11'>
                    <section
                        id='physicalStoreSection'
                        className='physicalStore-section section6 bg-white row align-items-center text-center'
                    >
                        {!physicalStoreList ? (
                            physicalStoreList.map((el) => (
                                <div id={el.storeGuid} key={el.storeGuid} className='physicalStoreCard bg-white w-100'>
                                    <div className='mx-4 mx-lg-0 d-flex'>
                                        <div className='physicalStore-name'>
                                            <div className='cardInner text-grey8 pb-4 mb-0 mb-lg-3'>
                                                <span>
                                                    <img
                                                        className='section16Img h-100 mx-0'
                                                        src='./images/physicalStore/section6_1.png'
                                                        alt='section6_1Img'
                                                    />
                                                </span>
                                                <span className='me-2'>{el.name}</span>
                                            </div>
                                        </div>
                                        <div className='physicalStore-location'>
                                            <div className='cardInner'>
                                                <div>{el.address}</div>
                                                <div>
                                                    <span>
                                                        <img
                                                            className='section16Img h-100 mx-0'
                                                            src='./images/physicalStore/section6_2.png'
                                                            alt='section6_1Img'
                                                        />
                                                    </span>
                                                    <span className='ms-2'>{el.telephone}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='physicalStore-image'>
                                            {el.picture && (
                                                <img
                                                    src={el.picture}
                                                    onError={(e) => {
                                                        handleImgOnError(e);
                                                    }}
                                                    alt=''
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='physicalStoreCard bg-white w-100'>
                                <div className='mx-4 mx-lg-0 d-flex justify-content-center'>
                                    <div className='physicalStore-location'>
                                        <div className='cardInner h-100'>
                                            <div className='text-center mb-0 h-100 d-flex justify-content-center align-items-center'>
                                                敬請期待
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </section>
                </div>

                <div className='wrapper mb-10 mb-lg-14'>
                    <section className='physicalStore-section section7 w-100 row align-items-center justify-content-center px-0 bg-white'>
                        <div className='smallWrapper'>
                            <div className='col-12 desc px-3 px-lg-0'>
                                <div className='text-grey6 mb-0'>說明：</div>
                                <ul className='text-grey6 ps-0 mb-4 mb-lg-5'>
                                    <li>
                                        1、賓樂達官方服務中心：為使用者提供專業、便捷、貼心服務的窗口，主要業務有產品維修、檢測、軟體升級和諮詢等服務；
                                    </li>
                                    <li>
                                        2、服務站：賓樂達官方認證並授權開展軟體升級、快速送修和諮詢服務的體驗中心或專賣店；
                                    </li>
                                    <li>
                                        3、授權代送修點：代送修點不提供直接維修服務，會將機器送往就近服務中心進行維修。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='wrapper'>
                    <section className='physicalStore-section section8 w-100 row justify-content-center'>
                        <div className='qaSection col-12 col-lg-6 text-center px-0'>
                            <div className='border-grey8 border-end'>
                                <div className='text-grey8 pt-0 pt-lg-4 mt-4 mb-2 mb-lg-5'>常見問題</div>
                                <h6 className='text-grey8 pb-0 pb-lg-4 mb-3'>
                                    <Link className='' to='/support/question'>
                                        <span className='me-2'>查看更多內容</span>
                                        <span>
                                            <img
                                                className='section16Img h-100 m-0'
                                                src='./images/physicalStore/section8_1.png'
                                                alt='section8Img'
                                            />
                                        </span>
                                    </Link>
                                </h6>
                            </div>
                        </div>
                        <div className='contactUs col-12 col-lg-6 text-center px-0'>
                            <div className='text-grey8 pt-lg-4 mt-3 mt-lg-4 mb-2 mb-lg-5'>聯繫我們</div>
                            <h6 className='text-grey8 pb-4 mb-0 mb-lg-3'>
                                <Link className='' to='/support/contactus'>
                                    <span className='me-2'>開始諮詢</span>
                                    <span>
                                        <img
                                            className='section16Img h-100 mx-0'
                                            src='./images/physicalStore/section8_1.png'
                                            alt='section8Img'
                                        />
                                    </span>
                                </Link>
                            </h6>
                        </div>
                    </section>
                </div>

                <hr className='w-100 m-0' />

                {/* <div className="wrapper">
                    <section className="physicalStore-section section9 w-100 align-items-center justify-content-center px-0 bg-white text-center">
                        <h2 className="mt-6 mt-lg-7 mb-2 mb-lg-3 text-grey8">
                            售后服务
                        </h2>
                        <div className="px-8 px-lg-0 mb-5 text-grey8">
                            欢迎使用宾乐达售后服务，期待为您提供帮助。
                        </div>
                        <div className="btn btn-primary rounded-pill text-white mb-6 mb-lg-14">
                            跳转至服务功能
                        </div>
                    </section>
                </div> */}
            </article>
        </div>
    );
}

export default PhysicalStore;
