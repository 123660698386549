import './appDownload.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';

function AppDownload() {
    const { t } = useTranslation(['download', 'productStat']); // i18n

    const [isOnload, setOnload] = useState(false);
    const [apkUrl, setApkUrl] = useState(
        'https://play.google.com/store/apps/details?id=com.synerter.SynerterSports&pli=1'
    );

    // 中國版android APK
    // const getApkUrl = async () => {
    // 	await axios
    // 		.get('web/sys/appdownloadurl?OSCode=1')
    // 		.then((res) => {
    // 			const url = res.data.result;
    // 			// console.log(url);

    // 			setApkUrl(url);
    // 		})
    // 		.catch((err) => {
    // 			console.log(err);
    // 		});
    // };

    useEffect(() => {
        setOnload(true);
        // getApkUrl();
    }, []);

    //獲取最新版apk下載連結
    const getNewestAndroid = async () => {
        window.open('https://play.google.com/store/apps/details?id=com.synerter.SynerterSports&pli=1', '_blank');

        // 中國版APK
        // await axios
        // 	.get('web/sys/appdownloadurl?OSCode=1')
        // 	.then((res) => {
        // 		//console.log(res);
        // 		const url = res.data.result;

        // 		// window.location.href = url;
        // 		window.open(url, '_blank');
        // 	})
        // 	.catch((err) => {
        // 		console.log(err);
        // 	});
    };

    return (
        <div className='container-fluid p-0'>
            <article id='container-appDownload' className='d-flex flex-column align-items-center px-0  mx-auto'>
                <section className='appDownload-section section1 row flex-column flex-lg-row px-lg-5 mx-0 mx-lg-auto'>
                    <div className='col-lg-5'>
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start mt-5 mt-lg-0'>
                            <img
                                className='section1AppIcon mb-3 mb-lg-5'
                                src='./images/appDownload/section1AppIcon.png'
                                alt='section1AppIcon'
                            />
                            <h1 className='mb-2 mb-lg-4'>{t('synerter_app')}</h1>
                            <p className='mb-3 mb-lg-8 px-5 px-lg-0'>{t('synerter_app_desc')}</p>
                            <div className='d-flex'>
                                <div
                                    className={`btnIcon ${
                                        isOnload ? 'd-flex' : 'd-none'
                                    } justify-content-center align-items-center btn btn-dark rounded-pill px-4 me-2`}
                                    onClick={() =>
                                        window.open(
                                            'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                            '_blank'
                                        )
                                    }
                                >
                                    <div
                                    // to={{
                                    //     pathname: '/app/download',
                                    //     state: {
                                    //         initialLocation: '.section1',
                                    //     },
                                    // }}
                                    >
                                        <img src='./images/appDownload/section1AppleLogo.png' alt='section1AppleLogo' />
                                    </div>
                                </div>
                                <div
                                    className={`btnIcon ${
                                        isOnload ? 'd-flex' : 'd-none'
                                    }  justify-content-center align-items-center btn btn-dark rounded-pill px-4 me-2`}
                                    onClick={() => getNewestAndroid()}
                                >
                                    <img src='./images/appDownload/section1AndroidLogo.png' alt='section1AndroidLogo' />
                                </div>
                                <div
                                    className='d-flex justify-content-center align-items-center btn btn-primary rounded-pill px-3 px-lg-4'
                                    onClick={() => getNewestAndroid()}
                                >
                                    {t('android_download')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='h-100 d-flex align-items-center'>
                            <img
                                className='section1Phone mx-auto ms-lg-14 mt-9 mt-lg-0'
                                src='./images/appDownload/section1Phone.png'
                                alt='section1Phone'
                            />
                        </div>
                    </div>
                </section>
                <section className='appDownload-section section2 w-100 row align-items-top'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h1 className='text-primary mt-8 mb-5 mt-lg-9 mb-lg-8'>
                            <span className='mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>{t('title_1-1')}</span>
                            <span className='d-block d-lg-inline'>{t('title_1-2')}</span>
                        </h1>
                        <p className='px-6 mx-auto'>{t('content_1')}</p>
                    </div>
                </section>
                <section className='appDownload-section section3 w-100 row flex-column-reverse flex-lg-row'>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex justify-content-center align-items-lg-end'>
                            <img
                                className='section3Img mx-0 ms-lg-auto mt-12 mt-lg-0'
                                src='./images/appDownload/section3.png'
                                alt='section3Img'
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-9 mt-12 mt-lg-6'>
                            <h1 className='mb-4 mb-lg-4'>{t('title_2')}</h1>
                            <p className='mb-3 px-5 px-lg-0'>{t('content_2')}</p>
                            {/* <h6 className="mb-3 mb-lg-8 px-5 px-lg-0 text-grey6">
								注：没有手机APP，也可直接使用发球机上的按键面板操作，不受网路限制。
							</h6> */}
                        </div>
                    </div>
                </section>
                <section className='appDownload-section section4 w-100 row'>
                    <div className='col-lg-6 mb-lg-0'>
                        <div className='h-100 d-flex flex-column justify-content-start align-lg-items-start mt-12'>
                            <div className='mx-auto ms-lg-auto'>
                                <h1 className='mb-4 mb-lg-4 text-center'>{t('title_3')}</h1>
                                <p className='mb-3 mb-lg-8 px-5 px-lg-0'>
                                    {t('content_3-1')}
                                    <br />
                                    <br />
                                    {t('content_3-2')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-0 px-lg-auto'>
                        <div className='h-100 ps-lg-15 ps-xxl-0 d-flex justify-content-start align-items-center'>
                            <img
                                className='section4Phone mx-0 mt-12 mt-lg-0'
                                srcSet={`${'./images/appDownload/section4PhoneSmall.png'} 992w, ${'./images/appDownload/section4Phone.png'} 993w`}
                                alt='section4Phone'
                            />
                        </div>
                    </div>
                </section>
                <section className='appDownload-section section4_2 w-100 row flex-column-reverse flex-lg-row'>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex justify-content-center align-items-center'>
                            <img
                                className='section4_2_Phone mx-0 ms-lg-auto mt-12 mt-lg-0'
                                src='./images/appDownload/section4_2_Phone.png'
                                alt='section4_2_Phone'
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-9 pb-lg-12 mt-12 mt-lg-0 '>
                            <h1 className='mb-4 mb-lg-4'>{t('title_4')}</h1>
                            <p className='mb-3 px-5 px-lg-0'>{t('content_4')}</p>
                        </div>
                    </div>
                </section>
                <section className='appDownload-section section5 w-100 row flex-column flex-lg-row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-14 pb-lg-11 mt-12 mt-lg-0'>
                            <div className='mx-auto ms-lg-auto'>
                                <h1 className='mb-4 mb-lg-4 text-center'>{t('title_5')}</h1>
                                <p className='mb-4 px-5 px-lg-0'>{t('content_5-1')}</p>
                                <ul className='mb-5 mb-lg-6 px-5 px-lg-0 text-center text-lg-start'>
                                    <li>{t('content_5-2')}</li>
                                    <li>{t('content_5-3')}</li>
                                    <li>{t('content_5-4')}</li>
                                    <li>{t('content_5-5')}</li>
                                    <li>{t('content_5-6')}</li>
                                    <li>{t('content_5-7')}</li>
                                </ul>
                                <Link
                                    to={{
                                        pathname: '/explore',
                                    }}
                                >
                                    <button className='d-flex justify-content-center align-items-center btn btn-outline-secondary rounded-pill px-5 mx-auto ms-lg-0'>
                                        {t('app_showcase')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-5 px-lg-0'>
                        <div className='h-100 d-flex justify-content-center align-items-center'>
                            <img
                                className='section5Phone ps-lg-8 mx-0 me-lg-auto mt-10 mt-lg-0'
                                src='./images/appDownload/section5Phone.png'
                                alt='section5Phone'
                            />
                        </div>
                    </div>
                </section>

                <section className='appDownload-section section5_2 w-100 row flex-column-reverse flex-lg-row'>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex justify-content-center align-items-center'>
                            <img
                                className='section5_2_Phone ps-lg-15 mx-0 mx-lg-auto mt-12 mt-lg-0'
                                src='./images/appDownload/section5_2_Phone.png'
                                alt='section5_2_Phone'
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start pb-lg-12 mt-12 mt-lg-0 '>
                            <h1 className='mb-4 mb-lg-4'>{t('title_6')}</h1>
                            <p className='mb-3 px-5 px-lg-0'>{t('content_6')}</p>
                        </div>
                    </div>
                </section>

                <div className='sameBgWrap'>
                    <section className='appDownload-section section5_3 w-100 row justify-content-center align-items-center mx-0 mt-14 mt-lg-0'>
                        <div className='col-12 text-center'>
                            <h1 className='text-primary mb-4 mb-lg-11'>
                                <span className='mb-2 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline'>{t('title_7-1')}</span>
                                <span className='d-block d-lg-inline'>{t('title_7-2')}</span>
                            </h1>
                            <p className='mb-3 mb-lg-4 px-5 px-lg-0'>{t('content_7')}</p>
                        </div>
                    </section>
                    <section className='appDownload-section section6 w-100 row flex-column flex-lg-row px-lg-5 mx-0'>
                        <div className='col-lg-7'>
                            <div className='h-100 qrCodeWrapper d-flex flex-column justify-content-start align-items-center align-items-lg-start pe-lg-12 mt-14 mt-lg-14'>
                                <div className='ms-auto'>
                                    <h1 className='mb-3 mb-lg-4 text-center text-lg-start'>{t('synerter_app-2')}</h1>
                                    <h2 className='mb-4 mb-lg-6 px-5 px-lg-0 text-center text-lg-start'>
                                        {t('welcome_download_synerter_app')}
                                    </h2>
                                    <div className='row '>
                                        <div className='col-lg-5 d-lg-none'>
                                            <div className='h-100 d-flex d-lg-none justify-content-start align-items-center px-5 px-lg-0'>
                                                <img
                                                    className='section6Phone mx-auto me-lg-auto mt-12 mb-9 mt-lg-0'
                                                    src='./images/appDownload/section6Phone.png'
                                                    alt='section6Phone'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            <div className='d-flex flex-column align-items-center'>
                                                <div
                                                    // to={{
                                                    //     pathname:
                                                    //         '/support/downloadcenter',
                                                    //     state: {
                                                    //         initialLocation:
                                                    //             '.section4',
                                                    //     },
                                                    // }}
                                                    className='text-center'
                                                    onClick={() =>
                                                        window.open(
                                                            'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                                            '_blank'
                                                        )
                                                    }
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <QRCodeSVG
                                                        size='200'
                                                        includeMargin={true}
                                                        value='https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883'
                                                    />
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '4px',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <img
                                                            className='w-100'
                                                            src='./images/download_IOS.svg'
                                                            alt='iosQRcode'
                                                        />
                                                    </div>
                                                </div>
                                                <p className='text-grey6 align-lg-self-start mb-0 mt-3'>
                                                    {t('app_support_version', {
                                                        version_name: 'iOS 12',
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            <div className='d-flex flex-column align-items-center'>
                                                <div
                                                    // to={{
                                                    //     pathname:
                                                    //         '/support/downloadcenter',
                                                    //     state: {
                                                    //         initialLocation:
                                                    //             '.section4',
                                                    //     },
                                                    // }}
                                                    className='text-center'
                                                    onClick={() => window.open(apkUrl, '_blank')}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <QRCodeSVG size='200' includeMargin={true} value={apkUrl} />
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '4px',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <img
                                                            className='w-100'
                                                            src='./images/download_android.svg'
                                                            alt='anQRcodeRcode'
                                                        />
                                                    </div>
                                                </div>
                                                <p className='text-grey6 align-lg-self-start mb-0 mt-3'>
                                                    {t('app_support_version', {
                                                        version_name: 'Android 8.0',
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='h-100 d-none d-lg-flex justify-content-start align-items-center px-5 px-lg-0'>
                                <img
                                    className='section6Phone me-auto mt-9 mt-lg-0'
                                    src='./images/appDownload/section6Phone.png'
                                    alt='section6Phone'
                                />
                            </div>
                        </div>
                    </section>
                </div>

                <section className='appDownload-section section7 w-100 row justify-content-center px-3 mt-12 mb-6 mt-lg-15 my-lg-13'>
                    <div className='col-12'>
                        <p className='text-grey6'>{t('description')}：</p>
                        <ul className='text-grey6 mb-5'>
                            <li>{t('desc-1')}</li>
                            <li>{t('desc-2')}</li>
                            <li>{t('desc-3')}</li>
                        </ul>
                        <p className='text-grey6'>
                            {t('productStat:product_disclaimer_1')}
                            <br />
                            {t('productStat:product_disclaimer_2')}
                            <br />
                            {t('productStat:product_disclaimer_3')}
                            <br />
                            {t('productStat:product_disclaimer_4')}
                        </p>
                    </div>
                </section>
            </article>
        </div>
    );
}

export default AppDownload;
