import React, { useCallback, useEffect, useState } from 'react';
import useInitialLocation from './useInitialLocation';
import { useTranslation } from 'react-i18next';
import Router from './router/router';
import useRefreshToken from './hooks/useRefreshToken';
import useAuth from './hooks/useAuth';

// 建立登入共用資料context
export const AccountContext = React.createContext();

function App() {
    const { auth } = useAuth();
    const refresh = useRefreshToken();
    const [loading, setLoading] = useState(true);

    const [homePageIndex, setHomePageIndex] = useState(0);
    const initialLocation = useInitialLocation();

    const [lang, setLang] = useState(null); // Initialize with null to indicate language not set yet
    const { i18n } = useTranslation();

    // 在事件方法中使用i18n.changeLanguage()方法
    const toggleI18n = (locale) => {
        i18n.changeLanguage(locale);
    };

    useEffect(() => {
        const localStorageLocale = localStorage.getItem('i18n') || navigator.language || 'zh';

        setLang(localStorageLocale);
    }, []);

    useEffect(() => {
        if (lang) {
            let locale = 'zh';
            if (lang === 'zh-CN') {
                locale = 'zh-CN';
            } else if (lang === 'zh-TW') {
                locale = 'zh';
            } else {
                locale = 'en';
            }

            toggleI18n(locale);

            refresh();
            setLoading(false);
        }
    }, [lang]);

    // Display nothing or a loading screen until the language is set
    if (loading) return null; // Or return a loading spinner if desired

    return (
        <AccountContext.Provider
            value={{
                homePageIndex,
                setHomePageIndex,
                lang,
                setLang,
            }}
        >
            <Router auth={auth} />
        </AccountContext.Provider>
    );
}

export default App;
