import './explore.scss';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { scrollToLocation } from '../../components/utils/utils';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../App';
import useAxios from '../../hooks/useAxios';

/// 网上展示厅
/// Author : YL
function Explore() {
    const { t } = useTranslation(['explore']); // i18n
    const { operation } = useAxios();

    const [onlineVideoList, setOnlineVideoList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const userInputRef = useRef('');
    const categoryNameObj = {
        0: t('hot_recommendations'),
        1: t('product_specials'),
        2: t('exhibition_events'),
        3: t('user_guide'),
        4: t('tutorial_courses'),
    };

    // 多國語言
    const { lang } = useContext(AccountContext);

    let languageCode = 'ZF';

    if (lang === 'zh-CN') {
        languageCode = 'ZH';
    } else if (lang === 'en') {
        languageCode = 'EN';
    } else {
        languageCode = 'ZF';
    }
    console.log('lang', lang);
    console.log('languageCode', languageCode);

    // 點擊切換分類
    const handleChangeFilter = (e, filter) => {
        [...e.currentTarget.parentNode.children].forEach((el) => {
            el.classList.remove('active');
        });
        e.currentTarget.classList.add('active');
        scrollToLocation('.pageBtnWrapper');
        setCategoryFilter(filter);
    };

    const getOnlineVideoList = useCallback(async () => {
        console.log(languageCode);
        await operation(
            {
                url: `/service/onlineVideo?languageCode=${languageCode}`,
                method: 'GET',
            },
            'getOnlineVideoList'
        ).then(({ success, response, error }) => {
            if (success) {
                // console.log(response);
                // console.log(response.data);
                // console.log(response.data.onlineVideoList.length);
                if (response.data.onlineVideoList.length === 0) {
                    getTWOnlineVideoList();
                } else {
                    setOnlineVideoList(response.data.onlineVideoList);
                    setCategoryList(response.data.categoryList);
                }
            }
            if (error) {
                console.log(error);
            }
        });
    }, [lang]);
    //沒多國內容，用中文內容呈現
    const getTWOnlineVideoList = async () => {
        await operation(
            {
                url: `/service/onlineVideo?languageCode=ZF`,
                method: 'GET',
            },
            'getOnlineVideoList'
        ).then(({ success, response, error }) => {
            if (success) {
                // console.log(response);
                // console.log(response.data);
                setOnlineVideoList(response.data.onlineVideoList);
                setCategoryList(response.data.categoryList);
            }
            if (error) {
                console.log(error);
            }
        });
    };
    // 使用者輸入查詢
    const handleChangingUserInput = (input) => {
        if (!input) setSearchKeyword('');

        return (userInputRef.current = input);
    };

    const renderOnlineVideoList = (onlineVideoList) => {
        const result = onlineVideoList
            .sort((a, b) => b.displayOrder - a.displayOrder)
            .filter((el) => {
                return el.title.includes(searchKeyword) || categoryNameObj[el.category].includes(searchKeyword);
            })
            .filter((el) => {
                if (!categoryFilter) return el;
                return el.category === categoryFilter;
            })
            .map((el) => {
                return (
                    <div
                        className='card col-4'
                        key={el.videoGuid}
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(el.videoURL)}
                    >
                        <div className='cardImg'>
                            <img
                                className='w-100 h-100'
                                src={el.coverPath}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = './images/explore/defaultCardImage.png';
                                }}
                                alt='cardImg'
                            ></img>
                        </div>
                        <div className='cardWords bg-white row align-items-center justify-content-between'>
                            <div className='cardDesc col-8 border-right'>
                                <div className='mb-2 cardTitle'>
                                    <span>{el.title}</span>
                                </div>
                                <div className='cardCategory'>
                                    <span>{categoryNameObj[el.category]}</span>
                                </div>
                            </div>
                            <div className='cardVideoLinkContainer col-4 ps-5 text-center'>
                                <div className='cardVideoLink d-flex'>
                                    <div>{t('watch_videos')}</div>
                                    <div className='arrowImg ms-2'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

        // if (!result.length)
        // 	return (
        // 		<div className="card col-4" key={'defaultVideo'}>
        // 			<div className="cardImg">
        // 				<img
        // 					className="w-100 h-100"
        // 					src={'./images/explore/defaultCardImage.png'}
        // 					alt="cardImg"
        // 				></img>
        // 			</div>
        // 			<div className="cardWords bg-white row align-items-center justify-content-between">
        // 				<div className="cardDesc col-7 border-right">
        // 					<div className="mb-2 cardTitle">
        // 						<span>賓樂達官方旗艦店</span>
        // 					</div>
        // 					<div className="cardCategory">
        // 						<span>{'Recommendation'}</span>
        // 					</div>
        // 				</div>
        // 				<div className="cardVideoLinkContainer col-5 text-center">
        // 					<div className="cardVideoLink d-flex">
        // 						<div
        // 						// onClick={() =>
        // 						// 	window.open(
        // 						// 		'https://shop247849299.world.taobao.com/',
        // 						// 		'_blank'
        // 						// 	)
        // 						// }
        // 						>
        // 							進入商城
        // 						</div>
        // 						<div className="arrowImg ms-2"></div>
        // 					</div>
        // 				</div>
        // 			</div>
        // 		</div>
        // 	);

        return result;
    };
    console.log(onlineVideoList);
    const handleSearchByKeyword = (keyword) => {
        scrollToLocation('.section2');
        setSearchKeyword(keyword);
    };

    useEffect(() => {
        getOnlineVideoList();
    }, [getOnlineVideoList]);

    return (
        <div className='container-fluid p-0 bg-white'>
            <article id='container-explore' className='d-flex flex-column align-items-center px-0 mx-auto'>
                {/* ExploreTop start */}

                <section className='exploreTop-section w-100 row'>
                    <div className='col position-relative p-0 text-center'>
                        <h1 className='text-center'>{t('appreciation_tour')}</h1>
                        <p className='text-center'>{t('explore_info')}</p>

                        <div className='searchWrapContainer w-100'>
                            {
                                <div className='searchWrap position-relative mx-lg-auto'>
                                    <input
                                        type='text'
                                        className='rounded-pill w-100 bg-white border-0'
                                        placeholder={t('input_info')}
                                        // value={userInputRef.current}
                                        onChange={(e) => {
                                            handleChangingUserInput(e.target.value);
                                        }}
                                        onKeyPress={(e) =>
                                            e.key === 'Enter' &&
                                            handleSearchByKeyword(userInputRef.current.replaceAll(' ', ''))
                                        }
                                    />
                                    <img
                                        className='position-absolute'
                                        src='./images/support/icnSearch.png'
                                        alt='icnSearch'
                                        onClick={() => {
                                            handleSearchByKeyword(userInputRef.current.replaceAll(' ', ''));
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        <div className='position-absolute pageBtnWrapper bg-white w-100'>
                            <div className='textWrap h-100 d-flex flex-wrap justify-content-center align-items-center mx-auto'>
                                <div className='classBtnInnerWrapper'>
                                    <button
                                        type='button'
                                        className='active btn btn-primary btn-lg mx-2'
                                        key={'all'}
                                        onClick={(e) => handleChangeFilter(e, null)}
                                    >
                                        {t('all')}
                                    </button>

                                    {categoryList.map((el) => {
                                        return (
                                            <button
                                                type='button'
                                                className='btn btn-primary btn-lg mx-2'
                                                key={el}
                                                onClick={(e) => handleChangeFilter(e, el)}
                                            >
                                                {categoryNameObj[el]}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ExploreTop end */}

                <section className='explore-section section2 w-100 row'>
                    <div className='col-12 p-0 text-center'>
                        <h2 className='px-6 mx-auto mb-3 text-grey8'>{t('explore_functionality')}</h2>
                        <h6 className='px-6 mx-auto mb-0 text-grey8'>{t('explore_more_guide')}</h6>
                    </div>
                </section>

                <section className='explore-section section3 w-100 row mb-10 mb-lg-14'>
                    <section className='explore-section section3 w-100 row mb-10 mb-lg-14'>
                        <div className='cardArea row justify-content-start'>
                            {renderOnlineVideoList(onlineVideoList)}
                        </div>
                    </section>

                    {/* <div className="cardArea row justify-content-center">
						敬請期待
					</div> */}
                </section>
            </article>
        </div>
    );
}

export default Explore;
